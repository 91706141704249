import {
	GET_AREA_FAILED,
	GET_AREA_STARTED,
	GET_AREA_SUCCESS,
	UPDATE_AREA_FAILED,
	UPDATE_AREA_STARTED,
	UPDATE_AREA_SUCCESS,
} from '../constants/actions'
import {
	createAreaDetails,
	getAreaDetails,
	updateAreaDetails,
} from '../services/area'
import { extractErrorStr } from '../utils/network'
import { actionCreator } from '../utils/redux'

export const getAreasAction = (params) => (dispatch) => {
	dispatch(actionCreator(GET_AREA_STARTED))
	return getAreaDetails(params)
		.then((json) => {
			dispatch(actionCreator(GET_AREA_SUCCESS, json.data?.data))
			return json
		})
		.catch((error) => {
			dispatch(actionCreator(GET_AREA_FAILED, extractErrorStr(error)))
			return error
		})
}

export const updateAreaAction = (updatedArea) => (dispatch) => {
	dispatch(actionCreator(UPDATE_AREA_STARTED))
	return updateAreaDetails(updatedArea)
		.then((json) => {
			dispatch(actionCreator(UPDATE_AREA_SUCCESS, json.data))
			return json
		})
		.catch((error) => {
			dispatch(actionCreator(UPDATE_AREA_FAILED, extractErrorStr(error)))
			return error
		})
}

export const createAreaAction = (createdArea) => (dispatch) => {
	dispatch(actionCreator(UPDATE_AREA_STARTED))
	return createAreaDetails(createdArea)
		.then((json) => {
			dispatch(actionCreator(UPDATE_AREA_SUCCESS, json.data))
			return json
		})
		.catch((error) => {
			dispatch(actionCreator(UPDATE_AREA_FAILED, extractErrorStr(error)))
			return error
		})
}
