import React from 'react'

const Contact = () => {
	return (
		<div className='static-page-container'>
			<h1>Contact Us</h1>
			<p>
				For any assistance or grievances, please reach us at
				coastok@gmail.com You may also reach out to our location at Hare
				Krishna Hills, East of Kailash Sant Nagar South Delhi- 110065 (India)
			</p>

			<p>
				If you still feel your issues unaddressed, you can call/whatsapp
				HG Jaya Vaman Dev Das at (+91)8929890375.
			</p>
		</div>
	)
}

export default Contact
