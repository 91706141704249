import HaridwarCamp from '../containers/EventRegister/particular-pages/HaridwarCamp2023'
import JagannathPuriMayapurCamp from '../containers/EventRegister/particular-pages/PuriMayapurCamp2022'
import Viplava2023 from '../containers/EventRegister/particular-pages/Viplava2023'
import { isProd } from '../utils/network'

export const EVENTS_UI_CONFIG = [
	{
		id: 'jagannath-puri-mayapur-camp',
		title: 'Jagannath Puri & Mayapur Yatra',
		dates: '25th Jan - 5th Feb 2025',
		startDate: '12-01-2024',
		deadline: '01-13-2025',
		shortLink: '/bothmnp2025',
		extraValidation: true,
		landingPage: (props) => <JagannathPuriMayapurCamp {...props} />,
		allowSubsidy: false,
		allowCash: false,
		allowDonation: false,
		allowOnlinePayment: true,
		allowPartialPayment: true,
		showAmount: false,
		showAmountBreakup: false,
		criteriaPoint:
			'Sea & Ganga Snan must be done under central team guidance; otherwise, you will be solely responsible for any mishaps.',
		headerImage:
			'https://res.cloudinary.com/dm1o3cvik/image/upload/v1732649541/337118102_761122642038492_6626692394984210_n_hlxs0j.jpg',
		trailer: 'https://www.youtube.com/watch?v=eCnOtWbxWOs',
		paymentHeaderImage:
			'https://i.pinimg.com/originals/ae/f2/5b/aef25b06ef92b646c2fa70a9ae81e5b2.jpg',
		uuid: isProd()
			? '6743771c7028a15238c41614'
			: '6743771c7028a15238c41614',
		priceTallyImg:
			'https://res.cloudinary.com/dm1o3cvik/image/upload/v1732910388/mnpcamp2024/WhatsApp_Image_2024-11-29_at_21.37.37_usxjdq.jpg',
		startingPrice: 4200,
		roomInfo: {
			1: 'Dormitory ( Hall - 10 To 20 occupancy , Common Bathroom)',
			2: 'Room (Non AC Room, 3 To 4 occupancy, Common Bathroom)',
			3: `Premium Room( AC Room , Double Occupancy , attached
			bathroom)`,
		},
		basicInputs: [
			{ id: 'name' },
			{ id: 'initiatedName' },
			{ id: 'education' },
			{ id: 'dob' },
			{ id: 'phone' },
			{ id: 'email' },
			{ id: 'connectedAreaId' },
			{ id: 'facilitator' },
			{ id: 'chantingRounds' },
			{ id: 'TNC' },
		],
		eligibility: {
			minimumChantingRounds: 4,
		},
		additionalInputs: [{ id: 'transportation' }, { id: 'accomodation' }],
		showAvailability: false,
		confirmationCharge: 1000,
		nonRefundableMessage: 'Note: Registration amount is non-refundable.',
		hideReferral: true,
		allowAxisBank: true,
		allowRazorpayBank: false,
	},
	{
		id: 'jagannath-puri-camp',
		title: 'Jagannath Puri Yatra',
		dates: '25th Jan - 31st Jan 2025',
		startDate: '12-01-2024',
		deadline: '01-13-2025',
		shortLink: '/puri2025',
		extraValidation: true,
		landingPage: (props) => <JagannathPuriMayapurCamp {...props} />,
		allowSubsidy: false,
		allowCash: false,
		allowDonation: false,
		allowOnlinePayment: true,
		allowPartialPayment: true,
		showAmount: false,
		showAmountBreakup: false,
		criteriaPoint:
			'Sea Snan must be done under central team guidance; otherwise, you will be solely responsible for any mishaps.',
		headerImage:
			'https://res.cloudinary.com/dm1o3cvik/image/upload/v1732914111/mnpcamp2024/jagannath-temple-in-puri-heritage_mcnqad.jpg',
		trailer: 'https://www.youtube.com/watch?v=eCnOtWbxWOs',
		paymentHeaderImage:
			'https://i.ytimg.com/vi/9GjGUyHXm0g/maxresdefault.jpg',
		uuid: isProd()
			? '674377707028a15238c41615'
			: '674377707028a15238c41615',
		priceTallyImg:
			'https://res.cloudinary.com/dm1o3cvik/image/upload/v1732910388/mnpcamp2024/WhatsApp_Image_2024-11-29_at_21.36.41_bhdktg.jpg',
		startingPrice: 1600,
		basicInputs: [
			{ id: 'name' },
			{ id: 'initiatedName' },
			{ id: 'education' },
			{ id: 'dob' },
			{ id: 'phone' },
			{ id: 'email' },
			{ id: 'connectedAreaId' },
			{ id: 'facilitator' },
			{ id: 'chantingRounds' },
			{ id: 'TNC' },
		],
		roomInfo: {
			1: 'Dormitory ( Hall - 10 To 20 occupancy , Common Bathroom)',
			2: 'Room (Non AC Room, 3 To 4 occupancy, Common Bathroom)',
			3: `Premium Room( AC Room , Double Occupancy , attached
			bathroom)`,
		},
		eligibility: {
			minimumChantingRounds: 4,
		},
		additionalInputs: [{ id: 'transportation' }, { id: 'accomodation' }],
		showAvailability: false,
		confirmationCharge: 1000,
		nonRefundableMessage: 'Note: Registration amount is non-refundable.',
		hideReferral: true,
		allowAxisBank: true,
		allowRazorpayBank: false,
	},
	{
		id: 'mayapur-camp',
		title: 'Mayapur Yatra',
		dates: '29th Jan - 5th Feb 2025',
		startDate: '12-01-2024',
		deadline: '01-13-2025',
		shortLink: '/mayapur2025',
		extraValidation: true,
		landingPage: (props) => <JagannathPuriMayapurCamp {...props} />,
		allowSubsidy: false,
		allowCash: false,
		allowDonation: false,
		allowOnlinePayment: true,
		allowPartialPayment: true,
		showAmount: false,
		showAmountBreakup: false,
		criteriaPoint:
			'Ganga Snan must be done under central team guidance; otherwise, you will be solely responsible for any mishaps.',
		headerImage:
			'https://res.cloudinary.com/dm1o3cvik/image/upload/v1732913823/mnpcamp2024/tovp_drawing_slide-lg.jpg_jhxu6r.webp',
		trailer: 'https://www.youtube.com/watch?v=eCnOtWbxWOs',
		paymentHeaderImage:
			'https://www.mayapur.com/wp-content/uploads/2021/03/Reflections-on-Bhakti-II-Devotion-as-the-Ultimate-Goal-of-Life.jpg',
		uuid: isProd()
			? '674377c77028a15238c41616'
			: '674377c77028a15238c41616',
		priceTallyImg: `https://res.cloudinary.com/dm1o3cvik/image/upload/v1732910388/mnpcamp2024/WhatsApp_Image_2024-11-29_at_21.37.08_xs8mj8.jpg`,
		startingPrice: 2300,
		basicInputs: [
			{ id: 'name' },
			{ id: 'initiatedName' },
			{ id: 'education' },
			{ id: 'dob' },
			{ id: 'phone' },
			{ id: 'email' },
			{ id: 'connectedAreaId' },
			{ id: 'facilitator' },
			{ id: 'chantingRounds' },
			{ id: 'TNC' },
		],
		additionalInputs: [
			{ id: 'transportation' },
			{
				id: 'accomodation',
			},
		],
		roomInfo: {
			1: 'Dormitory ( Hall - 10 To 20 occupancy , Common Bathroom)',
			2: 'Room (Non AC Room, 3 To 4 occupancy, Common Bathroom)',
			3: `Premium Room( AC Room , Double Occupancy , attached
			bathroom)`,
		},
		eligibility: {
			minimumChantingRounds: 4,
		},
		showAvailability: false,
		confirmationCharge: 1000,
		nonRefundableMessage: 'Note: Registration amount is non-refundable.',
		hideReferral: true,
		allowAxisBank: true,
		allowRazorpayBank: false,
	},
	{
		id: 'bss-braja-camp-2024',
		heading: 'Kartik BSS Braja Camp 2024',
		title: 'BSS Braja Camp',
		dates: '24 - 27 Oct 2024',
		deadline: '10/16/2024',
		landingPage: (props) => <HaridwarCamp {...props} />,
		allowSubsidy: false,
		allowCash: true,
		allowDonation: false,
		allowOnlinePayment: true,
		allowPartialPayment: true,
		showAmount: false,
		showAmountBreakup: false,
		allowAxisBank: true,
		allowRazorpayBank: false,
		showOutfit: false,
		hideReferral: true,
		transportationMessage:
			'Bus depart from Delhi NCR on 14th of June in morning',
		posterImage:
			'https://res.cloudinary.com/dm1o3cvik/image/upload/v1725867036/braja/ewoehwytlxrgyxdjyynf.jpg',
		paymentHeaderImage:
			'https://res.cloudinary.com/dm1o3cvik/image/upload/v1725723427/braja/rf80tyl7jsfrofebwaeg.jpg',
		priceTallyImg:
			'https://res.cloudinary.com/dm1o3cvik/image/upload/v1725723426/braja/aykrrd1onjfiovrcbasw.jpg',
		uuid: isProd()
			? '66d039936ccc683fcf6178e5'
			: '66df51df4c074abf194da3d5',
		startingPrice: 1400,
		eligibility: {
			minimumChantingRounds: 8,
		},
		confirmationCharge: 500,
		basicInputs: [
			{ id: 'name' },
			{ id: 'initiatedName' },
			{ id: 'phone' },
			{ id: 'email' },
			{ id: 'dob' },
			{ id: 'education' },
			{ id: 'connectedAreaId' },
			{ id: 'facilitator' },
			{ id: 'chantingRounds' },
			{ id: 'sadhnaGroup' },
			{ id: 'NUMBER_OF_BSS_CAMP' },
			{ id: 'TNC' },
			{ id: 'PBT_YEAR' },
		],
		extraValidation: true,
		additionalInputs: [{ id: 'accomodation' }],
		roomInfo: {
			1: 'Dormitory ( Hall - 10 To 100 occupancy , Common Bathrooms)',
			2: 'Room (Non AC Room, 4 occupancy, Attached Bathroom)',
		},
	},
	{
		id: 'bss-dps-haridwar-camp-2024',
		heading: 'Summer Camp 2024',
		title: 'BSS & DPS Summer Camp 2024 (Haridwar)',
		dates: '14 - 16 JUN 2024',
		deadline: '06/04/2024',
		landingPage: (props) => <HaridwarCamp {...props} />,
		allowSubsidy: false,
		allowCash: true,
		allowDonation: false,
		allowOnlinePayment: true,
		allowPartialPayment: true,
		showAmount: false,
		showAmountBreakup: false,
		allowAxisBank: true,
		showOutfit: false,
		hideReferral: true,
		transportationMessage:
			'Bus depart from Delhi NCR on 14th of June in morning',
		posterImage:
			'https://res.cloudinary.com/dm1o3cvik/image/upload/v1714494194/hhe4ywyleo71s4tum9y5.jpg',
		paymentHeaderImage:
			'https://i.pinimg.com/originals/ae/f2/5b/aef25b06ef92b646c2fa70a9ae81e5b2.jpg',
		priceTallyImg:
			'https://res.cloudinary.com/dm1o3cvik/image/upload/v1714493480/yw8s2r5eegag0f2gu1pq.jpg',
		uuid: isProd()
			? '66223e9f147baaf421a1f9f2'
			: '66223e9f147baaf421a1f9f2',
		startingPrice: 1400,
		eligibility: {
			minimumChantingRounds: 8,
		},
		confirmationCharge: 500,
		basicInputs: [
			{ id: 'name' },
			{ id: 'initiatedName' },
			{ id: 'phone' },
			{ id: 'email' },
			{ id: 'dob' },
			{ id: 'education' },
			{ id: 'connectedAreaId' },
			{ id: 'facilitator' },
			{ id: 'chantingRounds' },
			{ id: 'sadhnaGroup' },
		],
		additionalInputs: [{ id: 'transportation' }, { id: 'accomodation' }],
	},
	{
		id: 'sps-vl2-haridwar-camp-2024',
		heading: 'Summer Camp 2024',
		title: 'SPS & VL2 Summer Camp 2024 (Haridwar)',
		dates: '07 - 09 JUN 2024',
		deadline: '05/31/2024',
		landingPage: (props) => <HaridwarCamp {...props} />,
		allowSubsidy: false,
		allowCash: true,
		allowDonation: false,
		allowOnlinePayment: true,
		allowPartialPayment: true,
		showAmount: false,
		showAmountBreakup: false,
		allowAxisBank: true,
		hideReferral: true,
		transportationMessage:
			'Bus depart from Delhi NCR on 7th of June in morning',
		posterImage:
			'https://res.cloudinary.com/dm1o3cvik/image/upload/v1714494194/fsvi9rscqrusgpvrpoqz.jpg',
		paymentHeaderImage:
			'https://res.cloudinary.com/dnuq1lgqs/image/upload/v1683354128/DPS_Camp_to_Rishikesh_84_bqg4re.jpg',
		priceTallyImg:
			'https://res.cloudinary.com/dm1o3cvik/image/upload/v1717143109/scdjwqbzf8vfeuyjnnkx.jpg',
		uuid: isProd()
			? '66223e1c147baaf421a1f9f0'
			: '66223e1c147baaf421a1f9f0',
		startingPrice: 1400,
		roomInfo: {
			1: 'Dormitory ( Hall - 10 To 100 occupancy , Common Bathrooms)',
			2: 'Room (AC Room, 3 To 4 occupancy, Attached Bathroom)',
		},
		eligibility: {
			minimumChantingRounds: 4,
		},
		showOutfit: false,
		confirmationCharge: 500,
		basicInputs: [
			{ id: 'name' },
			{ id: 'initiatedName' },
			{ id: 'phone' },
			{ id: 'email' },
			{ id: 'dob' },
			{ id: 'education' },
			{ id: 'connectedAreaId' },
			{ id: 'facilitator' },
			{ id: 'chantingRounds' },
			{ id: 'sadhnaGroup' },
		],
		additionalInputs: [{ id: 'transportation' }, { id: 'accomodation' }],
	},
	{
		id: 'ftp12024',
		title: 'FTP Camp 1 2024',
		dates: '05 - 07 APR 2024',
		deadline: '03-31-2024',
		shortLink: '/ftp12024',
		landingPage: (props) => <JagannathPuriMayapurCamp {...props} />,
		allowSubsidy: false,
		allowCash: true,
		allowDonation: false,
		allowOnlinePayment: true,
		allowPartialPayment: true,
		showAmount: false,
		showAmountBreakup: false,
		trailer: 'https://www.youtube.com/watch?v=eCnOtWbxWOs',
		paymentHeaderImage:
			'https://res.cloudinary.com/dm1o3cvik/image/upload/v1711075933/dhrq8xilruysv7suhotm.jpg',
		uuid: isProd()
			? '65f968afbd5b64f53bf9bfea'
			: '65f968afbd5b64f53bf9bfea',
		priceTallyImg:
			'https://res.cloudinary.com/dm1o3cvik/image/upload/v1711032961/lycu5id1qskwtndxrauk.jpg',
		startingPrice: 100,
		roomInfo: {
			1: 'Dormitory ( Hall - 10 To 20 occupancy , Common Bathroom)',
			2: 'Room (AC Room, 3 To 4 occupancy, Common Bathroom)',
			3: `Premium Room( AC Room , Double Occupancy , attached
			bathroom)`,
		},
		basicInputs: [
			{ id: 'name' },
			{ id: 'initiatedName' },
			{ id: 'education' },
			{ id: 'dob' },
			{ id: 'phone' },
			{ id: 'email' },
			{ id: 'connectedAreaId' },
			{ id: 'facilitator' },
			{ id: 'chantingRounds' },
		],
		additionalInputs: [{ id: 'transportation' }, { id: 'accomodation' }],
		hideTransportationLabel: true,
		transportationMessage: 'Are you from delhi NCR ?',
		showAvailability: false,
		confirmationCharge: 100,
		nonRefundableMessage: 'Note: Registration amount is non-refundable.',
		hideReferral: true,
		allowAxisBank: true,
	},
	{
		id: 'jagannath-puri-mayapur-camp',
		title: 'Mayapur & Jagannath Puri Yatra',
		dates: '08 - 19 FEB 2024',
		deadline: '02-01-2024',
		shortLink: '/bothmnp',
		landingPage: (props) => <JagannathPuriMayapurCamp {...props} />,
		allowSubsidy: false,
		allowCash: true,
		allowDonation: false,
		allowOnlinePayment: true,
		allowPartialPayment: true,
		showAmount: false,
		showAmountBreakup: false,
		trailer: 'https://www.youtube.com/watch?v=eCnOtWbxWOs',
		paymentHeaderImage:
			'https://i.pinimg.com/originals/ae/f2/5b/aef25b06ef92b646c2fa70a9ae81e5b2.jpg',
		uuid: isProd()
			? '659a37a2e0cb8e8393f7ccb9'
			: '659a37a2e0cb8e8393f7ccb9',
		priceTallyImg:
			'https://res.cloudinary.com/dm1o3cvik/image/upload/v1704636633/gporbggykrz5mzbru5ks.jpg',
		startingPrice: 4200,
		roomInfo: {
			1: 'Dormitory ( Hall - 10 To 20 occupancy , Common Bathroom)',
			2: 'Room (Non AC Room, 3 To 4 occupancy, Common Bathroom)',
			3: `Premium Room( AC Room , Double Occupancy , attached
			bathroom)`,
		},
		basicInputs: [
			{ id: 'name' },
			{ id: 'initiatedName' },
			{ id: 'education' },
			{ id: 'dob' },
			{ id: 'phone' },
			{ id: 'email' },
			{ id: 'connectedAreaId' },
			{ id: 'facilitator' },
			{ id: 'chantingRounds' },
		],
		additionalInputs: [{ id: 'transportation' }, { id: 'accomodation' }],
		showAvailability: false,
		confirmationCharge: 800,
		nonRefundableMessage: 'Note: Registration amount is non-refundable.',
		hideReferral: true,
		allowAxisBank: true,
	},
	{
		id: 'jagannath-puri-camp',
		title: 'Jagannath Puri Yatra',
		dates: '08 - 11 FEB 2024',
		deadline: '02-01-2024',
		shortLink: '/puri2024',
		landingPage: (props) => <JagannathPuriMayapurCamp {...props} />,
		allowSubsidy: false,
		allowCash: true,
		allowDonation: false,
		allowOnlinePayment: true,
		allowPartialPayment: true,
		showAmount: false,
		showAmountBreakup: false,
		trailer: 'https://www.youtube.com/watch?v=eCnOtWbxWOs',
		paymentHeaderImage:
			'https://i.ytimg.com/vi/9GjGUyHXm0g/maxresdefault.jpg',
		uuid: isProd()
			? '659a36d9e0cb8e8393f7ccb7'
			: '659a36d9e0cb8e8393f7ccb7',
		priceTallyImg:
			'https://res.cloudinary.com/dm1o3cvik/image/upload/v1705657032/ay2mrrgbjyfanr030os1.jpg',
		startingPrice: 1600,
		basicInputs: [
			{ id: 'name' },
			{ id: 'initiatedName' },
			{ id: 'education' },
			{ id: 'dob' },
			{ id: 'phone' },
			{ id: 'email' },
			{ id: 'connectedAreaId' },
			{ id: 'facilitator' },
			{ id: 'chantingRounds' },
		],
		roomInfo: {
			1: 'Dormitory ( Hall - 10 To 20 occupancy , Common Bathroom)',
			2: 'Room (Non AC Room, 3 To 4 occupancy, Common Bathroom)',
			3: `Premium Room( AC Room , Double Occupancy , attached
			bathroom)`,
		},
		additionalInputs: [{ id: 'transportation' }, { id: 'accomodation' }],
		showAvailability: false,
		confirmationCharge: 800,
		nonRefundableMessage: 'Note: Registration amount is non-refundable.',
		hideReferral: true,
		allowAxisBank: true,
	},
	{
		id: 'mayapur-camp',
		title: 'Mayapur Yatra',
		dates: '12 - 19 FEB 2024',
		deadline: '02-05-2024',
		shortLink: '/mayapur2024',
		landingPage: (props) => <JagannathPuriMayapurCamp {...props} />,
		allowSubsidy: false,
		allowCash: true,
		allowDonation: false,
		allowOnlinePayment: true,
		allowPartialPayment: true,
		showAmount: false,
		showAmountBreakup: false,
		trailer: 'https://www.youtube.com/watch?v=eCnOtWbxWOs',
		paymentHeaderImage:
			'https://www.mayapur.com/wp-content/uploads/2021/03/Reflections-on-Bhakti-II-Devotion-as-the-Ultimate-Goal-of-Life.jpg',
		uuid: isProd()
			? '65812f853b3b15a2b19c4520'
			: '65812f853b3b15a2b19c4520',
		priceTallyImg: `https://res.cloudinary.com/dm1o3cvik/image/upload/v1703325006/znbpztok2bvsaqiobd1w.jpg`,
		startingPrice: 2300,
		basicInputs: [
			{ id: 'name' },
			{ id: 'initiatedName' },
			{ id: 'education' },
			{ id: 'dob' },
			{ id: 'phone' },
			{ id: 'email' },
			{ id: 'connectedAreaId' },
			{ id: 'facilitator' },
			{ id: 'chantingRounds' },
		],
		additionalInputs: [
			{ id: 'transportation' },
			{
				id: 'accomodation',
			},
		],
		roomInfo: {
			1: 'Dormitory ( Hall - 10 To 20 occupancy , Common Bathroom)',
			2: 'Room (Non AC Room, 3 To 4 occupancy, Common Bathroom)',
			3: `Premium Room( AC Room , Double Occupancy , attached
			bathroom)`,
		},
		showAvailability: false,
		confirmationCharge: 800,
		nonRefundableMessage: 'Note: Registration amount is non-refundable.',
		hideReferral: true,
		allowAxisBank: true,
	},
	{
		id: 'bss-dps-haridwar-camp',
		title: 'BSS DPS Summer Camp (Haridwar)',
		deadline: '06/06/2023',
		landingPage: (props) => <HaridwarCamp {...props} />,
		allowSubsidy: false,
		allowCash: true,
		allowDonation: false,
		allowOnlinePayment: true,
		allowPartialPayment: true,
		showAmount: false,
		showAmountBreakup: false,
		posterImage:
			'https://res.cloudinary.com/dnuq1lgqs/image/upload/v1683358717/WhatsApp_Image_2023-04-27_at_2.55.36_PM_pajejt.jpg',
		paymentHeaderImage:
			'https://res.cloudinary.com/dnuq1lgqs/image/upload/v1683354128/DPS_Camp_to_Rishikesh_84_bqg4re.jpg',
		uuid: isProd()
			? '6454c9284d978b21832d7dc8'
			: '6454c9284d978b21832d7dc8',
		startingPrice: 1400,
		eligibility: {
			minimumChantingRounds: 8,
		},
		basicInputs: [
			{ id: 'name' },
			{ id: 'initiatedName' },
			{ id: 'phone' },
			{ id: 'email' },
			{ id: 'connectedAreaId' },
			{ id: 'facilitator' },
			{ id: 'chantingRounds' },
			{ id: 'sadhnaGroup' },
		],
		additionalInputs: [{ id: 'transportation' }],
	},
	{
		id: 'sps-vl2-haridwar-camp',
		title: 'SPS VL2 Summer Camp (Haridwar)',
		deadline: '06/12/2023',
		landingPage: (props) => <HaridwarCamp {...props} />,
		allowSubsidy: false,
		allowCash: true,
		allowDonation: false,
		allowOnlinePayment: true,
		allowPartialPayment: true,
		showAmount: false,
		showAmountBreakup: false,
		posterImage:
			'https://res.cloudinary.com/dnuq1lgqs/image/upload/v1683358724/WhatsApp_Image_2023-04-27_at_2.55.36_PM_1_qzgj4g.jpg',
		paymentHeaderImage:
			'https://res.cloudinary.com/dnuq1lgqs/image/upload/v1683354128/DPS_Camp_to_Rishikesh_84_bqg4re.jpg',
		uuid: isProd()
			? '64560c2eaa6ceed8c9c9a818'
			: '64560c2eaa6ceed8c9c9a818',
		startingPrice: 1400,
		eligibility: {
			minimumChantingRounds: 4,
		},
		showOutfit: true,
		basicInputs: [
			{ id: 'name' },
			{ id: 'initiatedName' },
			{ id: 'phone' },
			{ id: 'email' },
			{ id: 'connectedAreaId' },
			{ id: 'facilitator' },
			{ id: 'chantingRounds' },
			{ id: 'sadhnaGroup' },
		],
		additionalInputs: [{ id: 'transportation' }],
	},
	{
		id: 'viplava',
		title: 'Viplava 2023',
		deadline: '10/20/2023',
		landingPage: (props) => <Viplava2023 {...props} />,
		allowSubsidy: true,
		allowCash: true,
		allowDonation: false,
		allowOnlinePayment: true,
		allowPartialPayment: true,
		allowAxisBank: true,
		showAmount: false,
		showAmountBreakup: true,
		posterImage:
			'https://res.cloudinary.com/dnuq1lgqs/image/upload/v1683358724/WhatsApp_Image_2023-04-27_at_2.55.36_PM_1_qzgj4g.jpg',
		paymentHeaderImage:
			'https://res.cloudinary.com/dwneljbds/image/upload/v1690725860/dzq768thaunywz2bdu2e.jpg',
		uuid: isProd()
			? '64c0a574121bec0e45a88804'
			: '64686b0457c46a6d780f940c',
		startingPrice: 1400,
		eligibility: {
			minimumChantingRounds: 2,
		},
		basicInputs: [
			{ id: 'name' },
			{ id: 'initiatedName' },
			{ id: 'education' },
			{ id: 'dob' },
			{ id: 'phone' },
			{ id: 'email' },
			{ id: 'connectedAreaId' },
			{ id: 'facilitator' },
			{ id: 'chantingRounds' },
		],
		additionalInputs: [{ id: 'transportation' }, { id: 'accomodation' }],
		showReferrerReward: true,
	},
]
// 64686b0457c46a6d780f940

export const getEventById = (eventId) => {
	return EVENTS_UI_CONFIG.find((event) => event.uuid === eventId)
}
