import React from 'react'
import { DataGrid } from '@mui/x-data-grid'

export const CustomDataGrid = ({
	rows = [],
	columns = [],
	rowCount = 0,
	loading = false,
	pageSizeOptions = [5, 10, 20, 50, 100],
	paginationModel,
	onPaginationModelChange,
	autoHeight = true,
	pagination = true,
	paginationMode = 'server',
	disableColumnFilter = true,
	// Additional props for flexibility
	initialState = {},
	getRowClassName,
	...restProps
}) => {
	return (
		<DataGrid
			rows={rows}
			columns={columns}
			rowCount={rowCount}
			loading={loading}
			pageSizeOptions={pageSizeOptions}
			autoHeight={autoHeight}
			pagination={pagination}
			paginationMode={paginationMode}
			disableColumnFilter={disableColumnFilter}
			initialState={initialState}
			paginationModel={paginationModel}
			onPaginationModelChange={onPaginationModelChange}
			getRowClassName={getRowClassName}
			density="compact"
			slotProps={{
				filterPanel: {
					filterFormProps: {
						logicOperatorInputProps: {
							variant: 'outlined',
							size: 'small',
						},
						columnInputProps: {
							variant: 'outlined',
							size: 'small',
							sx: { mt: 'auto' },
						},
						operatorInputProps: {
							variant: 'outlined',
							size: 'small',
							sx: { mt: 'auto' },
						},
						valueInputProps: {
							InputComponentProps: {
								variant: 'outlined',
								size: 'small',
							},
						},
					},
				},
			}}
			{...restProps} // Allows additional DataGrid props
		/>
	)
}
