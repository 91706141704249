import { Button } from '@mui/material'
import React, { useMemo } from 'react'
import { ROUTE } from '../../../constants/route'
import { useNavigate } from 'react-router-dom'

function DonationButton() {
	const navigate = useNavigate()
	const onClickHandler = () => {
		navigate(`${ROUTE.DONATION_PAGE.path}`)
	}
	const styles = useMemo(() => ({ height: 15, width: 15, marginLeft: 5 }), [])
	return (
		<Button
			variant="contained"
			color="primary"
			className="mb-16"
			onClick={onClickHandler}
		>
			{'Donate '}
			<img
				src="https://res.cloudinary.com/dwneljbds/image/upload/v1690727942/xqniteq2oppjfs6ufl1s.png"
				alt="donate"
				style={styles}
			/>
		</Button>
	)
}

export default DonationButton
