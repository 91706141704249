import { API_URL, METHOD } from '../constants/network';
import {fetchAPI} from '../utils/network';

export const uploadFile = (file) => {
    return fetchAPI({
        url: API_URL.UPLOAD,
        method: METHOD.POST,
        body: file
    })
}
