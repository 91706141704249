import React, { useEffect, useReducer } from 'react'
import { styled } from '@mui/material/styles'
import './style.scss'
import {
	Button,
	Dialog,
	FormControl,
	FormGroup,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	TextField,
} from '@mui/material'
import { GridSearchIcon } from '@mui/x-data-grid'
import { createLibraryData, getLibraryData } from '../../services/library'
import { isMobileScreen, modifyProperty } from '../../utils'
import {
	ALERT,
	LIBRARY_CATEGORY,
	LIBRARY_LANGUAGE,
	LIBRARY_MEDIA_TYPE,
	SADHNA_GROUP,
} from '../../constants'
import { CheckCircleRounded, Visibility } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import store from '../../store'
import { showAlert } from '../../actions'
import { CustomDataGrid } from '../../components/CustomTable/CustomDataGrid'

const PREFIX = 'LibraryContainer'

const classes = {
	tableHeader: `${PREFIX}-tableHeader`,
	modal: `${PREFIX}-modal`,
	modalRoot: `${PREFIX}-modalRoot`,
	paper: `${PREFIX}-paper`,
}

const Root = styled('div')({
	[`& .${classes.tableHeader}`]: {
		padding: '20px',
		marginBottom: '20px',
	},
	[`& .${classes.modal}`]: {
		display: 'flex',
		// padding: theme.spacing(1),
		alignItems: 'center',
		justifyContent: 'center',
	},
	[`& .${classes.modalRoot}`]: {
		// height: 300,
		position: 'absolute',
		flexGrow: 1,
		minWidth: 300,
		transform: 'translateZ(0)',
		// The position fixed scoping doesn't work in IE 11.
		// Disable this demo to preserve the others.
		'@media all and (-ms-high-contrast: none)': {
			display: 'none',
		},
	},
})

const styles = {
	paper: {
		width: 400,
		backgroundColor: 'white',
		// border: '2px solid #000',
		borderRadius: '8px',
		// boxShadow: theme.shadows[5],
		padding: '40px',
		lineHeight: '1.44',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
}

const initialState = {
	data: [],
	form: {
		lang: '',
		mediaType: '',
		category: '',
		sadhnaGroup: [],
		url: '',
		title: '',
	},
	modal: false,
	filters: {
		pageSize: 10,
		page: 0,
		total_results: 0,
		total_pages: 0,
		language: '',
		mediaType: '',
		search: '',
		sadhnaGroup: [],
	},
}

function LibraryContainer(props) {
	const [{ data, modal, form, loading, filters }, setState] = useReducer(
		(prev, data) => ({
			...prev,
			...data,
			filters: { ...prev.filters, ...data.filters },
			form: { ...prev.form, ...data.form },
		}),
		initialState
	)

	const isMobile = isMobileScreen()
	const params = useParams()
	const { libraryType } = params

	const fetchData = () => {
		const body = {
			id: '',
			category: LIBRARY_CATEGORY[libraryType].id,
			status: '',
			mediaType: filters.mediaType,
			sadhnaGroup: Array.isArray(filters.sadhnaGroup)
				? filters.sadhnaGroup?.join(',')
				: '',
			title: filters.search,
			lang: filters.language,
			page: filters.page + 1,
			per_page: filters.pageSize,
		}
		setState({
			loading: true,
		})
		getLibraryData(body).then((res) => {
			const { data, total_pages, total_results, current_page } =
				res?.data || {}
			setState({
				data,
				loading: false,
				filters: {
					total_results,
					total_pages,
				},
			})
		})
	}

	useEffect(() => {
		fetchData()
	}, [
		libraryType,
		filters.mediaType,
		filters.language,
		filters.search,
		filters.sadhnaGroup,
		filters.page,
		filters.pageSize,
	])

	const rowWidthConfig = {
		title: { default: { flex: 0.2 }, mobile: { width: 150 } },
		action: { default: { flex: 0.2 }, mobile: { width: 150 } },
		language: { default: { flex: 0.2 }, mobile: { width: 150 } },
		mediaType: { default: { flex: 0.2 }, mobile: { width: 150 } },
		sadhnaGroup: { default: { flex: 0.2 }, mobile: { width: 150 } },
	}

	const headCells = [
		{
			field: 'title',
			numeric: false,
			headerName: 'Title',
			sortable: false,
		},
		{
			field: 'language',
			numeric: false,
			headerName: 'Language',
			sortable: false,
		},
		{
			field: 'mediaType',
			numeric: false,
			headerName: 'Media Type',
			sortable: false,
		},
		{
			field: 'sadhnaGroup',
			numeric: false,
			headerName: 'Sadhna Group',
			sortable: false,
		},
		{
			field: 'action',
			headerName: 'Action',
			renderCell: (params) => {
				return (
					<>
						<IconButton
							onClick={() => {
								window.open(params.row.url, '_blank')
							}}
							aria-label="View"
							color="primary"
							title="View"
							size="large"
						>
							<Visibility />
						</IconButton>
					</>
				)
			},
		},
	]

	const onFilterChange = (name, value) => {
		setState({
			filters: {
				[name]: value,
				page: 1,
			},
		})
	}

	const onPageSizeChange = ({ page, pageSize }) => {
		setState({
			filters: {
				page,
				pageSize,
			},
		})
	}

	const handleInput = (name, value) => {
		setState({
			form: {
				...form,
				[name]: value,
			},
		})
	}

	const createAction = () => {
		const validateForm = (form, libraryType) => {
			const { mediaType, title, lang, url, sadhnaGroup } = form
			const messages = {
				category: 'Category is invalid or missing.',
				mediaType: 'Media type is required.',
				title: 'Title is required.',
				lang: 'Language is required.',
				url: 'URL is empty or invalid.',
				sadhnaGroup: 'Sadhna group cannot be empty if selected.',
			}

			const urlPattern =
				/^(https?:\/\/)?([\w\-])+(\.[\w\-]+)+([\w\-,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?$/

			// Define validation conditions
			const validations = [
				[!LIBRARY_CATEGORY[libraryType]?.id, messages.category],
				[!mediaType, messages.mediaType],
				[!title, messages.title],
				[!lang, messages.lang],
				[!url || !urlPattern.test(url), messages.url], // URL validation
				[
					Array.isArray(sadhnaGroup) && sadhnaGroup.length === 0,
					messages.sadhnaGroup,
				],
			]

			// Iterate and dispatch alert for first failed validation
			for (const [condition, message] of validations) {
				if (condition) {
					store.dispatch(
						showAlert({
							message,
							severity: ALERT.ERROR,
							duration: 5000,
						})
					)
					return false
				}
			}

			return true
		}

		// Usage

		const body = {
			category: LIBRARY_CATEGORY[libraryType]?.id,
			mediaType: form.mediaType,
			sadhnaGroup: Array.isArray(form.sadhnaGroup)
				? form.sadhnaGroup.join(',')
				: '',
			title: form.title,
			lang: form.lang,
			url: form.url,
		}

		if (validateForm(form, libraryType)) {
			// Proceed with submission logic
			setState({
				loading: true,
			})
			createLibraryData(body).then((res) => {
				fetchData()
				setState({
					modal: false,
					loading: false,
					form: {
						category: '',
						mediaType: '',
						sadhnaGroup: [],
						title: '',
						lang: '',
						url: '',
					},
				})
			})
		}
	}

	return (
		<Root className="library-container">
			<Paper className="p-3">
				<div className="table-header-container">
					<div className="filter-header">
						<TextField
							id="input-with-icon-textfield"
							label="Search"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<GridSearchIcon />
									</InputAdornment>
								),
							}}
							name="search"
							onChange={(e) =>
								onFilterChange(e.target.name, e.target.value)
							}
							autoComplete="off"
							value={filters.search}
							className={'search-field'}
						/>

						<FormControl variant="outlined">
							<InputLabel id="library-language">
								Language
							</InputLabel>
							<Select
								labelId="library-language"
								id="filter-dropdown"
								value={filters.language}
								onChange={(e) =>
									onFilterChange(
										e.target.name,
										e.target.value
									)
								}
								label="Language"
								name="language"
							>
								<MenuItem value={''}>All Language</MenuItem>
								{LIBRARY_LANGUAGE.getAll().map((lang) => (
									<MenuItem value={lang.id}>
										{lang.text}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl variant="outlined">
							<InputLabel id="input-label">Media Type</InputLabel>
							<Select
								labelId="input-label"
								id="filter-dropdown"
								value={filters.mediaType}
								onChange={(e) =>
									onFilterChange(
										e.target.name,
										e.target.value
									)
								}
								label="Media Type"
								name="mediaType"
							>
								<MenuItem value={''}>All Media Types</MenuItem>
								{LIBRARY_MEDIA_TYPE.getAll(libraryType).map(
									(el) => (
										<MenuItem value={el.id}>
											{el.text}
										</MenuItem>
									)
								)}
							</Select>
						</FormControl>

						<FormControl variant="outlined">
							<InputLabel id="input-label">
								Sadhna Group
							</InputLabel>
							<Select
								multiple
								labelId="input-label"
								id="filter-dropdown"
								value={filters.sadhnaGroup}
								onChange={(e) =>
									onFilterChange(
										e.target.name,
										e.target.value
									)
								}
								label="Sadhna Group"
								name="sadhnaGroup"
							>
								<MenuItem value={''}>
									All Sadhna Groups
								</MenuItem>
								{SADHNA_GROUP.getAll().map((el) => (
									<MenuItem value={el.id}>{el.text}</MenuItem>
								))}
							</Select>
						</FormControl>
						<IconButton
							onClick={() => setState({ modal: true })}
							color="primary"
							variant="contained"
							size="large"
						>
							Add
						</IconButton>
					</div>
				</div>
			</Paper>
			<div style={{ background: 'white' }}>
				<CustomDataGrid
					rows={modifyProperty(data, '_id', 'id').map((el) => ({
						...el,
						mediaType: LIBRARY_MEDIA_TYPE.getById(el.mediaType)
							.text,
						language: LIBRARY_LANGUAGE.getById(el.lang).text,
						sadhnaGroup: isNaN(el.sadhnaGroup)
							? el.sadhnaGroup
									?.map((s) => SADHNA_GROUP.getById(s).text)
									.join(', ')
							: el.sadhnaGroup,
					}))}
					columns={headCells.map((col) => ({
						...col,
						sortable: true,
						disableClickEventBubbling: true,
						...(isMobile
							? rowWidthConfig[col.field].mobile ||
							  rowWidthConfig[col.field].default
							: rowWidthConfig[col.field].default),
					}))}
					rowCount={filters.total_results}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: filters.pageSize,
							},
						},
					}}
					disableColumnFilter
					loading={loading}
					paginationModel={filters}
					onPaginationModelChange={(page) =>
						onPageSizeChange({
							pageSize: page.pageSize,
							page:
								page.pageSize != filters.pageSize
									? 0
									: page.page,
						})
					}
				/>
			</div>
			<Dialog
				open={!!modal}
				onClose={() => setState({ modal: null })}
				className={classes.modal}
			>
				<div style={styles.paper}>
					<h1>Create Content</h1>
					<FormGroup row={1} sx={{ width: '100%' }}>
						<TextField
							className="m-top-16"
							label="Title"
							variant="outlined"
							value={form.title}
							required
							type="string"
							name="title"
							onChange={(e) =>
								handleInput(e.target.name, e.target.value)
							}
							fullWidth
						/>
						<TextField
							className="m-top-16"
							label="URL"
							variant="outlined"
							required
							value={form.url}
							type="string"
							name="url"
							onChange={(e) =>
								handleInput(e.target.name, e.target.value)
							}
							fullWidth
						/>
						<FormControl
							variant="outlined"
							fullWidth
							className="m-top-16"
						>
							<InputLabel id="library-language">
								Language
							</InputLabel>
							<Select
								labelId="library-language"
								id="filter-dropdown"
								value={form.lang}
								required
								onChange={(e) =>
									handleInput(e.target.name, e.target.value)
								}
								label="Language"
								name="lang"
							>
								<MenuItem value={''}>Select</MenuItem>
								{LIBRARY_LANGUAGE.getAll().map((lang) => (
									<MenuItem value={lang.id}>
										{lang.text}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl
							variant="outlined"
							fullWidth
							className="m-top-16"
						>
							<InputLabel id="input-label">Media Type</InputLabel>
							<Select
								labelId="input-label"
								id="filter-dropdown"
								value={form.mediaType}
								required
								onChange={(e) =>
									handleInput(e.target.name, e.target.value)
								}
								label="Media Type"
								name="mediaType"
							>
								<MenuItem value={''}>Select</MenuItem>
								{LIBRARY_MEDIA_TYPE.getAll(libraryType).map(
									(el) => (
										<MenuItem value={el.id}>
											{el.text}
										</MenuItem>
									)
								)}
							</Select>
						</FormControl>

						<FormControl
							variant="outlined"
							fullWidth
							className="m-top-16"
						>
							<InputLabel id="input-label">
								Sadhna Group
							</InputLabel>
							<Select
								multiple
								labelId="input-label"
								id="filter-dropdown"
								value={form.sadhnaGroup}
								required
								onChange={(e) =>
									handleInput(e.target.name, e.target.value)
								}
								label="Sadhna Group"
								name="sadhnaGroup"
							>
								<MenuItem value={''}>
									All Sadhna Groups
								</MenuItem>
								{SADHNA_GROUP.getAll().map((el) => (
									<MenuItem value={el.id}>{el.text}</MenuItem>
								))}
							</Select>
						</FormControl>
					</FormGroup>
					<div>
						<Button
							className="m-top-16"
							variant="contained"
							color="primary"
							startIcon={<CheckCircleRounded />}
							onClick={createAction}
							aria-label="Accept"
							style={{ marginRight: '12px' }}
						>
							Create
						</Button>
					</div>
				</div>
			</Dialog>
		</Root>
	)
}

export default LibraryContainer
