// /api/v1/learning?id=&category=&status=&mediaType=&sadhnaGroup=&title=&lang=

import { API_URL, METHOD } from '../constants/network'
import { serialize } from '../utils'
import { fetchAPI } from '../utils/network'

export const getLibraryData = (params) => {
	return fetchAPI({
		url: `${API_URL.LIBRARY_DATA}${serialize({
			...params,
		})}`,
		method: METHOD.GET,
	})
}

export const createLibraryData = (body) => {
	return fetchAPI({
		url: API_URL.LIBRARY_DATA,
		method: METHOD.POST,
		body,
	})
}
