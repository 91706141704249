import React, { useEffect, useRef, useState } from 'react'
import speaker1 from './assets/2.jpg'
import speaker2 from './assets/3.jpg'
import speaker3 from './assets/4.jpg'
import speaker4 from './assets/5.jpg'
import speaker5 from './assets/6.jpg'
import speaker6 from './assets/7.jpg'
import speaker7 from './assets/8.jpg'
import speaker8 from './assets/9.jpg'
import speaker9 from './assets/10.jpg'
import speaker10 from './assets/11.jpg'
import talk1 from './assets/talk/talk1.jpg'
import talk2 from './assets/talk/talk2.jpeg'
import talk3 from './assets/talk/talk3.jpg'
import talk4 from './assets/talk/talk4.jpg'
import talk5 from './assets/talk/talk5.jpg'
import talk8 from './assets/talk/talk8.jpeg'
import talk10 from './assets/talk/talk10.jpg'
import sponsor1 from './assets/sponsors/12.jpg'
import sponsor2 from './assets/sponsors/13.jpg'
import sponsor3 from './assets/sponsors/14.jpg'
import sponsor4 from './assets/sponsors/15.jpg'
import sponsor5 from './assets/sponsors/16.jpg'
import sponsor6 from './assets/sponsors/17.jpg'
import event01 from './assets/events/event01.jpg'
import event02 from './assets/events/event02.jpg'
import event03 from './assets/events/event03.jpg'
import event04 from './assets/events/event04.jpg'
import event05 from './assets/events/event05.jpg'
import event06 from './assets/events/event06.jpg'
import event07 from './assets/events/event07.jpg'
import event08 from './assets/events/event08.jpg'
import event09 from './assets/events/event09.jpg'
import event10 from './assets/events/event10.jpg'
import event11 from './assets/events/event11.jpg'
import event12 from './assets/events/event12.jpg'
import event13 from './assets/events/event13.jpg'
import event14 from './assets/events/event14.jpg'
import event15 from './assets/events/event15.jpg'
import event16 from './assets/events/event16.jpg'
import event17 from './assets/events/event17.jpg'
import event18 from './assets/events/event18.jpg'
import event19 from './assets/events/event19.jpg'
import event20 from './assets/events/event20.jpg'
import event21 from './assets/events/event21.jpg'
import event22 from './assets/events/event22.jpg'
import event23 from './assets/events/event23.jpg'
import event24 from './assets/events/event24.jpg'
import event25 from './assets/events/event25.jpg'
import event26 from './assets/events/event26.jpg'
import event29 from './assets/events/event29.jpg'
import event30 from './assets/events/event30.jpg'
import event31 from './assets/events/event31.jpg'
import event32 from './assets/events/event32.jpg'
import event33 from './assets/events/event33.jpg'
import { getRandomImage } from '../../../../utils'
import './style.scss'
import { EVENTS_UI_CONFIG } from '../../../../data/event'
import CheckIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import useBookNow from '../../useBookNow'

export const glimpses = [
	{
		title: 'ISKCON receiving Guiness World Record for Biggest Anti Addiction Awareness Campaign',
		imageUrl: event01,
	},
	{
		title: 'HH Gopal Krishna Goswami Maharaj delivering session on Viplava eve',
		imageUrl: event02,
	},
	{
		title: 'The Ideaman Drama',
		imageUrl: event03,
	},
	{
		title: 'Award ceremony for Youth devotees',
		imageUrl: event04,
	},
	{
		title: 'Vedic Mantra Invocation for all auspiciousness',
		imageUrl: event05,
	},
	{
		title: 'Devotees at the entry gate of Viplava',
		imageUrl: event06,
	},
	{
		title: 'Mridanga play by Gurukul boys',
		imageUrl: event07,
	},
	{
		title: 'Interview with HH Gopal Krishna Goswami Maharaj',
		imageUrl: event08,
	},
	{
		title: 'Kirtan Mela by devotees around the globe',
		imageUrl: event09,
	},
	{
		title: 'Excursion to different ancient sites of Govardhan',
		imageUrl: event10,
	},
	{
		title: 'Live Mridanga Performance',
		imageUrl: event12,
	},
	{
		title: 'Group Mantra Meditation',
		imageUrl: event13,
	},
	{
		title: 'Enthusiastic Kirtan and Dance',
		imageUrl: event14,
	},
	{
		title: 'More devotees. Happy devotees.',
		imageUrl: event15,
	},
	{
		title: 'Welcoming Cabinet Minister, GoI',
		imageUrl: event16,
	},
	{
		title: 'Offering Lamp (Dipa Daan) to the Lord',
		imageUrl: event17,
	},
	{
		title: 'Model presentation by different areas',
		imageUrl: event18,
	},
	{
		title: 'Theatrical performance by Siksharthakam',
		imageUrl: event19,
	},
	{
		title: 'Enlightening talk by HG Radhe Shyam Prabhu',
		imageUrl: event20,
	},
	{
		title: 'Rock Show by IIT Delhi devotees',
		imageUrl: event21,
	},
	{
		title: 'Loving exchanges among devotees',
		imageUrl: event22,
	},
	{
		title: 'Dance Performance by Prince Dance Group',
		imageUrl: event23,
	},
	{
		title: 'Ecstatic Mantra Rock Kirtan',
		imageUrl: event24,
	},
	{
		title: 'Dance Performance by Prince Dance Group',
		imageUrl: event25,
	},
	{
		title: 'Viplava 2022',
		imageUrl: event26,
	},
	{
		title: 'Sumptuous Prasadam',
		imageUrl: event29,
	},
	{
		title: 'Dance by Prince Dance Group',
		imageUrl: event30,
	},
	{
		title: 'Dance by Feel Crew',
		imageUrl: event31,
	},
	{
		title: 'Quiz Competition on Bhagavad Gita',
		imageUrl: event32,
	},
	{
		title: 'Debate on Modern Civilization vs Vedic Civilization',
		imageUrl: event33,
	},
]

const Viplava2023 = () => {
	const event = EVENTS_UI_CONFIG.find((eve) => eve.id === 'viplava')
	const events = [
		{
			title: 'Enlightening Sessions',
			desc: 'by eminent personalities',
			imageUrl: talk1,
		},
		{
			title: 'Kirtan Mela',
			desc: 'by great kirtaniyas of all time',
			imageUrl: talk2,
		},
		{
			title: 'Dance Performances',
			desc: 'by Crazy Hoppers',
			imageUrl: talk3,
		},
		{
			title: 'Live Interview',
			desc: 'HG Radhe Shyam Prabhu, President ISKCON Pune',
			imageUrl: talk4,
		},
		{
			title: 'Spine-chilling Drama',
			desc: 'by Siksharthakam on "Salted Bread"',
			imageUrl: talk5,
		},
		{
			title: 'Thrilling Debate',
			desc: 'with esteemed personalities',
			imageUrl: event11,
		},
		{
			title: 'Panel Discussion',
			desc: 'with highly successful youth devotees',
			imageUrl: event33,
		},
		{
			title: 'Talent Hunt Performances',
			desc: 'by Talent Hunt Finalist devotees',
			imageUrl: talk8,
		},
		{
			title: 'Vedic Quiz',
			desc: 'based on Bhagavad Gita',
			imageUrl: event32,
		},
		{
			title: 'Refreshing Excursion',
			desc: 'in the periphery of Sri Govardhan Dham',
			imageUrl: talk10,
		},
	]

	const speakers = [
		{
			title: 'HH Gopal Krishna Goswami Maharaj',
			desc: 'GBC for ISKCON India',
			desc2: 'Chairman and Trustee, Bhaktivedanta Book Trust',
			color: '#754E20',
			imageUrl: speaker1,
		},
		{
			title: 'HH Bhakti Prachar Parivrajaka Swami',
			desc: 'ISKCON Regional Secretary for many states of India',
			desc2: 'Chariman, ISKCON Bhagavata Mahavidyalaya',
			color: '#754E20',
			imageUrl: speaker2,
		},
		{
			title: 'HG Rukmini Krishna Prabhu',
			desc: 'Co-President ISKCON Punjabi Bagh Delhi',
			color: '#754E20',
			imageUrl: speaker3,
		},
		{
			title: 'HG Patri Prabhu',
			desc: 'ISKCON Regional Secretary for Nepal',
			desc2: "Awarded by Nepal's highest civilian award",
			color: '#754E20',
			imageUrl: speaker4,
		},
		{
			title: 'HG Radhe Shyam Prabhu',
			desc: 'President, ISKCON Pune and Director, VOICE',
			desc2: 'Author and Youth counselor',
			color: '#754E20',
			imageUrl: speaker5,
		},
		{
			title: 'HH Bhakti Ashraya Vaishnava Swami',
			desc: 'ISKCON Sannyasi and Senior Vaishnava',
			desc2: 'Renowned Krishna Katha Speaker',
			color: '#754E20',
			imageUrl: speaker6,
		},
		{
			title: 'HH BB Govinda Swami Maharaj',
			desc: 'ISKCON Zonal Secretary for countries like Syria & Lebanon',
			desc2: 'Famous Kirtaniya and Srila Prabhupada Disciple',
			color: '#754E20',
			imageUrl: speaker7,
		},
		{
			title: 'HG Gauranga Prabhu',
			desc: 'Director, ISKCON GEV - a UNWTO award winning eco-village',
			desc2: 'Co-President ISKCON Chowpatty',
			color: '#754E20',
			imageUrl: speaker8,
		},
		{
			title: 'HH Prabodhananda Saraswati Maharaj',
			desc: 'ISKCON Sannyasi and Senior Vaishnava',
			desc2: 'Renowned Krishna Katha Speaker',
			color: '#754E20',
			imageUrl: speaker9,
		},
		{
			title: 'HG Sundar Gopal Prabhu',
			desc: 'Chairman, ISKCON India Youth Council',
			desc2: 'Director, COASTOK and IYF',
			color: '#754E20',
			imageUrl: speaker10,
		},
	]
	const [counter, setCounter] = useState({
		days: 0,
		hours: 0,
		min: 0,
		sec: 0,
	})
	const [isScrolled, setIsScrolled] = useState(false)
	const counterRef = useRef(null)

	const listenScrollEvent = (event) => {
		const scrollValue = +document.body.scrollTop

		if (scrollValue) setIsScrolled(true)
		else setIsScrolled(false)
	}

	const { handleRegisterBtnClick } = useBookNow()

	useEffect(() => {
		const date = new Date('10/13/2023')
		counterRef.current = setInterval(() => {
			const now = new Date()
			const diff = date - now
			if (typeof setCounter === 'function')
				setCounter((c) => ({
					days: Math.floor(diff / 1000 / 60 / 60 / 24),
					hours: Math.floor((diff / 1000 / 60 / 60) % 24),
					min: Math.floor((diff / 1000 / 60) % 60),
					sec: Math.floor((diff / 1000) % 60),
				}))
		}, 1000)
		window.addEventListener('scroll', listenScrollEvent, true)
		return () => {
			clearInterval(counterRef.current)
			window.removeEventListener('scroll', listenScrollEvent, true)
		}
	}, [])

	return (
		<div className="viplava-2023-container">
			<header
				className="header"
				style={{ background: isScrolled ? 'rgba(0,0,0,0.7)' : '' }}
			>
				<div className="logo">Viplava</div>
				<nav className="navigation">
					{/* <div>
						<PhoneIcon fontSize="small" />
					</div> */}
					<div></div>
				</nav>
			</header>
			<div className="banner-section">
				<div className="heading">VIPLAVA</div>
				<div className="subheading">A REVOLUTION IN CONSCIOUSNESS</div>
				<div className="themebox">
					<hr className="line"></hr>
					<div className="line-1">TRANSFORMING</div>
					<div className="line-2">
						<span style={{ color: '#feb236' }}>IN</span>D
						<span style={{ color: '#b5e7a0' }}>IA</span>
					</div>
					<div className="line-3">THROUGH</div>
					<div className="line-4">BHAGAVAD GITA</div>
					<hr className="line"></hr>
				</div>
				<div className="text-white date">
					20<sup>TH</sup> - 22<sup>ND</sup> OCT 2023
				</div>

				<button
					className="primary-btn"
					onClick={(e) => handleRegisterBtnClick(event, e)}
				>
					REGISTER NOW
				</button>
				<div className="timer-container">
					<div className="timer-heading">REGISTER WITHIN</div>
					<div className="timer-body">
						<div className="time">
							<span className="value">{counter.days}</span>{' '}
							<span className="type">Days</span>
						</div>
						<div className="time">
							<span className="value">{counter.hours}</span>{' '}
							<span className="type">Hours</span>
						</div>
						<div className="time">
							<span className="value">{counter.min}</span>{' '}
							<span className="type">Mins</span>
						</div>
					</div>
				</div>
				<div className="text-white text-lg pt-3 text-center">
					Last Date of Registration (Extended) <br /> (12th Oct 2023)
				</div>
			</div>
			<div className="additional-section">
				<section id="about" className="about-fest">
					<div className="section-title">About the Fest</div>
					<div>
						The word "Viplava" literally means revolution. So the
						ISKCON is celebrating VIPLAVA so as to bring Revolution
						or transformation in the Consciousness of our youth,
						therby leading to their overall well-being. It will take
						place from October 20th to 22nd October 2023 at Sri
						Govardhan Dham, for 7500+ youth from across the nation
						to inspire and empower them.
					</div>
				</section>
				{/* <div className="transforming-img">
					<img alt="transforming india" src={transformingIndia} />
				</div> */}
				<div className="flexible-container">
					<iframe
						width="100%"
						src="https://www.youtube-nocookie.com/embed/cA7zWuFhx30?controls=0"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowFullScreen
					></iframe>
				</div>

				<button
					className="primary-btn mb-4"
					onClick={handleRegisterBtnClick}
				>
					REGISTER NOW
				</button>
			</div>
			<section id="events" className="event-section">
				<div className="section-title">Events</div>
				<div className="slider-wrapper">
					<div className="slider">
						{events.map((eve) => (
							<Slide
								key={eve.title}
								title={eve.title}
								desc={eve.desc}
								desc2={eve.desc2}
								imageUrl={eve.imageUrl}
								containerWidth={280}
								width={280}
								height={160}
							/>
						))}
					</div>
				</div>
				<button
					className="primary-btn mb-4"
					onClick={handleRegisterBtnClick}
				>
					REGISTER NOW
				</button>
			</section>
			<div className="speaker-section">
				<div className="section-title">Speakers</div>
				<div className="slider-wrapper">
					<div className="slider">
						{speakers.map((eve) => (
							<Slide
								key={eve.title}
								title={eve.title}
								desc={eve.desc}
								desc2={eve.desc2}
								imageUrl={eve.imageUrl}
								containerWidth={'80vw'}
								width={'100%'}
								height={280}
								color={eve.color}
							/>
						))}
					</div>
				</div>
			</div>
			<div className="additional-section">
				<div className="section-title">Glimpses</div>
				<Slider2 slides={glimpses} />
				<section id="details" className="about-fest">
					<div className="section-title">Pricing</div>
					<div className="details-section">
						<div className="price-card">
							<div className="line-1">WITHOUT TRANSPORTATION</div>
							{/* <div className="line-2">FOR ONE PERSON</div> */}
							<div className="price-container">
								<div className="price">
									<div className="line-3">₹1500/-</div>
									<ul className="list">
										<li className="list-item">
											<CheckIcon
												color="primary"
												style={{
													fontSize: '8px',
													marginRight: '2px',
												}}
											/>
											Event access
										</li>
										<li className="list-item">
											<CheckIcon
												color="primary"
												style={{
													fontSize: '8px',
													marginRight: '2px',
												}}
											/>
											Dormitory Stay
										</li>
										<li className="list-item">
											<CheckIcon
												color="primary"
												style={{
													fontSize: '8px',
													marginRight: '2px',
												}}
											/>
											Prasadam
										</li>
										<li className="list-item">
											<CancelIcon
												color="error"
												style={{
													fontSize: '8px',
													marginRight: '2px',
												}}
											/>
											Pick & Drop
										</li>
									</ul>
								</div>
								<div className="price">
									<div className="line-3">₹2000/-</div>
									<ul className="list">
										<li className="list-item">
											<CheckIcon
												color="primary"
												style={{
													fontSize: '8px',
													marginRight: '2px',
												}}
											/>
											Event access
										</li>
										<li className="list-item">
											<CheckIcon
												color="primary"
												style={{
													fontSize: '8px',
													marginRight: '2px',
												}}
											/>
											Room Stay
										</li>
										<li className="list-item">
											<CheckIcon
												color="primary"
												style={{
													fontSize: '8px',
													marginRight: '2px',
												}}
											/>
											Prasadam
										</li>
										<li className="list-item">
											<CancelIcon
												color="error"
												style={{
													fontSize: '8px',
													marginRight: '2px',
												}}
											/>
											Pick & Drop
										</li>
									</ul>
								</div>
								<div className="price">
									<div className="line-3">₹2500/-</div>
									<ul className="list">
										<li className="list-item">
											<CheckIcon
												color="primary"
												style={{
													fontSize: '8px',
													marginRight: '2px',
												}}
											/>
											Event access
										</li>
										<li className="list-item">
											<CheckIcon
												color="primary"
												style={{
													fontSize: '8px',
													marginRight: '2px',
												}}
											/>
											Premium Room
										</li>
										<li className="list-item">
											<CheckIcon
												color="primary"
												style={{
													fontSize: '8px',
													marginRight: '2px',
												}}
											/>
											Prasadam
										</li>
										<li className="list-item">
											<CancelIcon
												color="error"
												style={{
													fontSize: '8px',
													marginRight: '2px',
												}}
											/>
											Pick & Drop
										</li>
									</ul>
								</div>
							</div>
							<button
								className="primary-btn"
								onClick={handleRegisterBtnClick}
							>
								REGISTER NOW
							</button>
						</div>
						<div className="price-card">
							<div className="line-1">WITH TRANSPORTATION</div>
							{/* <div className="line-2">FOR ONE PERSON</div> */}
							<div className="price-container">
								<div className="price">
									<div className="line-3">₹2000/-</div>
									<ul className="list">
										<li className="list-item">
											<CheckIcon
												color="primary"
												style={{
													fontSize: '8px',
													marginRight: '2px',
												}}
											/>
											Event access
										</li>
										<li className="list-item">
											<CheckIcon
												color="primary"
												style={{
													fontSize: '8px',
													marginRight: '2px',
												}}
											/>
											Dormitory Stay
										</li>
										<li className="list-item">
											<CheckIcon
												color="primary"
												style={{
													fontSize: '8px',
													marginRight: '2px',
												}}
											/>
											Prasadam
										</li>
										<li className="list-item">
											<CheckIcon
												color="primary"
												style={{
													fontSize: '8px',
													marginRight: '2px',
												}}
											/>
											Pick & Drop
										</li>
									</ul>
								</div>
								<div className="price">
									<div className="line-3">₹2500/-</div>
									<ul className="list">
										<li className="list-item">
											<CheckIcon
												color="primary"
												style={{
													fontSize: '8px',
													marginRight: '2px',
												}}
											/>
											Event access
										</li>
										<li className="list-item">
											<CheckIcon
												color="primary"
												style={{
													fontSize: '8px',
													marginRight: '2px',
												}}
											/>
											Room Stay
										</li>
										<li className="list-item">
											<CheckIcon
												color="primary"
												style={{
													fontSize: '8px',
													marginRight: '2px',
												}}
											/>
											Prasadam
										</li>
										<li className="list-item">
											<CheckIcon
												color="primary"
												style={{
													fontSize: '8px',
													marginRight: '2px',
												}}
											/>
											Pick & Drop
										</li>
									</ul>
								</div>
								<div className="price">
									<div className="line-3">₹3000/-</div>
									<ul className="list">
										<li className="list-item">
											<CheckIcon
												color="primary"
												style={{
													fontSize: '8px',
													marginRight: '2px',
												}}
											/>
											Event access
										</li>
										<li className="list-item">
											<CheckIcon
												color="primary"
												style={{
													fontSize: '8px',
													marginRight: '2px',
												}}
											/>
											Premium Room
										</li>
										<li className="list-item">
											<CheckIcon
												color="primary"
												style={{
													fontSize: '8px',
													marginRight: '2px',
												}}
											/>
											Prasadam
										</li>
										<li className="list-item">
											<CheckIcon
												color="primary"
												style={{
													fontSize: '8px',
													marginRight: '2px',
												}}
											/>
											Pick & Drop
										</li>
									</ul>
								</div>
							</div>
							<button
								className="primary-btn"
								onClick={handleRegisterBtnClick}
							>
								REGISTER NOW
							</button>
						</div>
					</div>
				</section>
				<div className="final-section">
					<div className="target">7500+ Youths</div>
					<div className="location">
						<p>SRI GOVARDHAN DHAM</p>
						<p>MATHURA (U.P.)</p>
					</div>
					<div className="btn-group">
						<button
							className="primary-btn"
							onClick={handleRegisterBtnClick}
						>
							REGISTER NOW
						</button>
					</div>
				</div>
			</div>
			<div className="sponsor-section">
				<div className="section-title">Our Sponsors</div>
				<div className="sponsors">
					<img
						alt="sponsor-1"
						className="sponsor-logo"
						src={sponsor1}
					/>
					<img
						alt="sponsor-2"
						className="sponsor-logo"
						src={sponsor2}
					/>
					<img
						alt="sponsor-3"
						className="sponsor-logo"
						src={sponsor3}
					/>
					<img
						alt="sponsor-4"
						className="sponsor-logo"
						src={sponsor4}
					/>
					<img
						alt="sponsor-4"
						className="sponsor-logo"
						src={sponsor5}
					/>
					<img
						alt="sponsor-4"
						className="sponsor-logo"
						src={sponsor6}
					/>
				</div>
			</div>
			<footer>
				<div className="copyright">
					&copy; 2023 All rights reserved by COASTOK
				</div>
				<div className="links">
					<a href="#about">About</a>
					<a href="#events">Events</a>
					<a href="#details">Details</a>
				</div>
			</footer>
		</div>
	)
}

const Slide = React.memo(
	({
		title,
		desc,
		desc2,
		width,
		height,
		slideColor,
		className,
		containerWidth,
		imageUrl,
		isGlimpses,
	}) => {
		const cardColor =
			slideColor ||
			`rgb(${Math.round(Math.random() * 128)}, 
						${Math.round(Math.random() * 128)}, ${Math.round(Math.random() * 128)})`
		return (
			<div
				className={'slide ' + className}
				style={{
					borderColor: cardColor,
					backgroundColor: cardColor,
					width: containerWidth,
				}}
			>
				<img
					alt={title}
					src={
						imageUrl ||
						getRandomImage({
							width,
							height,
							keyword: 'nature',
							random: false,
						})
					}
					style={
						isGlimpses ? {} : { height, width, objectFit: 'cover' }
					}
				/>
				{title && <div className="title">{title}</div>}
				{desc && <div className="desc">{desc}</div>}
				{desc2 && <div className="desc">{desc2}</div>}
			</div>
		)
	}
)

export const Slider2 = ({ slides }) => {
	const [currentSlide, setCurrentSlide] = useState(0)
	let sliderCountRef = useRef(null)

	const backLeft2 = (slides.length + currentSlide - 2) % slides.length
	const backLeft1 = (slides.length + currentSlide - 1) % slides.length
	const center = currentSlide % slides.length
	const backRight1 = (slides.length + currentSlide + 1) % slides.length
	const backRight2 = (slides.length + currentSlide + 2) % slides.length

	useEffect(() => {
		sliderCountRef.current = setInterval(setSlide, 3000)
		return () => {
			clearInterval(sliderCountRef.current)
		}
	}, [])

	const setSlide = () => {
		setCurrentSlide((currentSlide) => currentSlide + 1)
	}

	const getClassName = (index) => {
		switch (index) {
			case backLeft2:
				return 'back-left-2'
			case backRight2:
				return 'back-right-2'
			case backLeft1:
				return 'back-left-1'
			case backRight1:
				return 'back-right-1'
			case center:
				return 'center'
			default:
				return 'hide'
		}
	}

	return (
		<div className="slider-container-2">
			<div className="slider">
				{slides.map((s, i) => (
					<div key={s.title} className={getClassName(i)}>
						<Slide
							className="rounded"
							width={300}
							height={200}
							imageUrl={s.imageUrl}
							isGlimpses
						/>
					</div>
				))}
			</div>
			<div className="description">
				{slides.map((s, i) => (
					<div
						key={s.title}
						className={getClassName(i) !== 'center' && 'hide'}
					>
						{s.title}
					</div>
				))}
			</div>
			<div className="bullets">
				{slides.map((s, i) => (
					<span
						key={s.title}
						className={`bullet ${
							i === currentSlide % slides.length
								? 'highlight'
								: ''
						}`}
					></span>
				))}
			</div>
		</div>
	)
}

export default Viplava2023
