export const isDevEnv =
	window.origin.includes('dev.coastok') || window.origin.includes('localhost')

export const RAZORPAY_KEY_ID = isDevEnv
	? 'rzp_test_yd402yyfCIqBcW'
	: 'rzp_live_OH7PwZodMHsDfF'

export const AXIS_RAZORPAY_KEY_ID = isDevEnv
	? 'rzp_test_2149AfriFF17OJ'
	: 'rzp_live_GKZ4PRVZ86ic8O'

export const GOOGLE_CLIENT_ID =
	'16880015092-iv8a80mpfqtu3eg091l98mk3pd1k20q2.apps.googleusercontent.com'
