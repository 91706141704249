import {
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	Modal,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useState } from 'react'
import BlessedIcon from '../../../assets/icons/Blessed'

const PREFIX = 'DonationModal'

const classes = {
	modal: `${PREFIX}-modal`,
}

const StyledModal = styled(Modal)(({ theme }) => ({
	[`&.${classes.modal}`]: {
		display: 'flex',
		// padding: theme.spacing(8),
		alignItems: 'center',
		justifyContent: 'center',
	},
}))

function DonationModal({ visible, onClose, onSubmit }) {
	const [donation, setDonation] = useState(0)
	return (
		<StyledModal open={visible} onClose={onClose} className={classes.modal}>
			<div className={classes.paper}>
				<BlessedIcon />
				<Typography
					variant="h3"
					style={{
						marginBottom: '20px',
						textAlign: 'center',
					}}
				>
					Your little contribution can help other devotees to take
					part in Viplava Fest. Kindly volunteer in this act of
					goodwill.
				</Typography>
				<form
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<FormControl>
						<FormLabel>Donation</FormLabel>
						<RadioGroup
							aria-label="donation"
							name="donation"
							value={donation}
							onChange={(e) =>
								setDonation(
									(donation) => (donation += +e.target.value)
								)
							}
							row
						>
							<FormControlLabel
								value={100}
								control={<Radio />}
								label="₹100"
							/>
							<FormControlLabel
								value={500}
								control={<Radio />}
								label="₹500"
							/>
							<FormControlLabel
								value={1000}
								control={<Radio />}
								label="₹1000"
							/>
						</RadioGroup>
					</FormControl>
					<TextField
						label="Any other donation amount"
						variant="outlined"
						defaultValue={donation}
						TabPa
						value={donation}
						name="donation"
						onChange={(e) =>
							this.onChange(e.target.name, +e.target.value)
						}
						fullWidth
						type="number"
					/>
					<Button
						variant="contained"
						color="primary"
						onClick={() => onSubmit(donation)}
						style={{ marginTop: '20px' }}
					>
						Add Donation of ₹{donation}
					</Button>
				</form>
			</div>
		</StyledModal>
	)
}

export default DonationModal
