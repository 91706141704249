import { format } from 'date-fns'
import { API_URL, METHOD } from '../constants/network'
import { serialize } from '../utils'
import { fetchAPI } from '../utils/network'

export const getBookingStatus = (body) => {
	const url = `${API_URL.EVENT_BOOKING}${serialize(body)}`
	return fetchAPI({
		url,
	})
}

export const getSubsidyList = (body) => {
	const url = `${API_URL.EVENT_BOOKING}${serialize({
		...body,
		status: '2,13',
	})}`
	return fetchAPI({
		url,
	})
}

export const getCashList = (body) => {
	const url = `${API_URL.EVENT_BOOKING}${serialize({
		...body,
	})}`
	return fetchAPI({
		url,
	})
}

export const getFundBalance = (areaId) => {
	const url = `${API_URL.FUND_BALANCE}${serialize({ areaId })}`
	return fetchAPI({
		url,
	})
}

export const approveSubsidyRequest = (body) => {
	const url = `${API_URL.BOOKING_SUBSIDY}`
	return fetchAPI({
		url,
		method: METHOD.POST,
		body,
	})
}

export const approveCashRequest = (body) => {
	const url = `${API_URL.CASH_APPROVE}`
	return fetchAPI({
		url,
		method: METHOD.POST,
		body,
	})
}

export const rejectSubsidyRequest = (body) => {
	const url = `${API_URL.REJECT_BOOKING_SUBSIDY}`
	return fetchAPI({
		url,
		method: METHOD.POST,
		body,
	})
}

export const manageFundsRequest = (body) => {
	const url = `${API_URL.FUND}`
	const todayDate = format(new Date(), 'dd-MM-yyyy HH:mm')
	return fetchAPI({
		url,
		method: METHOD.POST,
		body: {
			...body,
			comments: `Added Funds on ${todayDate}`,
		},
	})
}

export const getInventoryData = (body) => {
	const url = `${API_URL.INVENTORY}${serialize(body)}`
	return fetchAPI({
		url,
		method: METHOD.GET,
		body,
		isOpenApi: false,
	})
}

export const createInventryData = (body) => {
	const url = `${API_URL.INVENTORY}`
	return fetchAPI({
		url,
		method: METHOD.POST,
		body,
	})
}

export const updateInventryData = (body, inventoryId) => {
	const url = `${API_URL.INVENTORY}/${inventoryId}`
	return fetchAPI({
		url,
		method: METHOD.PUT,
		body,
	})
}

export const createAttendanceQR = (body) => {
	const url = `${API_URL.CREATE_ATTENDANCE_QRS}`
	return fetchAPI({
		url,
		method: METHOD.POST,
		body,
	})
}

export const getAttendanceQRData = (params) => {
	const url = `${API_URL.GET_ATTENDANCE_QRS}/${serialize(params)}`
	return fetchAPI({
		url,
		method: METHOD.GET,
	})
}

export const getUserAttendanceList = (params) => {
	const url = `${API_URL.GET_USER_ATTENDANCE}/${serialize(params)}`
	return fetchAPI({
		url,
		method: METHOD.GET,
	})
}
