import React from 'react'
import FailureScreen from './acknowledgement/FailureScreen'
import SubsidyInProgress from './acknowledgement/SubsidyInProgress'
import CashInProgress from './acknowledgement/CashInProgress'
import SuccessScreen from './acknowledgement/SuccessScreen'
import PartiallyPaid from './acknowledgement/PartiallyPaid'
import SubsidyApproved from './acknowledgement/SubsidyApproved'
import usePayment from '../usePayment'

const AcknowledgmentScreen = ({
	name,
	bookingStatus,
	bookingReferenceNo,
	bookingDateTime,
	eventName,
	userDetails,
	finalAmount,
	amountAfterSubsidy,
	paidAmount,
	booking,
	onPaymentSuccess,
	onPaymentFailure,
	eventUiConfig,
}) => {
	const { initiatePayment } = usePayment({
		booking,
		userDetails,
		onPaymentSuccess,
		onPaymentFailure,
		eventUiConfig,
	})

	if (bookingStatus === 'CONFIRMED' || bookingStatus === 'CASH_CONFIRMED') {
		return (
			<SuccessScreen
				name={name}
				bookingReferenceNo={bookingReferenceNo}
				bookingDateTime={bookingDateTime}
				eventName={eventName}
				referrer={userDetails?.phone}
				paidAmount={paidAmount}
			/>
		)
	} else if (bookingStatus === 'SUBSIDY_REQUESTED') {
		return (
			<SubsidyInProgress
				name={name}
				referrer={userDetails?.phone}
				amountAfterSubsidy={amountAfterSubsidy}
				paidAmount={paidAmount}
				finalAmount={finalAmount}
				initiatePayment={initiatePayment}
				type="cash"
			/>
		)
	} else if (bookingStatus === 'PREPAID_SUBSIDY_REQUESTED') {
		return (
			<SubsidyInProgress
				name={name}
				referrer={userDetails?.phone}
				amountAfterSubsidy={amountAfterSubsidy}
				finalAmount={finalAmount}
				paidAmount={paidAmount}
				initiatePayment={initiatePayment}
				type="online"
			/>
		)
	} else if (bookingStatus === 'SUBSIDY_APPROVED') {
		return (
			<SubsidyApproved
				name={name}
				bookingReferenceNo={bookingReferenceNo}
				bookingDateTime={bookingDateTime}
				referrer={userDetails?.phone}
				finalAmount={finalAmount}
			/>
		)
	} else if (
		bookingStatus === 'CASH_PENDING' ||
		bookingStatus === 'CASH_PARTIALLY_PAID'
	) {
		return (
			<CashInProgress
				name={name}
				bookingReferenceNo={bookingReferenceNo}
				bookingDateTime={bookingDateTime}
				referrer={userDetails?.phone}
				finalAmount={finalAmount}
				paidAmount={paidAmount}
				initiatePayment={initiatePayment}
			/>
		)
	} else if (bookingStatus === 'PARTIALLY_PAID') {
		return (
			<PartiallyPaid
				name={name}
				bookingReferenceNo={bookingReferenceNo}
				bookingDateTime={bookingDateTime}
				referrer={userDetails?.phone}
				finalAmount={finalAmount}
				paidAmount={paidAmount}
				initiatePayment={initiatePayment}
			/>
		)
	}
	return <FailureScreen name={name} bookingReferenceNo={bookingReferenceNo} />
}

export default AcknowledgmentScreen
