import { string, number, object, mixed } from 'yup'
import {
	getInvalidMessage,
	getRequiredErrorMessage,
} from '../../utils/formValidation'

export const getAmounts = ({
	transportation,
	accomodation,
	outfit,
	donation,
	event,
}) => {
	const amounts = {
		registrationAmount: event?.registrationAmount,
		donationAmount: 0,
		accomodationAmount: 0,
		transportationAmount: 0,
		outfitAmount: 0,
	}
	if (transportation)
		amounts.transportationAmount = event?.transportationAmount
	if (accomodation + 1)
		amounts.accomodationAmount =
			event?.accomodationCategory[accomodation]?.amount
	if (outfit) amounts.outfitAmount = outfit * event?.outfitAmount
	if (donation) amounts.donationAmount = +donation

	return amounts
}

export const calculateTotal = (amounts) => {
	// amounts.outfitAmount = 0; // DHOTI KURTA IS FREE!
	return Object.values(amounts).reduce((acc, curr) => acc + curr, 0)
}

export const basicValidationSchema = object({
	name: string().required(getRequiredErrorMessage('Name')),
	initiatedName: string(),
	phone: number()
		.required(getRequiredErrorMessage('Phone number'))
		.min(5000000000, getInvalidMessage('Phone number'))
		.max(9999999999, getInvalidMessage('Phone number'))
		.test(
			'len',
			'Phone number should be 10 digit only.',
			(val) => val && val.toString().length === 10
		),
	email: string().required(getRequiredErrorMessage('Email')),
	facilitator: mixed().required(getRequiredErrorMessage('Facilitator')),
	connectedAreaId: mixed().required(
		getRequiredErrorMessage('Connected Area')
	),
	chantingRounds: number()
		.required(getRequiredErrorMessage('Chanting Rounds'))
		.min(2, 'Chanting rounds must be greater than or equal to 2.'),
})

export const getFinalAmount = (booking) => {
	const finalAmountArray = []
	if (booking.transportationAmount > 0) {
		finalAmountArray.push(`Transportation: ${booking.transportationAmount}`)
	}
	if (booking.registrationAmount > 0) {
		finalAmountArray.push(`Registration: ${booking.registrationAmount}`)
	}
	if (booking.accomodationAmount > 0) {
		finalAmountArray.push(`Accomodation: ${booking.accomodationAmount}`)
	}
	if (booking.outfitAmount > 0) {
		finalAmountArray.push(`Dhoti Kurta: ${booking.outfitAmount}`)
	}

	return finalAmountArray
}
