import React from "react";
import './style.scss';

const BlessedIcon = () => {
  return (
    <svg
      width="96px"
      height="96px"
      viewBox="0 0 2050 2050"
      xmlns="http://www.w3.org/2000/svg"
      className="blessed-icon"
    >
      <g data-name="Layer 1" id="Layer_1">
        <rect className="cls-1" height="2040" width="2040" x="5" y="5" />
      </g>
      <g data-name="Layer 3" id="Layer_3">
        <circle className="cls-2" cx="1025" cy="1109.1" r="579.4" />
        <path
          className="cls-3"
          d="M1025,862.9c189.4,0,359.4-32.8,475.6-84.9C1395.9,627.9,1221.9,529.7,1025,529.7S654.1,627.9,549.4,778C665.6,830.1,835.6,862.9,1025,862.9Z"
        />
        <path
          className="cls-4"
          d="M1038.6,361.5c-302.2,0-547.2,91.6-547.2,204.6s245,204.5,547.2,204.5,547.2-91.6,547.2-204.5S1340.8,361.5,1038.6,361.5Zm0,315.6c-209.2,0-378.7-49.7-378.7-111s169.5-111,378.7-111,378.8,49.7,378.8,111S1247.8,677.1,1038.6,677.1Z"
        />
        <path
          className="cls-5"
          d="M1023.5,1462.5c-76.5,0-148.6-30.7-202.9-86.5a30,30,0,1,1,43-41.9c42.9,44.1,99.7,68.4,159.9,68.4s119.3-25.3,162.6-71.2a30,30,0,1,1,43.6,41.1,285.4,285.4,0,0,1-92.3,65.8A275.9,275.9,0,0,1,1023.5,1462.5Z"
        />
        <path
          className="cls-5"
          d="M825.5,1174.6c-29.9,0-59.2-13.7-86.8-40.7a30,30,0,0,1,41.9-43c16,15.7,31.2,23.7,44.9,23.7s28.9-8,44.9-23.7a30,30,0,1,1,41.9,43C884.7,1160.9,855.5,1174.6,825.5,1174.6Z"
        />
        <path
          className="cls-5"
          d="M1224.5,1174.6c-30,0-59.2-13.7-86.8-40.7a30,30,0,1,1,41.9-43c16,15.7,31.1,23.7,44.9,23.7s28.9-8,44.9-23.7a30,30,0,0,1,41.9,43C1283.7,1160.9,1254.4,1174.6,1224.5,1174.6Z"
        />
      </g>
    </svg>
  );
};

export default BlessedIcon;