import React from 'react'
import { createRoot } from 'react-dom/client'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { Provider } from 'react-redux'
import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './store'
import { theme } from './constants/theme'
import './index.css'
import { CssBaseline } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { GOOGLE_CLIENT_ID } from './constants/config'
import { GoogleOAuthProvider } from '@react-oauth/google'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
	<Provider store={store}>
		<GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
			<StyledEngineProvider injectFirst>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<ThemeProvider theme={theme}>
						<CssBaseline />
						<App />
					</ThemeProvider>
				</LocalizationProvider>
			</StyledEngineProvider>
		</GoogleOAuthProvider>
	</Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
