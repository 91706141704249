import {
	GET_EVENT_DATA_STARTED,
	GET_EVENT_DATA_SUCCESS,
	GET_EVENT_DATA_FAILED,
	GET_SUBSIDY_LIST_STARTED,
	GET_SUBSIDY_LIST_FAILED,
	GET_SUBSIDY_LIST_SUCCESS,
	GET_FUND_BALANCE_SUCCESS,
	GET_FUND_BALANCE_FAILED,
	GET_FUND_BALANCE_STARTED,
	APPROVE_SUBSIDY_SUCCESS,
	APPROVE_SUBSIDY_STARTED,
	APPROVE_SUBSIDY_FAILED,
	REJECT_SUBSIDY_STARTED,
	REJECT_SUBSIDY_SUCCESS,
	GET_CASH_LIST_SUCCESS,
	GET_CASH_LIST_STARTED,
	GET_CASH_LIST_FAILED,
	APPROVE_CASH_STARTED,
	APPROVE_CASH_SUCCESS,
	APPROVE_CASH_FAILED,
	GET_INVENTORY_DATA_STARTED,
	GET_INVENTORY_DATA_SUCCESS,
	CREATE_INVENTORY_DATA_SUCCESS,
	CREATE_INVENTORY_DATA_FAILURE,
	CREATE_INVENTORY_DATA_STARTED,
	GET_ATTENDANCE_QRS_STARTED,
	CREATE_ATTENDANCE_QR_STARTED,
	GET_USER_ATTENDANCE_STARTED,
	GET_USER_ATTENDANCE_SUCCESS,
	GET_ATTENDANCE_QRS_SUCCESS,
	GET_USER_ATTENDANCE_FAILURE,
	CREATE_ATTENDANCE_QR_FAILURE,
	GET_ATTENDANCE_QRS_FAILURE,
} from '../constants/actions'
import { ALERT } from '../constants'

let initialState = {
	events: [],
	loading: false,
	error: null,
	alert: null,
	subsidies: [],
	cashRequests: [],
	funds: null,
	attendanceQrData: [],
	attendanceData: [],
}
const eventReducer = (state = initialState, action) => {
	switch (action.type) {
		case APPROVE_SUBSIDY_STARTED:
		case REJECT_SUBSIDY_STARTED:
		case GET_FUND_BALANCE_STARTED:
		case GET_SUBSIDY_LIST_STARTED:
		case GET_CASH_LIST_STARTED:
		case APPROVE_CASH_STARTED:
		case GET_ATTENDANCE_QRS_STARTED:
		case CREATE_ATTENDANCE_QR_STARTED:
		case GET_USER_ATTENDANCE_STARTED:
		case GET_EVENT_DATA_STARTED:
			return {
				...state,
				loading: true,
			}
		case GET_EVENT_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				events: action.payload,
			}
		case GET_SUBSIDY_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				subsidies: action.payload,
			}
		case GET_CASH_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				cashRequests: action.payload,
			}
		case GET_FUND_BALANCE_SUCCESS:
			return {
				...state,
				loading: false,
				funds: action.payload[0].fund,
			}
		case APPROVE_SUBSIDY_SUCCESS:
			return {
				...state,
				loading: false,
				subsidies: {
					...state.subsidies,
					total_results: state.subsidies.total_results - 1,
					data: state.subsidies.data.filter(
						(e) => e._id !== action.payload.id
					),
				},
				funds: {
					...state.funds,
					totalBalance:
						state.funds.totalBalance - action.payload.subsidy,
					holdBalance:
						state.funds.holdBalance - action.payload.subsidy,
				},
			}
		case REJECT_SUBSIDY_SUCCESS:
			return {
				...state,
				loading: false,
				subsidies: {
					...state.subsidies,
					total_results: state.subsidies.total_results - 1,
					data: state.subsidies.data.filter(
						(e) => e._id !== action.payload.id
					),
				},
			}
		case APPROVE_CASH_SUCCESS:
			return {
				...state,
				loading: false,
				cashRequests: {
					...state.cashRequests,
					total_results: state.cashRequests.total_results - 1,
					data: state.cashRequests?.data?.filter(
						(e) => e._id !== action.payload.id
					),
				},
			}
		case APPROVE_SUBSIDY_FAILED:
		case GET_FUND_BALANCE_FAILED:
		case GET_SUBSIDY_LIST_FAILED:
		case GET_CASH_LIST_FAILED:
		case APPROVE_CASH_FAILED:
		case GET_USER_ATTENDANCE_FAILURE:
		case CREATE_ATTENDANCE_QR_FAILURE:
		case GET_ATTENDANCE_QRS_FAILURE:
		case GET_EVENT_DATA_FAILED:
			return {
				...state,
				loading: false,
				error: action.error,
				alert: {
					message: action.error,
					severity: ALERT.ERROR,
					duration: 5000,
				},
			}
		case GET_INVENTORY_DATA_STARTED:
			return {
				...state,
				loading: true,
			}
		case GET_INVENTORY_DATA_SUCCESS:
			const { inventoryData } = action.payload || {}
			return {
				...state,
				loading: false,
				inventoryData,
			}

		case CREATE_INVENTORY_DATA_STARTED:
			return {
				...state,
				loading: true,
			}
		case CREATE_INVENTORY_DATA_SUCCESS:
		case CREATE_INVENTORY_DATA_FAILURE:
			return {
				...state,
				loading: false,
			}
		case GET_USER_ATTENDANCE_SUCCESS:
			return {
				...state,
				attendanceData: action.payload?.data || [],
				loading: false,
			}
		case GET_ATTENDANCE_QRS_SUCCESS:
			return {
				...state,
				attendanceQrData: action.payload?.data || [],
				loading: false,
			}

		default:
			return state
	}
}

export default eventReducer
