import React from 'react'
import { styled } from '@mui/material/styles'
import {
	Button,
	IconButton,
	InputAdornment,
	Modal,
	Paper,
	TextField,
} from '@mui/material'
import { AddCircle, Delete, Edit, Tune, Visibility } from '@mui/icons-material'
import UpdatePledgingPopup from './UpdatePledging'
import { getPledgeAction } from '../../actions/pledge'
import { connect } from 'react-redux'
import { DataGrid, GridSearchIcon } from '@mui/x-data-grid'
import { format } from 'date-fns'
import { ROUTE } from '../../constants/route'
import { closeDialog, showDialog } from '../../actions'
import { isMobileScreen } from '../../utils'
import './style.scss'

const styles = {
	tableHeader: {
		padding: '20px',
		marginBottom: '20px',
	},
}

const Root = styled('div')({})

class Pledging extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
		this.isMobile = isMobileScreen()
	}

	componentDidMount() {
		this.props.getPledgeAction()
	}

	viewPledgeDrive = (row) => {
		const { history } = this.props
		history.push(`${ROUTE.PLEDGING.path}/${row._id}`)
	}

	editPledgeDrive = (row) => {
		this.setState({
			modalData: row,
			modalType: 'EDIT',
		})
	}

	createPledgeDrive = () => {
		this.setState({
			modalData: {},
			modalType: 'EDIT',
		})
	}

	deletePledgeDrive = () => {
		this.props.showDialog({
			title: 'Delete this Pledge Drive?',
			description:
				"Are you sure to delete this Pledge Drive? This action won't be reverted",
			actions: [
				{
					action: this.props.closeDialog,
					text: 'Cancel',
					color: 'secondary',
				},
				{ action: this.props.closeDialog, text: 'Yes, Delete Pledge' },
			],
		})
	}

	onModalClose = () => {
		this.setState({
			modalData: null,
		})
	}

	populateData = (pledges, isEditAccess) => {
		const rowWidthConfig = {
			tag: { default: { flex: 0.25 }, mobile: { width: 150 } },
			status: { default: { flex: 0.15 }, mobile: { width: 100 } },
			createdAt: { default: { width: 130 } },
			startTime: { default: { flex: 0.1 }, mobile: { width: 100 } },
			endTime: { default: { flex: 0.1 }, mobile: { width: 100 } },
			pledge: {
				default: {
					width: 160,
				},
			},
		}
		const headCells = [
			{
				field: 'tag',
				numeric: false,
				headerName: 'Tag',
				sortable: false,
			},
			{
				field: 'status',
				numeric: false,
				headerName: 'Status',
				sortable: false,
			},
			{
				field: 'createdAt',
				numeric: false,
				headerName: 'Created At',
				sortable: false,
			},
			{
				field: 'startTime',
				numeric: false,
				headerName: 'Start Time',
				sortable: true,
			},
			{
				field: 'endTime',
				numeric: false,
				headerName: 'End Time',
				sortable: true,
			},
			{
				field: 'pledge',
				headerName: 'Actions',
				sortable: false,
				filterable: false,
				renderCell: (params) => (
					<strong>
						<IconButton
							onClick={() => this.viewPledgeDrive(params.value)}
							aria-label="View"
							color="primary"
							title="View"
							size="large"
						>
							<Visibility />
						</IconButton>
						{isEditAccess && (
							<>
								<IconButton
									onClick={() =>
										this.editPledgeDrive(params.value)
									}
									aria-label="Edit"
									color="primary"
									title="Edit"
									size="large"
								>
									<Edit />
								</IconButton>
								<IconButton
									onClick={() =>
										this.deletePledgeDrive(params.value)
									}
									aria-label="Delete"
									color="primary"
									title="Delete"
									size="large"
								>
									<Delete />
								</IconButton>
							</>
						)}
					</strong>
				),
				width: 160,
			},
		]
		const data = [
			{
				btnLabel: 'View',
				btnClick: this.viewPledgeDrive,
			},
		]
		if (isEditAccess) {
			data.push({
				btnLabel: 'Edit',
				btnClick: this.editPledgeDrive,
			})
		}
		const { total_results, loading } = this.props
		const { modalData, modalType } = this.state
		return (
			<Root className="pledging-container">
				{modalData && (
					<Modal open={!!modalData} onClose={this.onModalClose}>
						{modalType === 'EDIT' && (
							<div className="c-modal">
								<UpdatePledgingPopup
									pledgingId={modalData._id}
									tag={modalData.tag}
									msg={modalData.msg}
									startTime={modalData.startTime}
									endTime={modalData.endTime}
									closeHandler={this.onModalClose}
								/>
							</div>
						)}
					</Modal>
				)}
				<Paper style={styles.tableHeader}>
					<div className="table-header-container">
						<TextField
							id="input-with-icon-textfield"
							label="Search"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<GridSearchIcon />
									</InputAdornment>
								),
							}}
							onChange={(e) => this.handleSearch(e.target.value)}
							autoComplete="off"
							value={this.state.searchValue}
							className="search-field"
						/>
						<div className="button-container">
							{isEditAccess && (
								<Button
									startIcon={<AddCircle />}
									color="primary"
									variant="contained"
									onClick={this.createPledgeDrive}
								>
									Create Pledge
								</Button>
							)}
							<Button
								startIcon={<Tune />}
								color="primary"
								variant="contained"
								onClick={this.openFilterModal}
							>
								Filter
							</Button>
						</div>
					</div>
				</Paper>
				<div style={{ width: '100%', background: 'white' }}>
					<DataGrid
						rows={pledges
							.filter((pledge) => {
								return this.state.searchValue
									? this.searchRows(
											pledge,
											this.state.searchValue
									  )
									: true
							})
							.map((pledge) => ({
								...pledge,
								pledge,
								id: pledge._id,
								status: pledge.active ? 'ACTIVE' : 'INACTIVE',
								createdAt: format(
									new Date(pledge.createdAt),
									'dd MMM yyyy'
								),
								startTime: format(
									new Date(pledge.startTime),
									'dd MMM yyyy haaa'
								),
								endTime: format(
									new Date(pledge.endTime),
									'dd MMM yyyy haaa'
								),
							}))}
						autoHeight
						columns={headCells.map((col) => ({
							...col,
							sortable: true,
							disableClickEventBubbling: true,
							...(this.isMobile
								? rowWidthConfig[col.field].mobile ||
								  rowWidthConfig[col.field].default
								: rowWidthConfig[col.field].default),
						}))}
						pageSize={10}
						rowCount={total_results}
						pageSizeOptions={[5, 10, 20]}
						pagination
						// paginationMode="server"
						// onPageChange={this.handlePledgeDriveData}
						disableColumnFilter
						loading={loading}
					/>
				</div>
			</Root>
		)
	}

	render() {
		const { pledge } = this.props
		return (
			<div className={`pledging-container`}>
				{this.populateData(pledge.pledgeDrives.data)}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	pledge: state.pledgeReducer,
})

const mapDispatchToProps = {
	getPledgeAction,
	showDialog,
	closeDialog,
}

export default connect(mapStateToProps, mapDispatchToProps)(Pledging)
