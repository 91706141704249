import { useDispatch } from 'react-redux'
import { ROUTE } from '../../constants/route'
import { showAlert } from '../../actions'
import { getUserByUid } from '../../services/viplava'
import { useLocation, useNavigate } from 'react-router-dom'
import { ALERT } from '../../constants'
import { getQueryObj, serialize } from '../../utils'
import { useRef } from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import axios from 'axios'
import { format } from 'date-fns'
import { isProd } from '../../utils/network'

function useBookNow() {
	const eventRef = useRef()

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()

	const onLoginSuccess = async (res) => {
		const userData = (
			await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
				headers: {
					Authorization: `Bearer ${res?.access_token}`,
				},
			})
		)?.data
		localStorage.setItem(
			'USER_DETAILS',
			JSON.stringify({
				email: userData.email,
				name: userData.name,
				imageUrl: userData.imageUrl,
			})
		)
		dispatch(
			showAlert({
				message: 'Please wait. Redirecting you to appropriate page.',
				severity: ALERT.INFO,
				duration: 2000,
			})
		)
		getUserByUid({ email: userData.email })
			.then(({ result }) => {
				if (result.roles.some((role) => role >= 3)) {
					localStorage.setItem('isViplavaFacilitator', 'yes')
					navigate(
						`${ROUTE.EVENT_BRIDGE_PAGE.path}/${eventRef.current.uuid}`
					)
				} else {
					localStorage.setItem('isViplavaFacilitator', 'no')
					navigate(
						`${ROUTE.EVENT_REGISTER_PAGE.path}/${eventRef.current.uuid}?email=${userData.email}`
					)
					// setTimeout(function(){
					// 	document.location.href = `${ROUTE.EVENT_REGISTER.path}/${event.uuid}?email=${userData.email}`;
					// },250);
					window.location.href = `${ROUTE.EVENT_REGISTER_PAGE.path}/${eventRef.current.uuid}?email=${userData.email}`
				}
			})
			.catch(() => {
				localStorage.setItem('isViplavaFacilitator', 'no')
				// navigate(`${ROUTE.EVENT_REGISTER.path}/${event.uuid}?email=${userData.email}`)
				window.location.href = `${ROUTE.EVENT_REGISTER_PAGE.path}/${eventRef.current.uuid}?email=${userData.email}`
			})
	}

	// const { signIn } = useGoogleOneTapLogin({
	// 	clientId: GOOGLE_CLIENT_ID,
	// 	onSuccess: onLoginSuccess,
	// 	onFailure: (e) => {
	// 		alert(
	// 			"It seems cookies are not enabled in your browser. Please enable third party cookies and allow popups in browser settings. (One possible reason could be that you're trying to access website in incognito or please check your browser settings)"
	// 		)
	// 	},
	// })

	const signIn = useGoogleLogin({
		onSuccess: onLoginSuccess,
	})

	const handleRegisterBtnClick = (event, ...args) => {
		const data = getQueryObj(location?.search)
		eventRef.current = event
		const userProfile = localStorage.getItem('UserProfile')
		if (event.startDate && new Date() < new Date(event.startDate) && !window.origin.includes('localhost')) {
			alert(`Registration will start on ${format(new Date(event.startDate), 'dd/MM/yyyy')}`)
			return
		}
		if (
			userProfile &&
			JSON.parse(userProfile).roles.some((role) => role >= 3)
		) {
			localStorage.setItem('isViplavaFacilitator', 'yes')
			navigate(
				`${ROUTE.EVENT_BRIDGE_PAGE.path}/${event.uuid}${serialize({
					token: data.token,
				})}`
			)
		} else if (data.token) {
			// window.location.href = `${ROUTE.EVENT_REGISTER_PAGE.path}/${event.uuid}?token=${data.token}`
			localStorage.setItem('isViplavaFacilitator', 'no')
			navigate(
				`${ROUTE.EVENT_REGISTER_PAGE.path}/${event.uuid}?token=${data.token}`
			)
		} else {
			signIn(...args)
		}
	}

	return { handleRegisterBtnClick }
}

export default useBookNow
