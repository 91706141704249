import { Box, Button, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ROUTE } from '../../../constants/route'
import { useDispatch, useSelector } from 'react-redux'
import { getEventDetailsAction } from '../../../actions/event'
import { EVENTS_UI_CONFIG } from '../../../data/event'
import useCounter from '../../../hooks/useCounter'
import { format } from 'date-fns'
import { parseDate } from '../../../utils/date.util'

const EventCardTemplate = (props) => {
	const { title, dates, deadline, lastDate, headerImage } = props || {}
	return (
		<div class="flex items-center justify-center py-3">
			<div class="max-w-md md:max-w-2xl px-2">
				<div class="bg-white shadow-xl rounded-lg overflow-hidden md:flex">
					<div
						class="bg-cover bg-bottom h-56 md:h-auto md:w-56"
						style={{
							'background-image': `url(${headerImage})`,
						}}
					></div>
					<div>
						<div class="p-4 md:p-5">
							<p class="font-bold text-xl md:text-2xl">{title}</p>
							<p class="text-gray-700 md:text-lg">{dates}</p>
						</div>
						<div class="p-4 md:p-5 bg-gray-100">
							<div class="sm:flex sm:justify-between sm:items-center">
								<div>
									<div class="text-lg text-gray-700">
										<span class="text-gray-900 font-bold">
											{lastDate}
										</span>
									</div>
									<div class="flex items-center">
										<div class="flex inline-flex -mx-px"></div>
										<div class="text-gray-600 ml-2 text-sm md:text-base mt-1">
											<Countdown deadline={deadline} />
										</div>
									</div>
								</div>
								<button class="mt-3 sm:mt-0 py-2 px-5 md:py-3 md:px-6 bg-green-700 hover:bg-green-600 font-bold text-white md:text-lg rounded-lg shadow-md self-center">
									Book now
								</button>
							</div>
							<div class="mt-3 text-gray-600 text-sm md:text-base">
								Dive deep into devotion, blissful kirtans, and
								spiritual wisdom. Limited seats—register now!.
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const EventListing = () => {
	const dispatch = useDispatch()
	const events = useSelector((state) => state.eventReducer)?.events
	useEffect(() => {
		if (!events?.length) {
			dispatch(getEventDetailsAction())
		}
	}, [])
	return (
		<div className="event-listing-container">
			<Typography
				variant="h4"
				style={{
					marginBottom: '1rem',
				}}
			>
				Upcoming events
			</Typography>
			{events
				.filter((e) => e.status)
				.map((e) => {
					const eventUiConfig = EVENTS_UI_CONFIG.find(
						(config) => config.uuid === e.eventId
					)
					// const cardColor = `${Math.round(Math.random() * 255)},
					// 	${Math.round(Math.random() * 255)}, ${Math.round(Math.random() * 255)}`
					if (new Date(eventUiConfig.deadline).getTime() < Date.now())
						return null
					const lastDateText = `Last Date: ${format(
						parseDate(eventUiConfig.deadline),
						'MMMM do yyyy'
					)}`
					return (
						<Link
							to={
								eventUiConfig.shortLink ||
								`${ROUTE.EVENT_BRIDGE_PAGE.path}/${eventUiConfig.uuid}`
							}
						>
							<EventCardTemplate
								title={e.title}
								dates={eventUiConfig.dates}
								lastDate={lastDateText}
								deadline={eventUiConfig.deadline}
								headerImage={eventUiConfig.headerImage}
							/>
						</Link>
					)
				})}
		</div>
	)
}

export const Countdown = ({ deadline }) => {
	const counter = useCounter(deadline)

	return (
		<div className="event-list-counter">
			<div className="event-list-counter-box">
				<div className="event-list-counter-number">{counter.days}</div>
				<div className="event-list-counter-text">Day</div>
			</div>
			<div className="event-list-counter-box">
				<div className="event-list-counter-number">{counter.hours}</div>
				<div className="event-list-counter-text">Hour</div>
			</div>
			<div className="event-list-counter-box">
				<div className="event-list-counter-number">{counter.min}</div>
				<div className="event-list-counter-text">Min</div>
			</div>
			<div className="event-list-counter-box">
				<div className="event-list-counter-number">{counter.sec}</div>
				<div className="event-list-counter-text">Sec</div>
			</div>
		</div>
	)
}

export default EventListing
