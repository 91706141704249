import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material'
import React from 'react'
import { Autocomplete } from '@mui/lab'
import { getAreaDetails } from '../../../services/area'
import { searchUser } from '../../../services/user'
import { CLASS_LEVEL, PROFESSIONS } from '../../../constants'
import { EVENTS_UI_CONFIG } from '../../../data/event'
import { DatePicker } from '@mui/x-date-pickers'
import { getFormattedDate } from '../../../components/Calender/helpers'

export default class BasicInfo extends React.Component {
	constructor(props) {
		super(props)
		console.log(
			'userDetails',
			props.userDetails,
			props.userDetails.dob,
			new Date(props.userDetails.dob)
		)
		this.state = {
			name: props.userDetails.name || '',
			initiatedName: props.userDetails.initiatedName || '',
			email: props.userDetails.email || '',
			phone: props.userDetails.phone || '',
			education: { name: props.userDetails.education } || '',
			dob: props.userDetails.dob
				? new Date(props.userDetails.dob)
				: new Date(),
			connectedAreaId: props.userDetails.connectedAreaId,
			facilitator: props.userDetails.facilitator,
			chantingRounds: props.userDetails.chantingRounds || 0,
			userCategory: props.userDetails.userCategory || 'YOUTH',
			sadhnaGroup:
				props.userDetails.userCategory === 'YOUTH'
					? props.userDetails.sadhnaGroup || 1
					: 0,
			eventId: props.eventId,
		}
		this.disabled = !!props.userDetails.email
	}

	componentDidMount() {
		const { userDetails } = this.props
		if (userDetails?.connectedAreaId) {
			getAreaDetails({
				id: userDetails.connectedAreaId,
				isOpen: true,
			}).then((res) => {
				this.setState({
					connectedAreaId: res?.data?.data && res.data.data[0],
					facilitator:
						res?.data?.data &&
						res.data.data[0]?.facilitators.find(
							(f) => f._id === userDetails.facilitator
						),
				})
			})
		}
		this.onInputChange(null, '', 'connectedAreaId', 'search')
	}

	componentDidUpdate(prevProps) {
		if (this.props.userDetails.name !== prevProps.userDetails.name) {
			this.setState({
				name: this.props.userDetails.name,
				initiatedName: this.props.userDetails.initiatedName,
				email: this.props.userDetails.email,
				phone: this.props.userDetails.phone,
				connectedAreaId: this.props.userDetails.connectedAreaId,
				facilitator: this.props.userDetails.facilitator,
				chantingRounds: this.props.userDetails.chantingRounds,
				userCategory: this.props.userDetails.userCategory,
			})
		}
	}

	onInputChange = (event, value, type, reason, passedAreaId) => {
		const { mentor, facilitator, connectedAreaId } = this.state
		//response from api
		if (reason !== 'clear') {
			switch (type) {
				case 'connectedAreaId':
					if (mentor?._id || mentor) {
						getAreaDetails({
							facilitator: facilitator?._id || facilitator,
							per_page: 100,
						}).then((res) => {
							this.setState({
								[`options-${type}`]: res.data.data,
							})
						})
					} else {
						getAreaDetails({ per_page: 100, isOpen: true }).then(
							(res) => {
								this.setState({
									[`options-${type}`]: res.data.data,
								})
							}
						)
					}
					break
				case 'facilitator':
					if (
						passedAreaId ||
						connectedAreaId?._id ||
						connectedAreaId
					) {
						searchUser({
							q: value,
							userRole: '3,4',
							areaId:
								passedAreaId ||
								connectedAreaId?._id ||
								connectedAreaId,
							per_page: 100,
						}).then((res) => {
							this.setState({
								[`options-${type}`]: res.data.data,
							})
						})
					}
					break
				case 'mentor':
					searchUser({
						q: value,
						userRole: 3,
						per_page: 100,
						isOpen: true,
					}).then((res) => {
						this.setState({
							[`options-${type}`]: res.data.data,
						})
					})
					break
				default:
					getAreaDetails({ per_page: 100 }).then((res) => {
						this.setState({
							[`options-${type}`]: res.data.data,
						})
					})
			}
		} else {
			const resetObj = {
				'options-mentor': [],
				'options-facilitator': [],
			}
			if (type === 'connectedAreaId') {
				this.setState({
					...resetObj,
					facilitator: '',
				})
			} else if (type === 'facilitator') {
				this.setState({
					...resetObj,
					connectedAreaId: '',
				})
			} else {
				this.setState(resetObj)
			}
		}
	}

	onChange = (name, value) => {
		const { handleChange } = this.props
		if (name === 'connectedAreaId') {
			this.onInputChange(null, '', 'facilitator', 'search', value?._id)
		}
		this.setState({
			[name]: value,
		})
		handleChange({
			target: { name, value },
		})
	}

	render() {
		const {
			name,
			initiatedName,
			email,
			phone,
			connectedAreaId,
			education,
			dob,
			facilitator,
			chantingRounds,
			userCategory,
			sadhnaGroup,
			eventId,
		} = this.state
		const { errors, touched, handleBlur, userDetails } = this.props
		const eventUiConfig = EVENTS_UI_CONFIG.find(
			(config) => config.uuid === eventId
		)
		return (
			<>
				{eventUiConfig.basicInputs.find(
					(input) => input.id === 'name'
				) && (
					<TextField
						label="Name"
						variant="outlined"
						defaultValue={name}
						TabPa
						value={name}
						name="name"
						onChange={(e) =>
							this.onChange(e.target.name, e.target.value)
						}
						onBlur={(e) => handleBlur(e)}
						error={touched.name && errors.name}
						helperText={
							touched.name &&
							errors.name && <span>{errors.name}</span>
						}
						fullWidth
					/>
				)}
				{eventUiConfig.basicInputs.find(
					(input) => input.id === 'initiatedName'
				) && (
					<TextField
						label="Initiated Name"
						variant="outlined"
						defaultValue={initiatedName}
						TabPa
						value={initiatedName}
						name="initiatedName"
						onChange={(e) =>
							this.onChange(e.target.name, e.target.value)
						}
						onBlur={(e) => handleBlur(e)}
						error={touched.initiatedName && errors.initiatedName}
						helperText={
							touched.initiatedName &&
							errors.initiatedName && (
								<span>{errors.initiatedName}</span>
							)
						}
						fullWidth
					/>
				)}
				{eventUiConfig.basicInputs.find(
					(input) => input.id === 'email'
				) && (
					<TextField
						label="Email"
						variant="outlined"
						defaultValue={email}
						value={email}
						name="email"
						disabled={this.disabled}
						onChange={(e) =>
							this.onChange(e.target.name, e.target.value)
						}
						onBlur={(e) => handleBlur(e)}
						error={touched.email && errors.email}
						helperText={
							touched.email &&
							errors.email && <span>{errors.email}</span>
						}
						fullWidth
					/>
				)}
				{eventUiConfig.basicInputs.find(
					(input) => input.id === 'phone'
				) && (
					<TextField
						label="Phone"
						variant="outlined"
						defaultValue={phone}
						value={phone}
						name="phone"
						onChange={(e) =>
							this.onChange(e.target.name, e.target.value)
						}
						onBlur={(e) => handleBlur(e)}
						error={touched.phone && errors.phone}
						helperText={
							touched.phone &&
							errors.phone && <span>{errors.phone}</span>
						}
						type="number"
						fullWidth
					/>
				)}
				{eventUiConfig.basicInputs.find(
					(input) => input.id === 'dob'
				) && (
					<DatePicker
						label="DOB"
						format="dd/MM/yyyy"
						value={dob}
						onChange={(e) => {
							this.onChange('dob', e)
						}}
						inputVariant="outlined"
					/>
				)}
				{eventUiConfig.basicInputs.find(
					(input) => input.id === 'education'
				) && (
					<Autocomplete
						options={PROFESSIONS.map((val) => ({
							name: val,
							value: val,
						}))}
						getOptionLabel={(option) => option.name || ''}
						isOptionEqualToValue={(option, value) =>
							option?._id === value?._id
						}
						defaultValue={education}
						value={education}
						onChange={(e, newValue) => {
							this.onChange('education', newValue)
						}}
						onBlur={(e, newValue) => {
							handleBlur({
								target: {
									name: 'education',
									value: newValue,
								},
							})
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								label="Education"
								placeholder="Start typing your education"
								error={touched.education && errors.education}
								helperText={
									touched.education &&
									errors.education && (
										<span>{errors.education}</span>
									)
								}
							/>
						)}
					/>
				)}
				{eventUiConfig.basicInputs.find(
					(input) => input.id === 'connectedAreaId'
				) && (
					<Autocomplete
						options={this.state[`options-connectedAreaId`] || []}
						getOptionLabel={(option) => option.name || ''}
						defaultValue={connectedAreaId}
						filterSelectedOptions
						onInputChange={(e, val, reason) =>
							this.onInputChange(
								e,
								val,
								'connectedAreaId',
								reason
							)
						}
						isOptionEqualToValue={(option, value) =>
							option?._id === value?._id
						}
						value={connectedAreaId}
						onChange={(e, newValue) => {
							this.onChange('connectedAreaId', newValue)
						}}
						onBlur={(e, newValue) => {
							handleBlur({
								target: {
									name: 'connectedAreaId',
									value: newValue,
								},
							})
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								label="Connected Area"
								placeholder="Start typing Area Name"
								error={
									touched.connectedAreaId &&
									errors.connectedAreaId
								}
								helperText={
									touched.connectedAreaId &&
									errors.connectedAreaId && (
										<span>{errors.connectedAreaId}</span>
									)
								}
							/>
						)}
					/>
				)}
				{eventUiConfig.basicInputs.find(
					(input) => input.id === 'facilitator'
				) && (
					<Autocomplete
						options={this.state[`options-facilitator`] || []}
						getOptionLabel={(option) =>
							option.initiatedName || option.name
								? `${option.initiatedName || option.name}` +
								  (option.phone ? ` (${option.phone})` : '')
								: option.initiatedName || option.name
						}
						defaultValue={facilitator}
						filterSelectedOptions
						onInputChange={(e, val, reason) =>
							this.onInputChange(e, val, 'facilitator', reason)
						}
						isOptionEqualToValue={(option, value) =>
							option?._id === value?._id
						}
						value={facilitator}
						onChange={(e, newValue) => {
							this.onChange('facilitator', newValue)
						}}
						onBlur={(e, newValue) => {
							handleBlur({
								target: {
									name: 'facilitator',
									value: newValue,
								},
							})
						}}
						disabled={!connectedAreaId}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								label="Facilitator"
								placeholder="Start typing name"
								error={
									touched.facilitator && errors.facilitator
								}
								helperText={
									touched.facilitator &&
									errors.facilitator && (
										<span>{errors.facilitator}</span>
									)
								}
							/>
						)}
					/>
				)}
				{eventUiConfig.basicInputs.find(
					(input) => input.id === 'chantingRounds'
				) &&
					!!eventUiConfig.eligibility?.minimumChantingRounds && (
						<span>
							Please note that your chanting rounds must be
							atleast{' '}
							{eventUiConfig?.eligibility?.minimumChantingRounds}{' '}
							rounds/day to register in {eventUiConfig.title}
						</span>
					)}
				{eventUiConfig.basicInputs.find(
					(input) => input.id === 'chantingRounds'
				) && (
					<TextField
						label={`Chanting Rounds ${
							eventUiConfig?.eligibility?.minimumChantingRounds
								? '(Eligibility: altleast ' +
								  eventUiConfig?.eligibility
										?.minimumChantingRounds +
								  ' rounds daily)'
								: ''
						}`}
						variant="outlined"
						defaultValue={chantingRounds}
						TabPa
						value={chantingRounds}
						name="chantingRounds"
						onChange={(e) =>
							this.onChange(e.target.name, e.target.value)
						}
						onBlur={(e) => handleBlur(e)}
						type="number"
						error={touched.chantingRounds && errors.chantingRounds}
						helperText={
							touched.chantingRounds &&
							errors.chantingRounds && (
								<span>{errors.chantingRounds}</span>
							)
						}
						fullWidth
					/>
				)}
				{eventUiConfig.basicInputs.find(
					(input) => input.id === 'userCategory'
				) && (
					<FormControl variant="outlined" fullWidth>
						<InputLabel id="user-type-select-label">
							User Type
						</InputLabel>
						<Select
							labelId="user-type-select-label"
							id="user-type-select"
							value={userCategory}
							label="User Type"
							onChange={(e) => {
								this.onChange('userCategory', e.target.value)
								if (e.target.value !== 'YOUTH') {
									this.onChange('sadhnaGroup', 0)
								} else {
									this.onChange('sadhnaGroup', 1)
								}
							}}
						>
							<MenuItem value={'YOUTH'}>YOUTH</MenuItem>
							<MenuItem value={'BRAHMACHARI'}>
								BRAHMACHARI
							</MenuItem>
							{/* <MenuItem value={'PARENTS'}>PARENTS</MenuItem> */}
						</Select>
					</FormControl>
				)}
				{(eventUiConfig.basicInputs.find(
					(input) => input.id === 'userCategory'
				) &&
					userCategory === 'YOUTH') ||
					(eventUiConfig.basicInputs.find(
						(input) => input.id === 'sadhnaGroup'
					) && (
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="demo-simple-select-outlined-label">
								Class Level
							</InputLabel>
							<Select
								value={sadhnaGroup}
								onChange={(e) =>
									this.onChange(e.target.name, e.target.value)
								}
								label="Class Level"
								name="sadhnaGroup"
							>
								{Object.keys(CLASS_LEVEL).map((key) => (
									<MenuItem value={key}>
										{CLASS_LEVEL[key]}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					))}
				{false && (
					<FormControl variant="outlined" fullWidth>
						<InputLabel id="demo-simple-select-outlined-label">
							Camp
						</InputLabel>
						<Select
							value={eventId}
							onChange={(e) =>
								this.onChange(e.target.name, e.target.value)
							}
							label="Camp"
							name="eventId"
							error={touched.name && errors.name}
							helperText={
								touched.name &&
								errors.name && <span>{errors.name}</span>
							}
						>
							{EVENTS_UI_CONFIG.map((key) => (
								<MenuItem value={key.uuid}>
									{key.title}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)}
				<Button
					variant="contained"
					color="primary"
					onClick={() => {
						console.log(this.state.education?.name)
						this.props.onSubmit({
							name,
							initiatedName,
							email,
							phone,
							dob: getFormattedDate(this.state.dob),
							education: this.state.education,
							connectedAreaId:
								connectedAreaId?._id || connectedAreaId,
							facilitator: facilitator?._id || facilitator,
							chantingRounds,
							userRole: userDetails.userRole || 0,
							userCategory: userDetails.userCategory,
							sadhnaGroup: userDetails.sadhnaGroup,
							eventId,
						})
					}}
				>
					NEXT
				</Button>
			</>
		)
	}
}
