export const actionCreator = (type, payload, ...rest) => {
    if (type.toUpperCase().includes('FAILED')) {
        return ({
            type,
            error: payload,
            ...rest
        })
    }
    return ({
        type,
        payload,
        ...rest[0],
    })
}