/* eslint-disable no-undef */
import {
	Button,
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	CardMedia,
	Modal,
	Table,
	TableBody,
	TableCell,
	TableRow,
	TextField,
	Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useState } from 'react'
import CareIcon from '../../../assets/icons/CareIcon'
import { EVENTS_UI_CONFIG } from '../../../data/event'
import { raiseSubsidyRequest, updateBooking } from '../../../services/viplava'
import {
	ACCOMODATION_CATEGORY,
	ALERT,
	EVENT_BOOKING_STATUS,
	PAYMENT_METHOD_ONLINE,
} from '../../../constants'
import {
	AccountBalance,
	AccountBalanceWallet,
	Money,
	MoneyOffOutlined,
} from '@mui/icons-material'
import usePayment from '../usePayment'
import store from '../../../store'
import { showAlert } from '../../../actions'

const PREFIX = 'PaymentSection'

const classes = {
	root: `${PREFIX}-root`,
	media: `${PREFIX}-media`,
	modalRoot: `${PREFIX}-modalRoot`,
	modal: `${PREFIX}-modal`,
	paper: `${PREFIX}-paper`,
	reasonInput: `${PREFIX}-reasonInput`,
	tableRow: `${PREFIX}-tableRow`,
}

const Root = styled('div')({
	[`& .${classes.root}`]: {
		maxWidth: 345,
	},
	[`& .${classes.media}`]: {
		height: 140,
	},

	[`& .${classes.modalRoot}`]: {
		// height: 300,
		position: 'absolute',
		flexGrow: 1,
		minWidth: 300,
		transform: 'translateZ(0)',
		// The position fixed scoping doesn't work in IE 11.
		// Disable this demo to preserve the others.
		'@media all and (-ms-high-contrast: none)': {
			display: 'none',
		},
	},
	[`& .${classes.modal}`]: {
		display: 'flex',
		// padding: theme.spacing(1),
		alignItems: 'center',
		justifyContent: 'center',
	},
	[`& .${classes.paper}`]: {
		width: 400,
		backgroundColor: 'white',
		border: '2px solid #000',
		borderRadius: '8px',
		// boxShadow: theme.shadows[5],
		padding: '40px',
		lineHeight: '1.44',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	[`& .${classes.reasonInput}`]: {
		height: '88px',
		overflow: 'hidden',
		width: '100%',
		marginTop: '16px',
	},
	[`& .${classes.tableRow}`]: {
		'& > *': {
			padding: '4px', // Adjust this value to reduce spacing for TableRow and its children
		},
	},
})

const SUBSIDY_TYPE = {
	CASH: 'CASH',
	ONLINE: 'ONLINE',
}

const PaymentSection = ({
	userDetails,
	booking,
	onPaymentSuccess,
	onPaymentFailure,
	onRaiseSubsidyRequest,
	eventId,
	isTransportSelected,
}) => {
	const eventUiConfig = EVENTS_UI_CONFIG.find(
		(event) => event.uuid === eventId
	)
	const [verified, setVerified] = useState(false)
	const [subsidyModal, setSubsidyModal] = useState(false)
	const [subsidy, setSubsidy] = useState({
		amountAfterSubsidy: 0,
		subsidyReason: '',
	})

	const { initiatePayment } = usePayment({
		booking,
		userDetails,
		onPaymentFailure,
		onPaymentSuccess,
		eventUiConfig,
	})

	const handleSubsidyInput = (e) => {
		setSubsidy((subsidy) => ({
			...subsidy,
			[e.target.name]: e.target.value,
		}))
	}

	const sendSubsidyRequest = async (type) => {
		if (
			booking.accomodationCategory !== ACCOMODATION_CATEGORY.DORMATORY.id
		) {
			await updateBooking({
				...booking,
				eventId,
				userId: userDetails._id,
				bookingId: booking.bookingId,
				accomodationCategory: ACCOMODATION_CATEGORY.DORMATORY.id, // Dormatory
				tokenAmount:
					booking.tokenAmount - ACCOMODATION_CATEGORY.ROOM.amount,
				donationAmount: 0,
				transportationAmount: booking.transportationAmount,
			})
		}

		raiseSubsidyRequest({
			userId: userDetails._id,
			bookingId: booking.bookingId,
			subsidyAmount:
				+(booking.finalAmount - subsidy.amountAfterSubsidy) ?? 0,
			subsidyReason: subsidy.subsidyReason,
			amountAfterSubsidy: subsidy.amountAfterSubsidy,
		}).then((res) => {
			if (type === SUBSIDY_TYPE.CASH) {
				onRaiseSubsidyRequest(res.result)
			}
			if (type === SUBSIDY_TYPE.ONLINE) {
				initiatePayment(PAYMENT_METHOD_ONLINE.AXIS_BANK)
			}
		})
	}

	const payThroughCash = async () => {
		if (
			Date.now() >
			new Date(eventUiConfig.deadline).getTime() + 86400000
		) {
			// On deadline day at 12pm midnight
			store.dispatch(
				showAlert({
					message: 'Registration are closed for this event.',
					severity: ALERT.ERROR,
					duration: 5000,
				})
			)
			return
		}
		await updateBooking({
			...booking,
			eventId,
			userId: userDetails._id,
			bookingId: booking.bookingId,
			accomodationCategory: booking.accomodationCategory,
			transportationAmount:
				booking.transportationAmount || isTransportSelected,
			paymentMode: 'CASH',
			tokenAmount: booking.tokenAmount,
		}).then((res) => {
			onRaiseSubsidyRequest(res.result)
		})
	}

	const renderRow = (row, i) => {
		if (row.render) {
			const RenderComponent = row.render
			return <RenderComponent />
		}
		return (
			<TableRow className={classes.tableRow} key={i}>
				<TableCell>
					<Typography
						gutterBottom
						variant="body1"
						color="textPrimary"
						component="p"
					>
						<b>{row.label}</b>
					</Typography>
				</TableCell>
				<TableCell>{row.value}</TableCell>
			</TableRow>
		)
	}

	const renderBookingBreakup = (booking) => () => {
		return (
			<TableRow className={classes.tableRow}>
				<TableCell>
					<Typography
						gutterBottom
						variant="body1"
						color="textPrimary"
						component="p"
					>
						<b>Amount Breakup</b>
					</Typography>
				</TableCell>
				<TableCell>
					<Typography gutterBottom variant="body" component="p">
						{booking.registrationAmount &&
							`Registration (₹${booking.registrationAmount})`}
						{!!booking.donationAmount &&
							` + Donation (₹${booking.donationAmount})`}
						{!!booking.transportationAmount &&
							` + Transportation (₹${booking.transportationAmount})`}
						{!!booking.accomodationAmount &&
							` + Accomodation (₹${booking.accomodationAmount})`}
						{!!booking.outfitAmount &&
							` + Dhoti Kurta X ${booking.outfitCount} (₹${booking.outfitAmount})`}
						{!!booking.subsidy &&
							` - Discount (₹${booking.subsidy})`}
					</Typography>
				</TableCell>
			</TableRow>
		)
	}

	return (
		<Root className="payment-section">
			<div className={classes.modalRoot}>
				<Modal
					open={subsidyModal}
					onClose={() => setSubsidyModal(false)}
					className={classes.modal}
				>
					<div className={classes.paper}>
						<CareIcon />
						<Typography
							variant="h4"
							style={{
								marginBottom: '20px',
								textAlign: 'center',
							}}
						>
							<b>Subsidy Request</b>
							<br />
							How much can you pay out of Rs {booking.finalAmount}
							?
							{/* If you feel you cannot pay complete amount, kindly
							fill this form. The details of this form would be
							shared with your Area leader and Facilitator. */}
						</Typography>
						<form class="text-center">
							<TextField
								label="Amount you can afford to pay"
								variant="outlined"
								defaultValue={subsidy.amountAfterSubsidy}
								value={subsidy.amountAfterSubsidy}
								name="amountAfterSubsidy"
								onChange={handleSubsidyInput}
								fullWidth
								type="number"
							/>
							<TextField
								maxRows={4}
								placeholder="Reason"
								variant="outlined"
								value={subsidy.subsidyReason}
								name="subsidyReason"
								onChange={handleSubsidyInput}
								minRows={4}
								className={classes.reasonInput}
								fullWidth
							/>
							<Button
								variant="contained"
								color="primary"
								onClick={() =>
									sendSubsidyRequest(SUBSIDY_TYPE.ONLINE)
								}
								style={{ marginTop: '20px' }}
							>
								Request Subsidy & Pay Online
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={() =>
									sendSubsidyRequest(SUBSIDY_TYPE.CASH)
								}
								style={{ marginTop: '20px' }}
							>
								Request Subsidy & Pay Cash
							</Button>
						</form>
					</div>
				</Modal>
			</div>
			<Card className={classes.root}>
				<CardActionArea>
					<CardMedia
						className={classes.media}
						image={eventUiConfig.paymentHeaderImage}
						title={eventUiConfig.title}
					/>
					<CardContent>
						<center>
							<Typography
								gutterBottom
								variant="h5"
								component="h2"
							>
								Please verify your details before doing final
								payment.
							</Typography>
							<hr />
							<Typography
								gutterBottom
								variant="h6"
								component="h2"
							>
								{eventUiConfig.title}
							</Typography>
						</center>
						<hr />
						<div></div>
						<Table>
							<TableBody>
								{[
									{
										label: 'Name: ',
										value:
											userDetails.initiatedName ||
											userDetails.name,
									},
									{
										label: 'Contact: ',
										value: userDetails.phone,
									},
									{
										label: 'Email: ',
										value: userDetails.email,
									},
									{
										label: 'Chanting rounds: ',
										value: userDetails.chantingRounds,
									},
									{
										label: 'Connected Area: ',
										value: booking.connectedArea,
									},
									{
										label: 'Accomodation Type: ',
										value: booking.accomodationCategoryName,
									},
									...(eventUiConfig?.additionalInputs?.filter(
										(el) => el.id === 'transportation'
									).length > 0
										? [
												{
													label: 'Transportation: ',
													value:
														booking.transportationAmount ||
														isTransportSelected
															? 'Yes'
															: 'No',
												},
										  ]
										: []),
									{
										label: 'Total Amount: ',
										value: `₹${booking.finalAmount}
										${eventUiConfig.allowOnlinePayment && !booking.tokenAmount ? '*' : ''}`,
									},
									...(booking.tokenAmount
										? [
												{
													label: 'Your booking confirmation amount: ',
													value: `₹${
														booking.tokenAmount
													} ${
														eventUiConfig.allowOnlinePayment
															? '*'
															: ''
													}`,
												},
												{
													label: 'Remaining amount: ',
													value: `₹ ${
														booking.finalAmount -
														booking.tokenAmount
													}`,
												},
										  ]
										: []),
									...(eventUiConfig.showAmountBreakup
										? [
												{
													render: renderBookingBreakup(
														booking
													),
												},
										  ]
										: []),
									...(booking.cf1_value
										? [
												{
													label: 'Number of BSS camps attended ?',
													value: booking.cf1_value,
												},
										  ]
										: []),
									...(booking.cf2_value
										? [
												{
													label: 'Expected year of joining the PBT batch ?',
													value: booking.cf2_value,
												},
										  ]
										: []),
								].map(renderRow)}
							</TableBody>
						</Table>
						<br />
						{!!booking.subsidyReason && (
							<div>
								<Typography
									gutterBottom
									variant="body1"
									color="green"
									component="p"
									style={{ color: 'rgb(104 159 104)' }}
								>
									<b>
										{'->'} {booking.subsidyReason}
									</b>
								</Typography>
							</div>
						)}
						<br />
						{eventUiConfig.allowOnlinePayment && (
							<Typography
								gutterBottom
								variant="body"
								component="p"
							>
								*Transaction Charges excluded, if done through
								online mode
							</Typography>
						)}
					</CardContent>
				</CardActionArea>
				<CardActions>
					<div className="action-btns">
						{eventUiConfig.allowOnlinePayment &&
							(verified ? (
								<>
									{eventUiConfig.allowAxisBank && (
										<button
											className="beautiful-button"
											onClick={() =>
												initiatePayment(
													PAYMENT_METHOD_ONLINE.AXIS_BANK
												)
											}
										>
											<AccountBalance className="mr-2" />
											Pay online
											{/* via Axis Bank (0₹ Fee) */}
										</button>
									)}
									{eventUiConfig.allowRazorpayBank && (
										<button
											className="beautiful-button-2 mt-4"
											id="rzp-button1"
											onClick={() =>
												initiatePayment(
													PAYMENT_METHOD_ONLINE.RAZOR_PAY
												)
											}
										>
											<AccountBalanceWallet className="mr-2" />
											Pay online via Razorpay(2% Fee
											Applicable)
										</button>
									)}
								</>
							) : (
								<button
									className="primary-btn"
									onClick={() => setVerified(true)}
								>
									Verify & Pay
								</button>
							))}
						<br />
						{eventUiConfig.allowSubsidy &&
							!(
								booking.status === 'SUBSIDY_APPROVED' ||
								booking.status === 'SUBSIDY_REQUESTED' ||
								booking.status === 'SUBSIDY_FAILED'
							) &&
							verified && (
								<Button
									color="white"
									variant="contained"
									onClick={() => {
										setSubsidyModal(true)
									}}
								>
									<MoneyOffOutlined />
									Unable to Pay
								</Button>
							)}
						<br />
						{eventUiConfig.allowCash &&
							verified &&
							booking.status === EVENT_BOOKING_STATUS[1] && (
								<Button
									color="secondary"
									variant="contained"
									onClick={payThroughCash}
								>
									<Money className="mr-2" />
									Pay through Cash mode
								</Button>
							)}
					</div>
				</CardActions>
				<br />
			</Card>
		</Root>
	)
}

export default PaymentSection
