import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Card,
	CardContent,
	CardHeader,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Modal,
	Select,
	TextField,
	Typography,
} from '@mui/material'
import { AddCircle, Delete } from '@mui/icons-material'
import AddFrontlinerTut from '../../assets/images/adding-frontliner.gif'
import React from 'react'
import { getAreaDetails, updateAreaDetails } from '../../services/area'
import { getUsers, searchUser } from '../../services/user'
import { removeItemOnce } from '../../utils'
import FrontlinersComponent from './Frontliners'
import './style.scss'

const queryStringToObj = (queryString) => {
	const ignoredAmp = queryString.split('?')[1]
	const terms = ignoredAmp.split('&')
	const output = {}
	terms.forEach((term) => {
		const [key, value] = term.split('=')
		output[key] = value
	})
	return output
}

class UpdateFrontliners extends React.Component {
	constructor(props) {
		super(props)
		const { areaId } = queryStringToObj(props.location.search)
		this.areaId = areaId
		this.state = {
			facilitators: [],
			facilitatorId: null,
			frontliners: [],
			members: [],
			options: [],
		}
	}

	componentDidMount() {
		getAreaDetails({ id: this.areaId }).then((res) => {
			const frontliners = res?.data?.data[0].frontliners
			if (res?.data?.data[0].frontliners) {
				this.setState(() => ({
					frontliners,
				}))
			}
		})
		getUsers({ connectedAreaId: this.areaId, per_page: 1000 }).then(
			(response) => {
				this.setState(() => ({
					members: response?.data?.data,
				}))
			}
		)
	}

	onInputChange = (event, value) => {
		//response from api
		if (event) {
			searchUser({
				q: value,
				userRole: '1',
				areaId: this.areaId,
				facilitator: this.state.facilitatorId,
			}).then((res) => {
				this.setState({
					options: res.data.data,
				})
			})
		}
	}

	onChange = (name, value) => {
		this.setState({
			[name]: value,
		})
	}

	manipulateFrontliner = (user) => {
		const frontliners = this.state.frontliners.map((front) => ({
			...front,
			frontlinerId: front.frontlinerId.map((f) => {
				if (typeof f === 'object') {
					return f._id
				}
				return f
			}),
		}))
		const index = frontliners.findIndex((f) => {
			return f.facilitatorId === this.state.facilitatorId
		})
		if (index !== -1) {
			frontliners[index].frontlinerId.push(user._id)
			frontliners[index].frontlinerId = frontliners[
				index
			].frontlinerId.map((f) => {
				if (typeof f === 'object') {
					return f._id
				}
				return f
			})
		} else {
			frontliners.push({
				facilitatorId: this.state.facilitatorId,
				frontlinerId: [user._id],
			})
		}
		updateAreaDetails({ areaId: this.areaId, frontliners })
			.then((res) => {
				this.setState({
					addFrontliner: '',
					addingFrontliner: false,
					frontliners: res.data.frontliners,
					disableBtn: false,
				})
			})
			.catch((err) => {
				this.setState({
					disableBtn: true,
				})
			})
	}

	deleteFrontliner = (userId) => {
		const frontliners = this.state.frontliners
			.filter((f) => f._id !== userId)
			.map((f) => f._id)
		// const frontliners = this.state.frontliners.map(front => ({...front, frontlinerId: front.frontlinerId.map((f) => {if(typeof f === "object") {return f._id} return f;})}));
		// const index = frontliners.findIndex(f => {
		//     return f.facilitatorId === this.state.facilitatorId
		// });
		// frontliners[index].frontlinerId could be a string (id) or an obj {_id: id}
		// const updatedArr = removeItemOnce(frontliners[index].frontlinerId.map((f) => {if(typeof f === "object") {return f._id} return f;}), userId);
		// frontliners[index].frontlinerId = updatedArr;
		updateAreaDetails({ areaId: this.areaId, frontliners })
			.then((res) => {
				this.setState({
					frontliners: res.data.frontliners,
					disableBtn: false,
				})
			})
			.catch((err) => {
				this.setState({
					disableBtn: true,
				})
			})
	}

	render() {
		const { members, frontliners } = this.state
		return (
            (<div className="update-front-container">
                <Card>
					<CardContent>
						{frontliners?.length ? (
							<Typography variant="h4">
								Assign devotees under Frontliner
							</Typography>
						) : (
							<div>
								<Typography variant="h4">
									You haven't assigned any frontliner!
								</Typography>
								<Typography variant="h5">
									See how to add a frontliner
								</Typography>
								<div className="tutorial">
									<img
										alt="Go to Edit Area details to add Frontliner"
										src={AddFrontlinerTut}
									/>
								</div>
							</div>
						)}
					</CardContent>
				</Card>
                <div className="assigned">
					{frontliners.map((front) => {
						return (
                            (<Accordion expanded={true} onChange={() => {}}>
                                <AccordionSummary
									aria-controls="panel1d-content"
									id="panel1d-header"
								>
									<div
										style={{
											width: '100%',
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<Typography
											style={{ flex: '1' }}
											variant="h6"
										>
											{front?.initiatedName ||
												front?.name}
										</Typography>
										<IconButton
                                            onClick={() => {
												this.setState({
													disableBtn: true,
												})
												this.deleteFrontliner(front._id)
											}}
                                            aria-label="Delete Frontliner"
                                            color="primary"
                                            title="Delete Frontliner"
                                            disabled={this.state.disableBtn}
                                            size="large">
											<Delete />
										</IconButton>
									</div>
								</AccordionSummary>
                                <AccordionDetails>
									<FrontlinersComponent
										members={members.filter((m) => {
											return (
												m?.frontliner?._id === front._id
											)
										})}
										areaId={this.areaId}
										frontlinerId={front._id}
									/>
								</AccordionDetails>
                            </Accordion>)
                        );
					})}
				</div>
            </div>)
        );
	}
}

export default UpdateFrontliners
