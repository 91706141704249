import { createStore, combineReducers, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'

import reducers from './reducers'
import { thunk } from 'redux-thunk'

const middleware = [thunk]
middleware.push(createLogger())

// Grab the state from a global variable
const initialState = {}

const store = createStore(
	combineReducers(reducers),
	initialState,
	applyMiddleware(...middleware)
)

export const createNewStore = (initialState) => {
	const store = createStore(
		combineReducers(reducers),
		initialState,
		applyMiddleware(...middleware)
	)
	return store
}

export default store
