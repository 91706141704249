import { API_URL, METHOD } from '../constants/network'
import { serialize } from '../utils'
import { fetchAPI } from '../utils/network'

export const getAreaDetails = (params) => {
	const { isOpen, ...restParams } = params || {}
	const url = `${API_URL.AREA}${serialize(restParams)}`
	return fetchAPI({
		url,
		isOpenApi: isOpen || undefined,
	})
}

export const updateAreaDetails = (areaDetails) => {
	const { areaId, ...updatedData } = areaDetails
	const url = `${API_URL.AREA}/${areaId}`
	return fetchAPI({
		url,
		method: METHOD.PUT,
		body: updatedData,
	})
}

export const deactivateArea = (areaId) => {
	const url = `${API_URL.AREA}/${areaId}`
	return fetchAPI({
		url,
		method: METHOD.PUT,
		body: { status: 0 },
	})
}

export const createAreaDetails = (areaDetails) => {
	const url = `${API_URL.AREA}`
	return fetchAPI({
		url,
		method: METHOD.POST,
		body: areaDetails,
	})
}
