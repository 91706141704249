import { utils, writeFile } from 'xlsx'
import { uploadFile } from '../services/misc'
import { ACCOMODATION_CATEGORY } from '../constants'
import store from '../store'

export const getToken = () => localStorage.getItem('Token')

export const setToken = (token) => localStorage.setItem('Token', token)

export const isUserloggedIn = () => {
	let userInfo = JSON.parse(localStorage.getItem('UserProfile')) || {}
	return !!(userInfo.token && userInfo.loggedIn)
}

export const serialize = (param) => {
	const str = []
	for (let p in param)
		if (param.hasOwnProperty(p) && !!param[p]) {
			str.push(encodeURIComponent(p) + '=' + encodeURIComponent(param[p]))
		}
	const serialized = str.join('&')
	return serialized ? `?${serialized}` : serialized
}

export const uploadBlobFile = (blob, filename = new Date().getTime()) => {
	var fd = new FormData()
	fd.append('file', blob, filename + '.wav')
	return uploadFile(fd)
}

export const getCurrentUser = () => {
	const userProfile = localStorage.getItem('UserProfile')
	if (userProfile) {
		try {
			return JSON.parse(userProfile)
		} catch {
			return {}
		}
	}
	return {}
}

export const extractYoutubeID = (url) => {
	url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
	return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0]
}

export const isMobileScreen = () => {
	return window.innerWidth <= 768
}

export const isAreaLeader = (userProfile) => {
	const user = userProfile || JSON.parse(localStorage.getItem('UserProfile'))
	if (user?.roles?.includes(4)) {
		return true
	}
	return false
}

export const isAsstDirector = (userProfile) => {
	const user = userProfile || JSON.parse(localStorage.getItem('UserProfile'))
	if (user?.roles?.includes(5)) {
		return true
	}
	return false
}

export const isGenSecretary = (userProfile) => {
	const user = userProfile || JSON.parse(localStorage.getItem('UserProfile'))
	if (user?.roles?.includes(6)) {
		return true
	}
	return false
}

export const isDirector = (userProfile) => {
	const user = userProfile || JSON.parse(localStorage.getItem('UserProfile'))
	if (user?.roles?.includes(7)) {
		return true
	}
	return false
}

export const isFacilitator = (userProfile) => {
	const user = userProfile || JSON.parse(localStorage.getItem('UserProfile'))
	if (user?.roles?.includes(3)) {
		return true
	}
	return false
}

export const isFrontliner = (userProfile) => {
	const user = userProfile || JSON.parse(localStorage.getItem('UserProfile'))
	if (user?.roles?.includes(2)) {
		return true
	}
	return false
}

export const isApprovedMember = (userProfile) => {
	const user = userProfile || JSON.parse(localStorage.getItem('UserProfile'))
	if (user?.roles?.includes(1)) {
		return true
	}
	return false
}

export const removeItemOnce = (arr, value) => {
	var index = arr.indexOf(value)
	if (index > -1) {
		arr.splice(index, 1)
	}
	return arr
}

export const getQueryObj = (queryString) => {
	const queries = queryString?.split('?')?.[1]?.split('&') || []
	const data = {}
	queries.forEach((query) => {
		const [key, value] = query.split('=')
		data[key] = value
	})
	return data
}

export const getReferrer = () => {
	return getQueryObj(window.location.href)?.referrer ?? ''
}

export const getAreas = () => {
	if (localStorage.getItem('AREAS')) {
		return JSON.parse(localStorage.getItem('AREAS'))
	}
	return []
}

function camelCaseToNormalString(camelCase) {
	// Use a regular expression to insert a space before all capital letters
	const stringWithSpaces = camelCase.replace(/([A-Z])/g, ' $1')

	// Capitalize the first letter and remove leading spaces
	return (
		stringWithSpaces.charAt(0).toUpperCase() +
		stringWithSpaces.slice(1).trim()
	)
}

function modifyKeys(obj) {
	const modifiedObj = {}
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			// Modify the key as needed
			const newKey = camelCaseToNormalString(key)
				?.replace('P B T', 'PBT')
				.replace('B S S', 'BSS')
			modifiedObj[newKey] = obj[key]
		}
	}
	return modifiedObj
}

export const exportFromJSON = ({ data, fileName }) => {
	// Make Workbook of excel
	const wb = utils.book_new()

	const modifiedData = data.map((el) => modifyKeys(el))

	// Export json to Worksheet of Excel ( only array possible )
	const jsonToSheet = utils.json_to_sheet(modifiedData)
	// Add Worksheet to Workbook ( workbook contains one or more worksheets )
	utils.book_append_sheet(wb, jsonToSheet, 'Sheet 1')

	// Export excel file
	writeFile(wb, `${fileName}.xlsx`)
}

export const getRandomImage = ({
	width = 300,
	height = 400,
	keyword = 'random',
	random = true,
} = {}) => {
	let id = 1
	if (random) id = Math.round(Math.random() * 1000)
	return `https://unsplash.it/${width}/${height}/?${keyword}&id=${id}`
}

export const getAccomodationName = (id) => {
	return ACCOMODATION_CATEGORY[id]?.name
}

export const applyFilter = (value, filterKeys) => {
	return (item) => {
		let values = filterKeys.map((key) => String(item[key])?.toLowerCase?.())
		return values.some((el) => el.includes(value))
	}
}

export function customDebounce(func, delay) {
	let timeoutId
	return function (...args) {
		// Clear the previous timeout if it exists
		clearTimeout(timeoutId)
		console.log(timeoutId)
		timeoutId = setTimeout(function () {
			func.apply(this, args)
		}, delay)
	}
}

export function buildSearchParam(searchValue) {
	let query = {
		q: searchValue,
	}
	// if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(searchValue)) {
	// 	query.email = searchValue
	// } else if (/\+?\d[\d -]{8,12}\d/.test(searchValue)) {
	// 	query.phone = searchValue
	// } else {
	// 	query.name = searchValue
	// }
	// console.log({ query, searchValue })
	return query
}

export function modifyProperty(arr, from, to) {
	return arr.map((obj) => {
		const { [from]: value, ...rest } = obj
		return { ...rest, [to]: value }
	})
}
