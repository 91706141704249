import { Button, Typography } from '@mui/material'
import React from 'react'
import { ROUTE } from '../../../../constants/route'
import { Link } from 'react-router-dom'
import useEventIdFromParams from '../../../../hooks/useEventIdFromParams'

function ActionButtons() {
	const eventId = useEventIdFromParams()

	const homeLink = () => {
		return `${ROUTE.EVENT_BRIDGE_PAGE.path}/${eventId}`
	}

	return (
		<div className="action-btns">
			<Link to={homeLink()}>
				<Button variant="contained" color="danger" className="mb-16">
					Return To Home Page
				</Button>
			</Link>
			<br />
			<Button
				variant="contained"
				color="primary"
				className="mb-16"
				onClick={() => {
					window.location.reload()
				}}
			>
				Check Payment Status
			</Button>

			<Typography variant="body1">
				Read our{' '}
				<Link to={ROUTE.PRIVACY.path} className="font-bold">
					Privacy policy
				</Link>
			</Typography>
		</div>
	)
}

export default ActionButtons
