import React from "react";

const CareIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={48}
      height={48}
      viewBox="0 0 512.001 512.001"
      style={{ enableBackground: "new 0 0 512.001 512.001;" }}
    >
      <g>
        <path
          style={{ fill: "#F9DCA4" }}
          d="M53.562,87.202l5.427,2.444c6.787,3.066,14.469,3.508,21.55,1.254L218.412,47.08
       c13.717-4.362,28.56-3.64,41.79,2.053l131.625,56.545c12.726,5.45,18.58,20.225,13.055,32.918c-0.027,0.06-0.053,0.12-0.08,0.181
       c-5.308,11.962-18.987,17.816-31.337,13.343l-100.693-36.432c-7.792-2.828-16.334-2.914-24.205-0.255l-88.48,29.874
       c-9.61,3.253-15.108,13.332-12.618,23.162c1.535,6.024,5.709,10.645,10.952,12.972c4.238,1.881,9.172,2.272,13.957,0.691
       l77.206-25.506c10.347-3.413,21.592,1.687,25.843,11.721c2.368,5.566,2.121,11.554-0.146,16.663
       c-2.201,4.959-6.317,9.086-11.845,11.129l-121.733,45.224c-0.89,0.342-1.803,0.657-2.708,0.956
       c-22.38,7.426-47.009,3.383-66.506-9.91l-64.01-43.624"
        />
        <path
          style={{ fill: "#F9DCA4" }}
          d="M436.936,467.736l-3.546-3.586c-4.432-4.491-10.373-7.18-16.656-7.554l-122.356-7.348
       c-12.174-0.729-23.689-5.822-32.416-14.352l-86.846-84.798c-8.402-8.185-8.53-21.651-0.295-30.003
       c0.04-0.04,0.079-0.079,0.119-0.119c7.84-7.84,20.439-8.304,28.841-1.005l68.516,59.474c5.299,4.61,12.026,7.279,19.06,7.575
       l79.056,3.329c8.589,0.355,16.006-5.939,17.031-14.469c0.621-5.23-1.271-10.156-4.708-13.593c-2.777-2.777-6.56-4.591-10.825-4.797
       l-68.812-3.349c-9.22-0.453-16.558-7.909-16.863-17.139c-0.177-5.122,1.841-9.781,5.191-13.13c3.25-3.251,7.761-5.26,12.755-5.191
       l110.024,1.32c0.808,0,1.625,0.029,2.433,0.069c19.956,0.946,38.198,11.643,49.565,28.092l37.321,53.988"
        />
      </g>
      <path
        style={{ fill: "#EC589B" }}
        d="M329.875,223.729c0-14.972,10.309-27.11,23.026-27.11c15.896,0,28.782,15.172,28.782,33.888
   c0,23.395-27.723,46.266-51.809,58.58c-24.084-12.312-51.809-35.185-51.809-58.58c0-18.716,12.887-33.888,28.782-33.888
   C319.566,196.619,329.875,208.756,329.875,223.729z"
      />
      <path
        d="M283.032,188.447c3.297-7.43,3.37-15.967,0.203-23.409c-5.929-13.992-21.879-21.224-36.31-16.463l-77.207,25.506
   c-2.581,0.852-5.369,0.713-7.857-0.389c-3.089-1.371-5.341-4.039-6.173-7.306c-1.393-5.495,1.734-11.226,7.111-13.047l88.481-29.875
   c6.037-2.039,12.642-1.969,18.606,0.196l100.689,36.431c16.452,5.959,34.883-1.896,41.931-17.78l0.148-0.331
   c3.581-8.227,3.73-17.353,0.419-25.694c-3.309-8.337-9.669-14.872-17.902-18.397l-131.62-56.543
   c-15.096-6.494-32.041-7.327-47.709-2.343l-43.588,13.853c-4.463,1.418-6.93,6.186-5.512,10.647
   c1.418,4.463,6.187,6.932,10.647,5.512l43.59-13.853c11.782-3.748,24.521-3.122,35.874,1.762l131.633,56.548
   c4.062,1.74,7.195,4.96,8.827,9.069c1.632,4.115,1.559,8.615-0.198,12.649l7.749,3.439l-7.816-3.292
   c-3.495,7.879-12.586,11.751-20.702,8.809l-100.684-36.428c-9.56-3.471-20.146-3.582-29.81-0.319l-88.485,29.876
   c-13.711,4.641-21.669,19.258-18.115,33.286c2.129,8.347,7.862,15.136,15.73,18.628c6.347,2.816,13.47,3.17,20.056,0.991
   l77.202-25.505c6.114-2.013,12.872,1.048,15.387,6.989c1.358,3.191,1.324,6.709-0.095,9.905c-1.375,3.098-3.874,5.449-7.049,6.622
   l-121.83,45.261c-0.761,0.293-1.543,0.561-2.331,0.821c-19.399,6.436-41.477,3.121-59.062-8.868L13.253,181.78
   c-3.868-2.636-9.142-1.638-11.78,2.231c-2.637,3.869-1.638,9.143,2.231,11.78l64.008,43.623
   c14.511,9.893,31.526,15.032,48.441,15.031c8.642,0,17.259-1.342,25.5-4.075c1.211-0.4,2.195-0.748,3.003-1.06l121.718-45.221
   C273.868,201.322,279.782,195.766,283.032,188.447z"
      />
      <path
        d="M50.08,94.933l5.418,2.441c5.046,2.28,10.461,3.43,15.895,3.43c3.939,0,7.889-0.605,11.713-1.822l61.49-19.543
   c4.463-1.418,6.93-6.186,5.512-10.647c-1.418-4.463-6.189-6.93-10.647-5.512L77.966,82.823c-5.101,1.626-10.602,1.304-15.496-0.905
   l-5.427-2.444c-4.268-1.924-9.288-0.02-11.211,4.249C43.91,87.99,45.812,93.01,50.08,94.933z"
      />
      <path
        d="M510.496,396.33l-37.321-53.986c-13.009-18.826-33.995-30.691-56.122-31.739c-0.946-0.047-1.871-0.052-2.748-0.079
   l-110.008-1.32c-7.164-0.106-13.821,2.627-18.867,7.674c-5.123,5.124-7.918,12.202-7.669,19.404
   c0.448,13.538,11.393,24.662,24.925,25.328l68.812,3.348c1.977,0.096,3.839,0.921,5.242,2.325c1.743,1.742,2.575,4.146,2.286,6.586
   c-0.494,4.102-4.167,7.175-8.258,7.011l-79.057-3.33c-5.102-0.214-10.02-2.167-13.859-5.506l-68.514-59.471
   c-11.699-10.162-29.441-9.543-40.455,1.47c-0.015,0.015-0.088,0.088-0.102,0.103c-5.582,5.661-8.611,13.158-8.531,21.109
   c0.08,7.946,3.258,15.375,8.941,20.911l38.713,37.799c3.349,3.27,8.718,3.207,11.989-0.142c3.271-3.35,3.207-8.718-0.142-11.989
   l-38.72-37.806c-2.431-2.369-3.79-5.546-3.824-8.944c-0.034-3.403,1.263-6.612,3.647-9.03l-6.016-5.974l6.096,5.894
   c4.687-4.687,12.28-4.948,17.288-0.597l68.509,59.467c6.72,5.848,15.34,9.274,24.269,9.649l79.061,3.33
   c12.962,0.512,24.257-9.099,25.8-21.942c0.907-7.645-1.694-15.148-7.134-20.586c-4.387-4.388-10.214-6.97-16.409-7.271
   l-68.806-3.348c-4.78-0.235-8.648-4.167-8.807-8.965c-0.088-2.573,0.874-5.002,2.712-6.84c1.782-1.783,4.144-2.757,6.66-2.708
   l110.126,1.32c0.669,0,1.346,0.026,2.032,0.06c16.895,0.8,32.967,9.938,42.991,24.445l37.322,53.988
   c1.645,2.379,4.291,3.657,6.981,3.657c1.663,0,3.343-0.488,4.813-1.505C512.195,405.462,513.158,400.181,510.496,396.33z"
      />
      <path
        d="M439.425,458.197c-5.911-5.99-13.79-9.563-22.183-10.063l-122.357-7.349c-10.145-0.607-19.732-4.852-27.001-11.954
   l-27.274-26.632c-3.351-3.271-8.718-3.207-11.989,0.142s-3.207,8.718,0.142,11.989l27.272,26.63
   c10.182,9.951,23.62,15.9,37.835,16.75l122.359,7.349c4.211,0.251,8.161,2.043,11.134,5.055l3.547,3.584
   c1.658,1.676,3.841,2.515,6.026,2.515c2.155,0,4.31-0.816,5.962-2.451c3.329-3.293,3.358-8.661,0.066-11.989L439.425,458.197z"
      />
      <path
        d="M366.517,262.83c3.729,2.831,9.049,2.101,11.878-1.629c7.808-10.29,11.766-20.616,11.766-30.694
   c0-23.361-16.715-42.366-37.26-42.366c-9.078,0-17.271,4.36-23.026,11.324c-5.755-6.963-13.948-11.324-23.026-11.324
   c-20.545,0-37.26,19.005-37.26,42.366c0,13.018,6.488,26.263,19.285,39.368c9.771,10.007,22.962,19.51,37.143,26.76
   c1.212,0.619,2.536,0.929,3.859,0.929c1.324,0,2.647-0.31,3.859-0.929c6.677-3.414,13.17-7.346,19.297-11.688
   c3.821-2.707,4.724-7.999,2.016-11.819c-2.706-3.819-7.996-4.723-11.818-2.016c-4.211,2.983-8.764,5.836-13.353,8.373
   c-21.551-11.919-43.331-31.534-43.331-48.977c0-14.011,9.109-25.41,20.304-25.41c8.022,0,14.548,8.358,14.548,18.632
   c0,4.682,3.795,8.478,8.478,8.478s8.478-3.796,8.478-8.478c0-10.274,6.526-18.632,14.548-18.632
   c11.195,0,20.304,11.399,20.304,25.41c0,6.292-2.799,13.17-8.317,20.444C362.056,254.682,362.786,259.999,366.517,262.83z"
      />
    </svg>
  );
};

export default CareIcon;
