import * as React from 'react'
import './style.scss'
import { BarChart } from '@mui/x-charts/BarChart'
import { Autocomplete, Button, Grid2, Paper, TextField } from '@mui/material'
import { getUsers } from '../../services/user.js'
import { getAreas } from '../../utils/index.js'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { format, isValid } from 'date-fns'
import { getSadhna } from '../../services/sadhna.js'
import { GridSearchIcon } from '@mui/x-data-grid'
import {
	getCurrentWeekMonday,
	getDayFromDate,
	getSeventhDate,
	SadhnaMarks,
} from './helper.js'
import { HIDE_MAIN_LOADER, SHOW_MAIN_LOADER } from '../../constants/actions.js'
import store from '../../store.js'
import { showAlert } from '../../actions.js'
import { ALERT } from '../../constants/index.js'
import { axisClasses } from '@mui/x-charts'
import { useSearchParams } from 'react-router-dom'

const chartSetting = {
	yAxis: [
		{
			label: 'Percentage',
		},
	],
	height: 500,
	sx: {
		[`.${axisClasses.left} .${axisClasses.label}`]: {
			transform: 'translate(-20px, 0)',
		},
	},
}

const ALL_AREAS = getAreas()

export function valueFormatter(value) {
	return `${value}%`
}

const SadhnaReportContainer = () => {
	const [filter, setFilter] = React.useState({
		user: null,
		from: getCurrentWeekMonday(),
		to: new Date(),
		areaId: '',
	})
	const [users, setUsers] = React.useState([])
	const [sadhnaData, setSadhnaData] = React.useState([])
	const [, setLoading] = React.useState(false)
	const [searchParams] = useSearchParams()
	const userIdQuery = searchParams?.get('userId')

	React.useEffect(() => {
		setLoading(true)
		getUsers({ connectedAreaId: filter.areaId, per_page: 1000 }).then(
			(response) => {
				setUsers(response?.data?.data || [])
				setLoading(false)
			}
		)
	}, [filter.areaId])

	React.useEffect(() => {
		if (userIdQuery) {
			searchSadhna(userIdQuery)
		}
	}, [])

	const onChangeFilter = (key, value) => {
		setFilter((prevData) => ({
			...prevData,
			[key]: value,
		}))
	}

	const generateSadhna = (sadhnaList) => {
		if (sadhnaList && sadhnaList.length > 0) {
			const formattedSadhna = sadhnaList.map((sadhna) => {
				const {
					servicePercentage,
					readingPercentage,
					hearingPercentage,
				} = SadhnaMarks.getIndividualPercentage(sadhna)
				return {
					day: getDayFromDate(sadhna.date),
					date: format(sadhna.date, 'dd/MM/yyyy'),
					percentage:
						(servicePercentage +
							readingPercentage +
							hearingPercentage) /
						4,
					servicePercentage,
					readingPercentage,
					hearingPercentage,
				}
			})
			return formattedSadhna
		} else {
			return []
		}
	}

	function validateAndFormatDates(filter) {
		// Check if 'from' and 'to' are valid dates
		if (!isValid(new Date(filter.from))) {
			throw new Error("Invalid 'from' date")
		}
		if (!isValid(new Date(filter.to))) {
			throw new Error("Invalid 'to' date")
		}

		// Format 'from' and 'to' dates
		const from = format(new Date(filter.from), 'yyyy-MM-dd')
		const to = format(new Date(filter.to), 'yyyy-MM-dd')

		// Check if 'userId' exists and is a string
		const userId = filter.user?._id
		if (!userId || typeof userId !== 'string') {
			throw new Error("Invalid or missing 'userId'")
		}

		return { from, to, userId }
	}

	const searchSadhna = () => {
		// Usage
		try {
			const validateObj = {
				from: filter.from,
				to: filter.to,
				user: userIdQuery ? { _id: userIdQuery } : filter.user,
			}
			const { from, to, userId } = validateAndFormatDates(validateObj)
			store.dispatch({ type: SHOW_MAIN_LOADER })
			getSadhna({ from, to, userId, perPage: 100 }).then((res) => {
				store.dispatch({ type: HIDE_MAIN_LOADER })
				setSadhnaData(generateSadhna(res?.data?.data?.reverse()))
			})
		} catch (error) {
			store.dispatch(
				showAlert({
					message: error?.message,
					severity: ALERT.ERROR,
					duration: 5000,
				})
			)
		}
	}

	return (
		<Paper>
			<Grid2
				container
				direction="row"
				spacing={2}
				sx={{ padding: '15px' }}
			>
				<Grid2 item size={2}>
					<Autocomplete
						options={ALL_AREAS || []}
						getOptionLabel={(option) => option.name || ''}
						filterSelectedOptions
						isOptionEqualToValue={(option, value) =>
							option?._id === value._id
						}
						onChange={(e, newValue) => {
							setFilter((prev) => ({
								...prev,
								areaId: newValue?._id,
								user: null,
							}))
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								label="Connected Area"
								placeholder="Start typing Area Name"
							/>
						)}
					/>
				</Grid2>
				<Grid2 item size={2}>
					<Autocomplete
						options={users || []}
						getOptionLabel={(option) => {
							return (
								(option.initiatedName || option.name
									? `${option.initiatedName || option.name}` +
									  (option.phone ? ` (${option.phone})` : '')
									: option.initiatedName || option.name) +
								option?._id
							)
						}}
						filterSelectedOptions
						isOptionEqualToValue={(option, value) =>
							option?._id === value?._id
						}
						value={filter.user}
						onChange={(e, newValue) => {
							onChangeFilter('user', newValue)
						}}
						onBlur={() => {}}
						disabled={!filter.areaId}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								label="Facilitator"
								placeholder="Devotee Name"
							/>
						)}
					/>
				</Grid2>
				<Grid2 item size={2}>
					<DatePicker
						label="Start Date"
						format="dd/MM/yyyy"
						value={filter.from}
						onChange={(e) => {
							onChangeFilter('from', e)
							onChangeFilter('to', getSeventhDate(e))
						}}
						inputVariant="outlined"
					/>
				</Grid2>
				<Grid2 item size={2}>
					<DatePicker
						label="End Date"
						format="dd/MM/yyyy"
						value={filter.to}
						onChange={(e) => {
							onChangeFilter('to', e)
						}}
						inputVariant="outlined"
						minDate={filter.from} // Min is 6 days after 'from'
						// maxDate={getSeventhDate(filter.from)}
					/>
				</Grid2>
				<Grid2 item size={2}>
					<Button
						onClick={() => searchSadhna()}
						variant="contained"
						size="large"
					>
						<GridSearchIcon />
						Search
					</Button>
				</Grid2>
			</Grid2>
			<Grid2 container direction="row" spacing={2}>
				<Grid2 item size={6}>
					<BarChart
						dataset={sadhnaData}
						series={[
							{
								dataKey: 'percentage',
								label: 'Total',
								valueFormatter,
							},
						]}
						height={290}
						xAxis={[
							{
								data: sadhnaData.map(
									(sadhna) => `${sadhna.date} (${sadhna.day})`
								),
								scaleType: 'band',
							},
						]}
						colors={['#4CAF50']}
						margin={{ top: 50, bottom: 30, left: 40, right: 10 }}
						barLabel={(item) => {
							return `${item.value?.toString()}%`
						}}
						{...chartSetting}
					/>
				</Grid2>
				<Grid2 item size={6}>
					<BarChart
						dataset={sadhnaData}
						series={[
							{
								dataKey: 'servicePercentage',
								label: 'Service',
								valueFormatter,
							},
						]}
						height={290}
						xAxis={[
							{
								data: sadhnaData.map(
									(sadhna) => `${sadhna.date} (${sadhna.day})`
								),
								scaleType: 'band',
							},
						]}
						colors={['#2196F3', '#06B2AF']}
						margin={{ top: 50, bottom: 30, left: 40, right: 10 }}
						barLabel={(item) => {
							return `${item.value?.toString()}%`
						}}
						{...chartSetting}
					/>
				</Grid2>
			</Grid2>
			<Grid2 container direction="row" spacing={2}>
				<Grid2 item size={6}>
					<BarChart
						dataset={sadhnaData}
						series={[
							{
								dataKey: 'hearingPercentage',
								label: 'Hearing',
								valueFormatter,
							},
						]}
						height={290}
						xAxis={[
							{
								data: sadhnaData.map(
									(sadhna) => `${sadhna.date} (${sadhna.day})`
								),
								scaleType: 'band',
							},
						]}
						colors={['#06B2AF', '#FFC107']}
						margin={{ top: 50, bottom: 30, left: 40, right: 10 }}
						barLabel={(item) => {
							return `${item.value?.toString()}%`
						}}
						{...chartSetting}
					/>
				</Grid2>
				<Grid2 item size={6}>
					<BarChart
						dataset={sadhnaData}
						series={[
							{
								dataKey: 'readingPercentage',
								label: 'Reading',
								valueFormatter,
							},
						]}
						height={290}
						xAxis={[
							{
								data: sadhnaData.map(
									(sadhna) => `${sadhna.date} (${sadhna.day})`
								),
								scaleType: 'band',
							},
						]}
						colors={['#FFC107']}
						margin={{ top: 50, bottom: 30, left: 40, right: 10 }}
						barLabel={(item) => {
							return `${item.value?.toString()}%`
						}}
						{...chartSetting}
					/>
				</Grid2>
			</Grid2>
		</Paper>
	)
}

export default SadhnaReportContainer
