import {
	Box,
	Button,
	Card,
	Dialog,
	IconButton,
	TextField,
	Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './style.scss'
import {
	createOrUpdateAttendanceQRRequestAction,
	createOrUpdateInventoryRequestAction,
	getAttendanceQRsRequestAction,
} from '../../actions/event'
import { DataGrid, GridLoadIcon } from '@mui/x-data-grid'
import { isMobileScreen } from '../../utils'
import { CancelRounded, Done } from '@mui/icons-material'
import { closeDialog } from '../../actions'
import QRIcon from './assets/qr_code_2.svg'
import { getFullFormattedDateTimeString } from '../../components/Calender/helpers'
import { getUserAttendanceList } from '../../services/event'
const XLSX = require('xlsx')
const PREFIX = 'ManageQRScreen'

const classes = {
	body: `${PREFIX}-body`,
	modalRoot: `${PREFIX}-modalRoot`,
	modal: `${PREFIX}-modal`,
	qrModalContainer: `${PREFIX}-qrModalContainer`,
	qrtitle: `${PREFIX}-qrTitle`,
}

const styles = {
	ModalBox: {
		width: 800,
		backgroundColor: 'white',
		borderRadius: '8px',
		// boxShadow: theme.shadows[5],
		padding: '40px',
		lineHeight: '1.44',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
}

const StyledCard = styled(Card)({
	[`& .${classes.body}`]: {
		flexDirection: 'column',
	},
	[`& .${classes.modalRoot}`]: {
		// height: 300,
		position: 'absolute',
		flexGrow: 1,
		minWidth: 300,
		transform: 'translateZ(0)',
		// The position fixed scoping doesn't work in IE 11.
		// Disable this demo to preserve the others.
		'@media all and (-ms-high-contrast: none)': {
			display: 'none',
		},
	},
	[`& .${classes.modal}`]: {
		display: 'flex',
		// padding: theme.spacing(1),
		alignItems: 'center',
		justifyContent: 'center',
	},
	[`& .${classes.qrtitle}`]: {
		justifyContent: 'center',
		alignItems: 'center',
	},
	[`& .${classes.qrModalContainer}`]: {
		width: '80vh',
		backgroundColor: 'white',
		border: '2px solid #000',
		borderRadius: '8px',
		// boxShadow: theme.shadows[5],
		padding: '40px',
		lineHeight: '1.44',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
})

const ManageQRScreen = ({ eventId }) => {
	const [filters, setFilters] = useState({
		pageSize: 10,
		page: 0,
	})
	const [modalData, setModalData] = useState(null)
	const [qrUrlModal, showQRModal] = useState(null)
	const eventReducer = useSelector((state) => state.eventReducer)
	const loading = eventReducer.loading
	const attendanceQrData = eventReducer?.attendanceQrData

	const [selectedRows, setSelectedRows] = useState([])

	const dispatch = useDispatch()

	const loadInitialData = useCallback(() => {
		dispatch(
			getAttendanceQRsRequestAction({
				page: filters.page + 1,
				per_page: filters.pageSize,
				id: '',
				eventId,
				status: 1,
			})
		)
	}, [dispatch, filters, eventId])

	useEffect(() => {
		loadInitialData()
	}, [filters.page, filters.pageSize, loadInitialData])

	const headCells = [
		{
			field: 'title',
			numeric: false,
			headerName: 'Title',
			sortable: false,
		},
		{
			field: 'status',
			numeric: false,
			headerName: 'Status',
			sortable: false,
		},
		{
			field: 'createdAt',
			numeric: false,
			headerName: 'Created At',
			sortable: false,
		},
		{
			field: 'id',
			headerName: 'Actions',
			sortable: false,
			filterable: false,
			renderCell: (params) => (
				<>
					<IconButton
						onClick={() => {
							showQRModal(params?.row)
						}}
						aria-label="Edit"
						color="primary"
						title="Edit"
						size="large"
					>
						<img src={QRIcon} alt="QR Icon" />
					</IconButton>
				</>
			),
		},
	]

	const rowWidthConfig = headCells.reduce((acc, value) => {
		acc[value.field] = { default: { flex: 0.15 }, mobile: { width: 150 } }
		return acc
	}, {})

	const handleUserData = ({ page, pageSize }) => {
		setFilters((f) => ({
			...f,
			page,
			pageSize,
		}))
	}

	const onChange = (key, value) => {
		setModalData((prevValue) => ({
			...prevValue,
			[key]: value,
		}))
	}

	const validateData = (data) => {
		return data?.title
	}

	const submitRequest = (deleteId = false) => {
		if (!validateData(modalData) && !deleteId) return
		if (!deleteId) {
			const { id } = modalData
			modalData.eventId = eventId
			dispatch(
				createOrUpdateAttendanceQRRequestAction({
					id,
					data: modalData,
				})
			).then((resp) => {
				setModalData(null)
				loadInitialData()
			})
		} else {
			dispatch(closeDialog())
			console.log(deleteId)
			dispatch(
				createOrUpdateInventoryRequestAction({
					id: deleteId,
					attendanceQrData: { status: 0 },
				})
			).then((resp) => {
				setModalData(null)
				loadInitialData()
			})
		}
	}

	const downloadImage = async (imageUrl, title) => {
		try {
			// Fetch the image data
			const response = await fetch(imageUrl)
			const blob = await response.blob()

			// Create a URL for the Blob
			const url = window.URL.createObjectURL(blob)

			// Create a link element
			const a = document.createElement('a')
			a.href = url

			// Set the download attribute with a file name
			a.download = `${title}.png`

			// Append the link to the body and simulate a click
			document.body.appendChild(a)
			a.click()

			// Clean up and remove the link
			a.remove()
			window.URL.revokeObjectURL(url)
		} catch (error) {
			console.error('Failed to download image:', error)
		}
	}

	const generateAttendanceReport = () => {
		const promises = selectedRows.map((eventId) => {
			return getUserAttendanceList({
				page: 1,
				per_page: 500,
				eventId: eventId,
			})
		})
		Promise.all(promises).then((result) => {
			const attendanceRecords = result.map((res) => res.data.data)
			console.log(attendanceRecords)
			// Step 1: Flatten nested arrays
			const flattenedRecords = attendanceRecords.flat()

			// Step 2: Extract unique participants and events
			const participants = [
				...new Set(
					flattenedRecords.map((record) => record.userId.name)
				),
			]
			const events = [
				...new Set(
					flattenedRecords.map((record) => record.eventId.title)
				),
			]

			// Step 3: Build attendance matrix with totals
			const attendanceMatrix = participants.map((participant) => {
				let totalPresent = 0
				let totalAbsent = 0

				const row = { Name: participant }

				events.forEach((event) => {
					const isPresent = flattenedRecords.some(
						(record) =>
							record.userId.name === participant &&
							record.eventId.title === event
					)

					row[event] = isPresent ? 'P' : 'A'
					if (isPresent) {
						totalPresent++
					} else {
						totalAbsent++
					}
				})

				// Add total present and absent columns
				row['Total Present'] = totalPresent
				row['Total Absent'] = totalAbsent

				return row
			})

			// Rearrange columns to have "Total Present" and "Total Absent" first
			const formattedAttendanceMatrix = attendanceMatrix.map((record) => {
				const { Name, ...rest } = record
				const {
					'Total Present': totalPresent,
					'Total Absent': totalAbsent,
					...events
				} = rest

				return {
					Name,
					'Total Present': totalPresent,
					'Total Absent': totalAbsent,
					...events,
				}
			})

			// Step 4: Export to Excel
			const generateExcel = (data, fileName) => {
				const worksheet = XLSX.utils.json_to_sheet(data)
				const workbook = XLSX.utils.book_new()
				XLSX.utils.book_append_sheet(workbook, worksheet, 'Attendance')
				XLSX.writeFile(workbook, fileName)
			}

			// Generate Excel file
			generateExcel(
				formattedAttendanceMatrix,
				'Attendance_Report_with_Totals.xlsx'
			)
		})
	}

	const handleSelectionChange = (selectionModel) => {
		setSelectedRows(selectionModel) // Update selected row IDs
	}

	return (
		<StyledCard className="p-3">
			<div className="screen overview-screen">
				<div className="filter-header">
					<Typography variant="h4">Attendance QR Code</Typography>
					<div>
						<Button
							variant="contained"
							color="primary"
							onClick={generateAttendanceReport}
						>
							Generate Attendance Report
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={() => setModalData({})}
						>
							Create QR
						</Button>
					</div>
				</div>
				{!!modalData && (
					<Dialog
						open={!!modalData}
						onClose={() => setModalData(null)}
						maxWidth="md"
						onBackdropClick={() => setModalData(null)}
					>
						<Box sx={styles.ModalBox}>
							<form noValidate autoComplete="off">
								<Typography variant="h3">
									{modalData?._id
										? 'Update Attendance QR'
										: 'Create Attendance QR'}
								</Typography>
								<TextField
									className="m-top-16"
									label="Title"
									variant="outlined"
									defaultValue={modalData.title}
									value={modalData.title}
									name="title"
									onChange={(e) =>
										onChange(e.target.name, e.target.value)
									}
									fullWidth
								/>
							</form>

							<div className="m-top-16">
								<Button
									variant="contained"
									color="secondary"
									startIcon={<CancelRounded />}
									onClick={() => setModalData(null)}
									aria-label="Reject"
								>
									Close
								</Button>
								&nbsp; &nbsp; &nbsp;
								{loading ? (
									<GridLoadIcon />
								) : (
									<Button
										variant="contained"
										color="primary"
										startIcon={<Done />}
										onClick={() => submitRequest()}
										aria-label="Reject"
									>
										{modalData?.id ? 'Update' : 'Create'}
									</Button>
								)}
							</div>
						</Box>
					</Dialog>
				)}
				{!!qrUrlModal && (
					<div className={classes.modalRoot}>
						<Dialog
							open={!!qrUrlModal}
							onClose={() => showQRModal(null)}
							className={classes.modal}
							onBackdropClick={() => showQRModal(null)}
							sx={{
								textAlign: 'center',
							}}
						>
							<div className={classes.qrModalContainer}>
								<img
									src={qrUrlModal.url}
									alt="QR"
									style={{
										height: '70vh',
										width: '70vh',
									}}
								/>
								<p className={classes.qrtitle}>
									{qrUrlModal.title}
								</p>
								<Button
									onClick={() =>
										downloadImage(
											qrUrlModal.url,
											qrUrlModal.title
										)
									}
									variant="contained"
									className="mb-2 mt-2"
								>
									Download QR
								</Button>
							</div>
						</Dialog>
					</div>
				)}
				<div className="m-top-16">
					<DataGrid
						rows={
							attendanceQrData?.data?.map((inv) => ({
								id: inv._id,
								title: inv.title,
								status:
									inv.status === 1 ? 'Active' : 'Inactive',
								updatedAt: inv.updatedAt,
								url: inv.url,
								createdBy: inv.createdBy,
								createdAt: getFullFormattedDateTimeString(
									inv.createdAt
								),
								addedBy: inv.addedBy,
							})) || []
						}
						autoHeight
						columns={headCells?.map((col) => ({
							...col,
							sortable: true,
							disableClickEventBubbling: true,
							// // resizable: true,
							...(isMobileScreen()
								? rowWidthConfig[col.field].mobile ||
								  rowWidthConfig[col.field].default
								: rowWidthConfig[col.field].default),
						}))}
						pageSize={filters.per_page}
						rowCount={attendanceQrData?.total_results}
						loading={loading}
						paginationModel={filters}
						checkboxSelection
						onRowSelectionModelChange={(newSelectionModel) => {
							handleSelectionChange(newSelectionModel)
						}}
						selectionModel={selectedRows}
						onPaginationModelChange={(page) =>
							handleUserData({
								pageSize: page.pageSize,
								page:
									page.pageSize != filters.pageSize
										? 0
										: page.page,
							})
						}
					/>
				</div>
			</div>
		</StyledCard>
	)
}

export default ManageQRScreen
