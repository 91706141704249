import {
	SAVE_USER_DETAILS_STARTED,
	SAVE_USER_DETAILS_SUCCESS,
	SAVE_USER_DETAILS_FAILED,
	SHOW_MAIN_LOADER,
	HIDE_MAIN_LOADER,
	SHOW_ALERT,
	HIDE_ALERT,
	SHOW_DIALOG,
	HIDE_DIALOG,
	LOGOUT_USER_STARTED,
	LOGOUT_USER_SUCCESS,
	LOGOUT_USER_FAILED,
	OPEN_DRAWER,
	CLOSE_DRAWER,
} from './constants/actions'
import { ALERT } from './constants'
import areaReducer from './reducers/area'
import userReducer from './reducers/user'
import pledgeReducer from './reducers/pledge'
import notificationReducer from './reducers/notification'
import scheduleReducer from './reducers/schedule'
import eventReducer from './reducers/event'

let initialState = {
	user: null,
	loading: false,
	dialog: { open: false },
	error: null,
	alert: null,
	drawerOpen: false,
}

const commonContent = (state = initialState, action) => {
	switch (action.type) {
		case SAVE_USER_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				user: action.payload,
			}
		case SAVE_USER_DETAILS_FAILED:
		case LOGOUT_USER_FAILED:
			return {
				...state,
				loading: false,
				error: action.error,
				alert: {
					message: action.error,
					severity: ALERT.ERROR,
					duration: 5000,
				},
			}
		case SHOW_MAIN_LOADER:
		case SAVE_USER_DETAILS_STARTED:
		case LOGOUT_USER_STARTED:
			return {
				...state,
				loading: true,
			}
		case HIDE_MAIN_LOADER:
			return {
				...state,
				loading: false,
			}
		case SHOW_ALERT:
			return {
				...state,
				alert: action.payload,
			}
		case HIDE_ALERT:
			return {
				...state,
				alert: null,
			}
		case SHOW_DIALOG:
			return {
				...state,
				dialog: { ...action.payload, open: true },
			}
		case HIDE_DIALOG:
			return {
				...state,
				dialog: { ...state.dialog, open: false },
			}
		case LOGOUT_USER_SUCCESS:
			return {
				...state,
				loading: false,
				user: null,
			}
		case OPEN_DRAWER:
			return {
				...state,
				drawerOpen: true,
			}
		case CLOSE_DRAWER:
			return {
				...state,
				drawerOpen: false,
			}
		default:
			return state
	}
}

const ExportedReducers = {
	commonContent,
	areaReducer,
	userReducer,
	pledgeReducer,
	notificationReducer,
	scheduleReducer,
	eventReducer,
}

export default ExportedReducers
