import useFetchData from '../../../hooks/useFetchData'
import { ACCOMODATION_CATEGORY } from '../../../constants'
import { getPublicInventoryData } from '../../../services/viplava'

const modifyInventoryData = (inventoryData) => {
	if (!inventoryData) return
	const finalInventoryData = inventoryData?.reduce((acc, value) => {
		acc[value.accomodationCategory] = value.inventory
		return acc
	}, {})
	// Returns Availability Object{ 1: {left, total}, 2: {left, total}, 3: {left, total}
	return finalInventoryData
}

function AccommodationAvailabilityWrapper({ children, eventId, userDetails }) {
	const accommodationCategory = Object.values(ACCOMODATION_CATEGORY)
		.map((el) => el.id)
		.join(',')
	const { data } = useFetchData(() =>
		getPublicInventoryData({
			eventId,
			accomodationCategory: accommodationCategory,
			userId: userDetails?._id,
		})
	)
	const inventoryData = data?.result
	return children(modifyInventoryData(inventoryData))
}

export default AccommodationAvailabilityWrapper
