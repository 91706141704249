import React from 'react'
import { styled } from '@mui/material/styles'
import { Button, InputAdornment, Paper, TextField } from '@mui/material'
import { CloudDownload, Tune } from '@mui/icons-material'
import { getPledgeUsersAction } from '../../actions/pledge'
import { connect } from 'react-redux'
import { DataGrid, GridSearchIcon } from '@mui/x-data-grid'
import { format } from 'date-fns'
import { exportFromJSON, isMobileScreen } from '../../utils'
import './style.scss'

const PREFIX = 'ViewAreaPledges'

const classes = {
	tableHeader: `${PREFIX}-tableHeader`,
}

const Root = styled('div')({
	[`& .${classes.tableHeader}`]: {
		padding: '20px',
		marginBottom: '20px',
	},
})

class ViewAreaPledges extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: [],
		}
		this.isMobile = isMobileScreen()
	}

	componentDidMount() {
		const { match } = this.props
		this.props
			.getPledgeUsersAction({ ...match.params, per_page: 1000 })
			.then((res) => {
				this.setState(res.data)
			})
	}

	handleSearch = (searchString) => {
		this.setState({
			searchValue: searchString,
		})
	}

	searchRows = (row, searchValue) => {
		return (
			(row?.userId?.name &&
				row.userId.name
					.toUpperCase()
					.includes(searchValue.toUpperCase())) ||
			(row?.userId?.initiatedName &&
				row.userId.initiatedName
					.toUpperCase()
					.includes(searchValue.toUpperCase()))
		)
	}

	download = () => {
		const { data } = this.state
		const fileName = 'pledges'
		exportFromJSON({
			data: data.map((p) => ({
				name: p.userId.initiatedName || p.userId.name,
				pledgedOn: format(
					new Date(p.pledgeTime),
					'dd MMM yyyy h:m aaa'
				),
				pledgeValue: p.pledgeValue,
				achieveValue: p.achieveValue,
			})),
			fileName,
		})
	}

	populateData = (pledges) => {
		const rowWidthConfig = {
			name: { default: { flex: 0.4 }, mobile: { width: 200 } },
			pledgedOn: { default: { flex: 0.2 }, mobile: { width: 200 } },
			pledgeValue: { default: { flex: 0.2 }, mobile: { width: 100 } },
			achieveValue: { default: { flex: 0.2 }, mobile: { width: 100 } },
		}
		const headCells = [
			{
				field: 'name',
				numeric: false,
				headerName: 'Name',
				sortable: false,
			},
			{
				field: 'pledgedOn',
				numeric: false,
				headerName: 'Pledged on',
				sortable: false,
			},
			{
				field: 'pledgeValue',
				numeric: false,
				headerName: 'Pledge taken',
				sortable: true,
			},
			{
				field: 'achieveValue',
				numeric: false,
				headerName: 'Achieved',
				sortable: true,
			},
		]
		const { total_results, loading } = this.props
		return (
			<Root className="pledging-container">
				<Paper className="p-3">
					<div className="table-header-container">
						<TextField
							id="input-with-icon-textfield"
							label="Search"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<GridSearchIcon />
									</InputAdornment>
								),
							}}
							onChange={(e) => this.handleSearch(e.target.value)}
							autoComplete="off"
							value={this.state.searchValue}
						/>
						<div>
							<Button
								startIcon={<Tune />}
								color="primary"
								variant="contained"
								onClick={this.openFilterModal}
							>
								Filter
							</Button>
							&nbsp; &nbsp; &nbsp;
							<Button
								startIcon={<CloudDownload />}
								color="primary"
								variant="contained"
								onClick={this.download}
							>
								Download
							</Button>
						</div>
					</div>
				</Paper>
				<div style={{ width: '100%', background: 'white' }}>
					<DataGrid
						rows={pledges
							.filter((pledge) => {
								return this.state.searchValue
									? this.searchRows(
											pledge,
											this.state.searchValue
									  )
									: true
							})
							.map((pledge) => ({
								...pledge,
								pledge,
								id: pledge._id,
								name:
									pledge.userId.initiatedName ||
									pledge.userId.name,
								pledgedOn: format(
									new Date(pledge.pledgeTime),
									'dd MMM yyyy h:m aaa'
								),
							}))}
						autoHeight
						columns={headCells.map((col) => ({
							...col,
							sortable: true,
							disableClickEventBubbling: true,
							...(this.isMobile
								? rowWidthConfig[col.field].mobile ||
								  rowWidthConfig[col.field].default
								: rowWidthConfig[col.field].default),
						}))}
						pageSize={10}
						rowCount={total_results}
						pageSizeOptions={[5, 10, 20]}
						pagination
						// paginationMode="server"
						// onPageChange={this.handlePledgeDriveData}
						disableColumnFilter
						loading={loading}
					/>
				</div>
			</Root>
		)
	}

	render() {
		return (
			<div className={`pledging-container`}>
				{this.populateData(this.state.data)}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	pledge: state.pledgeReducer,
})

const mapDispatchToProps = {
	getPledgeUsersAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewAreaPledges)
