import {
	SAVE_USER_DETAILS_STARTED,
	SAVE_USER_DETAILS_SUCCESS,
	SAVE_USER_DETAILS_FAILED,
	LOGOUT_USER_STARTED,
	LOGOUT_USER_SUCCESS,
	LOGOUT_USER_FAILED,
} from "../constants/actions";
import { loginService, logout, validateToken } from "../services/auth";
import { extractErrorStr } from "../utils/network";
import { actionCreator } from "../utils/redux";

export const loginAction = (loginData) => (dispatch) => {
	dispatch(actionCreator(SAVE_USER_DETAILS_STARTED));
	return loginService(loginData)
		.then((json) => {
			dispatch(actionCreator(SAVE_USER_DETAILS_SUCCESS, json.data[0]));
			return json;
		})
		.catch((error) => {
			dispatch(actionCreator(SAVE_USER_DETAILS_FAILED, extractErrorStr(error)));
			return error;
		});
};

export const logoutAction = () => (dispatch) => {
	dispatch(actionCreator(LOGOUT_USER_STARTED));
	return logout()
		.then((json) => {
			dispatch(actionCreator(LOGOUT_USER_SUCCESS));
			return json;
		})
		.catch((error) => {
			dispatch(actionCreator(LOGOUT_USER_FAILED, extractErrorStr(error)));
			return error;
		});
};

export const validateTokenAction = (token) => (dispatch) => {
	dispatch(actionCreator(SAVE_USER_DETAILS_STARTED));
	return validateToken(token)
		.then((json) => {
			dispatch(actionCreator(SAVE_USER_DETAILS_SUCCESS, json.data[0]));
			return json;
		})
		.catch((error) => {
			dispatch(actionCreator(SAVE_USER_DETAILS_FAILED, extractErrorStr(error)));
			return error;
		});
};
