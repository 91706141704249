import {
    GET_SCHEDULE_FAILED,
	GET_SCHEDULE_STARTED,
	GET_SCHEDULE_SUCCESS,
	CREATE_SCHEDULE_FAILED,
	CREATE_SCHEDULE_STARTED,
	CREATE_SCHEDULE_SUCCESS,
    UPDATE_SCHEDULE_FAILED,
	UPDATE_SCHEDULE_STARTED,
	UPDATE_SCHEDULE_SUCCESS,
    
} from "../constants/actions";
import {
    addScheduledNotification,
    getScheduledNotifications,
    updateScheduledNotification
} from "../services/notification";
import { extractErrorStr } from "../utils/network";
import { actionCreator } from "../utils/redux";


export const getScheduleAction = (params) => (dispatch) => {
    dispatch(actionCreator(GET_SCHEDULE_STARTED));
	return getScheduledNotifications(params)
		.then((json) => {
			dispatch(actionCreator(GET_SCHEDULE_SUCCESS, {response: json.data, date: params.date}));
			return json;
		})
		.catch((error) => {
			dispatch(actionCreator(GET_SCHEDULE_FAILED, extractErrorStr(error)));
			return error;
		});
}

export const createScheduleAction = (createdSchedule) => (dispatch) => {
	dispatch(actionCreator(CREATE_SCHEDULE_STARTED));
	return addScheduledNotification(createdSchedule)
		.then((json) => {
			dispatch(actionCreator(CREATE_SCHEDULE_SUCCESS, json.data));
			return json;
		})
		.catch((error) => {
			dispatch(actionCreator(CREATE_SCHEDULE_FAILED, extractErrorStr(error)));
			return error;
		});
};

export const updateScheduleAction = (notificationId, data) => (dispatch) => {
	dispatch(actionCreator(UPDATE_SCHEDULE_STARTED));
	return updateScheduledNotification(notificationId, data)
		.then((json) => {
			dispatch(actionCreator(UPDATE_SCHEDULE_SUCCESS, json.data));
			return json;
		})
		.catch((error) => {
			dispatch(actionCreator(UPDATE_SCHEDULE_FAILED, extractErrorStr(error)));
			return error;
		});
};

