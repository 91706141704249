import React, { useMemo } from 'react'
import CashScreen from './components/CashScreen'
import FundScreen from './components/FundsScreen'
import OverviewScreen from './components/OverviewScreen'
import ReportScreen from './components/ReportScreen'
import SubsidyScreen from './components/SubsidyScreen'
import TabHeader, { TAB_HEADER_VALUES } from './components/TabHeader'
import './style.scss'
import { isGenSecretary } from '../../utils'
import InventoryScreen from './components/InventoryScreen'
import { withRouter } from '../../hooks/withRouter'

const EventContainer = ({ router }) => {
	const [value, setValue] = React.useState(TAB_HEADER_VALUES.OVERVIEW)
	const eventId = router?.params?.eventId
	const isGeneralSecratory = useMemo(() => isGenSecretary(), [])

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	return (
		<div className="event-container">
			<TabHeader value={value} handleChange={handleChange} />
			<div>
				{value === TAB_HEADER_VALUES.OVERVIEW && (
					<OverviewScreen eventId={eventId} />
				)}
				{value === TAB_HEADER_VALUES.SUBSIDY_REQUESTS && (
					<SubsidyScreen eventId={eventId} />
				)}
				{value === TAB_HEADER_VALUES.CASH_REQUESTS && (
					<CashScreen eventId={eventId} />
				)}
				{value === TAB_HEADER_VALUES.REPORTS && (
					<ReportScreen eventId={eventId} />
				)}
				{value === TAB_HEADER_VALUES.FUNDS_OVERVIEW &&
					isGeneralSecratory && <FundScreen eventId={eventId} />}
				{value === TAB_HEADER_VALUES.MANAGE_INVENTORY && (
					<InventoryScreen eventId={eventId} />
				)}
			</div>
		</div>
	)
}

export default withRouter(EventContainer)
