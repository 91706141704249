import { API_URL, METHOD } from '../constants/network'
import { serialize } from '../utils'
import { fetchAPI } from '../utils/network'

export const updateWebUser = (body) => {
	const url = `${API_URL.WEB_USER}`
	return fetchAPI({
		url,
		method: METHOD.POST,
		body,
		isOpenApi: true,
	})
}

export const updateBooking = (body) => {
	const url = `${API_URL.BOOKING}`
	return fetchAPI({
		url,
		method: METHOD.POST,
		body,
		isOpenApi: true,
	})
}

export const createOrder = (body) => {
	const url = `${API_URL.ORDER}`
	return fetchAPI({
		url,
		method: METHOD.POST,
		body,
		isOpenApi: true,
	})
}

export const getBookingDetails = (user_id, eventId) => {
	const url = `${API_URL.BOOKING}${serialize({ user_id, eventId })}`
	return fetchAPI({
		url,
		isOpenApi: true,
	})
}

export const getUserByUid = (uid) => {
	// uid could be either email or user id
	const url = `${API_URL.WEB_USER}${serialize(uid)}`
	return fetchAPI({
		url,
		isOpenApi: true,
	})
}

export const confirmViplavaRegistration = (bookingId) => {
	const url = `${API_URL.CONFIRM_PAYMENT}${serialize({ bookingId })}`
	return fetchAPI({
		url,
		isOpenApi: true,
	})
}

export const raiseSubsidyRequest = (body) => {
	const url = `${API_URL.SUBSIDY_REQUEST}`
	return fetchAPI({
		url,
		body,
		method: METHOD.POST,
		isOpenApi: true,
	})
}

export const getEventDetails = () => {
	const url = API_URL.EVENT
	return fetchAPI({
		url,
		isOpenApi: true,
	})
}

export const getPublicInventoryData = (body) => {
	const url = `${API_URL.WEB_INVENTORY}${serialize(body)}`
	return fetchAPI({
		url,
		method: METHOD.GET,
		body,
		isOpenApi: false,
	})
}
