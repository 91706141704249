import React, { useEffect } from 'react'
import { Button, TextField, Typography } from '@mui/material'
import './style.scss'
import Logo from '../../assets/images/logo.png'
import Registration from '../../assets/images/online-registration.png'
import { validateToken } from '../../services/auth'
import { ROUTE } from '../../constants/route'
import { getToken } from '../../utils'
import { loginAction } from '../../actions/auth'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const LoginContainer = () => {
	const [state, setState] = useState({
		email: '',
		password: '',
	})
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		if (getToken()) {
			validateToken(getToken())
				.then((response) => {
					const userProfile = response?.data[0]
					userProfile.accessToken = getToken()
					userProfile.loggedIn = true
					localStorage.setItem(
						'UserProfile',
						JSON.stringify(userProfile)
					)
					if (
						location.pathname === ROUTE.DASHBOARD.path ||
						location.pathname === ROUTE.LOGIN.path ||
						location.pathname === ROUTE.HOME.path
					)
						navigate(ROUTE.DASHBOARD.path)
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}, [])

	const onChange = (name, value) => {
		setState((state) => ({
			...state,
			[name]: value,
		}))
	}

	const handleLogin = () => {
		const { email, password } = state

		if (email && password) {
			dispatch(loginAction({ email, password })).then((res) => {
				if (res && res.data) {
					const userProfile = res.data[0]
					userProfile.loggedIn = true
					const token = res.data[0]?.token
					localStorage.setItem('Token', token)
					localStorage.setItem(
						'UserProfile',
						JSON.stringify(userProfile)
					)
					navigate(ROUTE.DASHBOARD.path)
				}
			})
		} else {
			alert('Please fill details')
		}
	}

	const { email, password } = state
	return (
		<div className="login-container">
			<div className="greetings">
				<img src={Registration} />
			</div>
			<div className="login-box">
				<div className="img-wrapper">
					<img width="200px" src={Logo} />
				</div>
				<Typography variant="h5">
					Hare Krsna! Let's get started
				</Typography>
				<Typography variant="subtitle2">
					Sign in to continue (Only for admins){' '}
				</Typography>
				<TextField
					className="input-elem"
					required
					label="Email"
					variant="outlined"
					autoComplete="off"
					name="email"
					value={email}
					onChange={(e) => onChange(e.target.name, e.target.value)}
					fullWidth
				/>
				<TextField
					className="input-elem"
					required
					label="Password"
					variant="outlined"
					type="password"
					autoComplete="off"
					name="password"
					value={password}
					onChange={(e) => onChange(e.target.name, e.target.value)}
					fullWidth
				/>
				<Button
					variant="outlined"
					color="primary"
					onClick={handleLogin}
				>
					Login
				</Button>
				<br />
			</div>
		</div>
	)
}

export default LoginContainer
