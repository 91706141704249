import React from "react";
import "./style.scss";

class PrivacyContainer extends React.Component {
	render() {
		return (
			<div className="static-page-container" id="content">
				<div id="placeholders">
					<h1>
						Privacy Policy of{" "}
						<span class="highlight preview_company_name">COASTOK</span>
					</h1>
					<p>
						<span class="highlight preview_company_name">COASTOK</span> operates
						the{" "}
						<span class="highlight preview_website_name">
							<em>COASTOK</em>
						</span>{" "}
						mobile application, which provides the SERVICE.
					</p>
					<p>
						This page is used to inform mobile application visitors regarding
						our policies with the collection, use, and disclosure of Personal
						Information if anyone decided to use our Service, the{" "}
						<span class="highlight preview_website_name">
							<em>coastok.com</em>
						</span>{" "}
						mobile application.
					</p>
					<p>
						If you choose to use our Service, then you agree to the collection
						and use of information in relation with this policy. The Personal
						Information that we collect are used for providing and improving the
						Service. We will not use or share your information with anyone
						except as described in this Privacy Policy.
					</p>
					<p>
						The terms used in this Privacy Policy have the same meanings as in
						our Terms and Conditions, which is accessible at{" "}
						<span class="highlight preview_website_url">
							mobile application URL
						</span>
						, unless otherwise defined in this Privacy Policy.
					</p>
					<h2>Information Collection and Use</h2>
					<p>
						For a better experience while using our Service, we may require you
						to provide us with certain personally identifiable information,
						including but not limited to your name, email address, phone number,
						and postal address. The information that we collect will be used to
						contact or identify you.
					</p>
					<p>
						The information is shared to COASTOK and is only used for
						communication services by the person user choose to converse with.
						This information would never shared to any third party vendor,
						organization, indivdual, or tool. This data would help us to
						identify your identity for the services we provide.{" "}
					</p>
					<ul>
						<li>
							To save the profile images, so that each user gets some
							personalised experience we require permissions for your device
							camera
						</li>
						<li>
							To record your questions or doubts regarding our services, we need
							permissions to access the device audio recorder, so that your
							question can be easily understood and resolved further.
						</li>
					</ul>
					{/* <h2>Log Data</h2>
            <p>We want to inform you that whenever you visit our Service, we collect information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer's Internet Protocol (“IP”) address, browser
                version, pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.</p>
            <h2>Cookies</h2>
            <p>Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser from the mobile application that you visit and are stored on your computer's hard drive.</p>
            <p>Our mobile application uses these “cookies” to collection information and to improve our Service. You have the option to either accept or refuse these cookies, and know when a cookie is being sent to your computer. If you choose to refuse
                our cookies, you may not be able to use some portions of our Service.</p>*/}
					<h2>Service Providers</h2>
					<p>
						We may employ third-party companies and individuals due to the
						following reasons:
					</p>
					<ul>
						<li>To facilitate our Service;</li>
						<li>To provide the Service on our behalf;</li>
						<li>To perform Service-related services; or</li>
						<li>To assist us in analyzing how our Service is used.</li>
					</ul>
					<p>
						We want to inform our Service users that these third parties have
						access to your Personal Information. The reason is to perform the
						tasks assigned to them on our behalf. However, they are obligated
						not to disclose or use the information for any other purpose.
					</p>
					<h2>Security</h2>
					<p>
						We value your trust in providing us your Personal Information, thus
						we are striving to use commercially acceptable means of protecting
						it. But remember that no method of transmission over the internet,
						or method of electronic storage is 100% secure and reliable, and we
						cannot guarantee its absolute security.
					</p>
					<p>
						We use HTTPS and SSL certification to secure all the network
						connections while using our service. This ensures that the data
						should pass through a secured layer and in encrypted format and not
						to be intercepted by any intruder or attack.{" "}
					</p>
					<h2>Links to Other Sites</h2>
					<p>
						Our Service may contain links to other sites. If you click on a
						third-party link, you will be directed to that site. Note that these
						external sites are not operated by us. Therefore, we strongly advise
						you to review the Privacy Policy of these websites. We have no
						control over, and assume no responsibility for the content, privacy
						policies, or practices of any third-party sites or services.
					</p>
					<h2>Children's Privacy</h2>
					<p>
						Our Services do not address anyone under the age of 13. We do not
						knowingly collect personal identifiable information from children
						under 13. In the case we discover that a child under 13 has provided
						us with personal information, we immediately delete this from our
						servers. If you are a parent or guardian and you are aware that your
						child has provided us with personal information, please{" "}
						<u>
							<a href="mailto:coastok@gmail.com">contact us</a>
						</u>{" "}
						so that we will be able to do necessary actions.
					</p>
					<h2>Changes to This Privacy Policy</h2>
					<p>
						We may update our Privacy Policy from time to time. Thus, we advise
						you to review this page periodically for any changes. We will notify
						you of any changes by posting the new Privacy Policy on this page.
						These changes are effective immediately, after they are posted on
						this page.
					</p>
					<h2>Contact Us</h2>
					<p>
						If you feel any violations of rules aforementioned by any of the
						entity, kindly report the issue to the below mentioned details.
					</p>
					<p>
						{" "}
						If you have any questions or suggestions about our Privacy Policy,
						do not hesitate to contact us at{" "}
						<b>
							<u>
								<a href="mailto:coastok@gmail.com">coastok@gmail.com</a>
							</u>
						</b>
					</p>
				</div>
			</div>
		);
	}
}

export default PrivacyContainer;
