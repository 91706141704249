import React from 'react'

const About = () => {
	return (
		<div className='static-page-container'>
			<h1>About COASTOK Mission</h1>
			<p>
				Happiness and the true fulfillment of the soul and unending
				success have been the goals of humanity from eternity. The
				purpose of life is a life of purpose and what makes a life
				valuable is the perfect blend of knowledge, skills and values.
				Most of us simply go through life but in the pursuit of going
				through life, we forget to grow through life. Therefore the
				reality is” one cannot upload love, one can’t download time, one
				can’t google all of the life’s answers therefore one must
				actually live some of his life. COnnect A Soul TO Krishna
				(COASTOK) is an initiative of{' '}
				<a
					href="https://iyfdelhi.com/"
					target="_blank"
					rel="noreferrer"
				>
					ISKCON Youth Forum, Delhi
				</a>
				.
			</p>
			<p>
				COASTOK is aimed towards nourishing the hearts of today's youth
				with the sublime message of the scriptures and helping them
				flourish in their lives in a wholesome manner. Spirit of COASTOK
				For a sincere human being to live a life of pure principles is a
				great challenge. ISKCON youth Forum is serving as an oasis in
				the sense that it is ‘life-giving and transforming’ society in a
				stress –ridden fast-paced rat race society’ that is like a
				desert. Thus COASTOK is committed to provide a facility like an
				Oasis in the middle of a desert to keep oneself surcharged in
				remembrance of the Supreme Lord, Sri Krishna always. The
				inspiration is the spiritual inspiration to practice devotion to
				God with full enthusiasm and developing the spiritual knowledge
				which acts like a beacon in the sea like unending mysteries of
				life. COASTOK is striving towards rekindling the wisdom and
				reviving their loving and serving propensities.
			</p>
			<h3>Mission</h3>
			<p>
				Change is an inevitable part of the life and history has
				witnessed numerous revolutions as part of the change. Most of
				these revolutions were spearheaded and lead by the youths only.
				As India makes rapid advancements in the field of technology and
				is recognized the world over for its vast and untapped
				resources, especially the ‘human’ resource, with 55% of the
				population in the age group of 18-25, the future seems bright.
				But along with this euphoria, there is also a darker side to the
				reality; one that finds today’s youth battling with drug and
				alcoholic addictions, pre and post marital infidelities and
				mental depressions. Therefore COnnect A Soul TO Krishna has
				ventured into wisdom literatures with the desire to bring about
				positive revolutions in the misguided life of modern youths.
			</p>
			<p>
				Now COnnect A Soul TO Krishna has taken a good shape in many
				prestigious cities of India. The goals of COASTOK are as follow:
				Training in soft skills like Public Speaking, Stress management,
				Leadership and Management by the application of the timeless
				wisdom in the modern scenario. Bringing out the potential in the
				individual like Concentration, Motivation, Self Confidence etc.
				Blending the character with competence in bringing about A Long
				Enduring Success. Dealing With Sensitivity And self-awareness In
				Thought, Words And Action Organizing Outing For Experiencing
				Closer Touch With The Nature And The Marvelous Gift Of The
				Supreme Divine.
			</p>
		</div>
	)
}

export default About
