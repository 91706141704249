import { API_URL, METHOD } from '../constants/network';
import { serialize } from '../utils';
import { fetchAPI } from '../utils/network';

export const getQuestions = (params) => {
    const url = API_URL.QUESTION + serialize(params);
    return fetchAPI({
        url,
    })
}

export const updateAnswer = (questionId, body) => {
    return fetchAPI({
        url: `${API_URL.QUESTION}/${questionId}`,
        method: METHOD.PUT,
        body,
    })
}