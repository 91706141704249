import { Button } from '@mui/material'
import { WhatsApp } from '@mui/icons-material'
import React from 'react'

function WhatsAppBtn({ referrer }) {
	const shareClick = () => {
		window.open(
			`https://api.whatsapp.com/send?text=${
				window.location.href?.split('?')[0] + '?referrer=' + referrer
			}`,
			'_blank'
		)
	}
	return (
		<Button
			variant="contained"
			color="secondary"
			className="mb-16"
			onClick={shareClick}
		>
			<WhatsApp /> Share
		</Button>
	)
}

export default WhatsAppBtn
