import React from 'react'
import './style.scss'
import NavbarComponent from '../Navbar'
import { WITHOUT_FOOTER_PAGES } from '../../constants/route'
import { matchPath, useLocation } from 'react-router-dom'

const FooterComponent = (props) => {
	const location = useLocation()
	const match = WITHOUT_FOOTER_PAGES.find((path) => {
		return matchPath(
			{
				path: path,
				exact: true,
			},
			location.pathname
		)
	})
	return (
		<footer
			className="app-footer"
			style={{ display: match ? 'none' : 'flex' }}
		>
			<NavbarComponent {...props} showLogin={false} />
		</footer>
	)
}

export default FooterComponent
