export function getCurrentWeekMonday() {
	const today = new Date() // Current date
	const dayOfWeek = today.getDay() // Day of the week (0 is Sunday, 1 is Monday, etc.)
	const differenceToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1 // Calculate how many days to subtract (Sunday is handled as 6 days before Monday)
	const monday = new Date(today) // Create a new date object for manipulation
	monday.setDate(today.getDate() - differenceToMonday) // Set the date to the previous Monday
	return monday
}

export function getSeventhDate(startDate) {
	const maxEndDate = new Date(startDate)
	maxEndDate.setDate(startDate.getDate() + 7) // Add 7 days to the start date
	return maxEndDate
}

export function getDayFromDate(date) {
	// Create a new Date object with the current date
	const currentDate = new Date(date)

	// Get the day of the week (0 = Sunday, 1 = Monday, etc.)
	const dayOfWeek = currentDate.getDay()

	// Array of day names for reference
	const days = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	]

	// Get the name of the day
	const dayName = days[dayOfWeek]

	return dayName
}

const SadhnaCategory = {
	Total: 'total',
	Sleep: 'sleep',
	Wakeup: 'wakeup',
	Day_rest: 'dayRest',
	Chanting: 'chanting',
	Hearing: 'hearing',
	Reading: 'reading',
	Service: 'service',
	Morning_program: 'morningProgram',
}

const SadhnaGroup = {
	TRD: { v: 0 },
	BSS: { v: 1 },
	SPS: { v: 2 },
}

const MorningProgram = {
	OnTime: { v: 0 },
	Late: { v: 1 },
	Absent: { v: 2 },
	NA: { v: 3 },
}

let level = SadhnaGroup.SPS.v

export const SadhnaMarks = {
	mrkListSleep: () => {
		switch (level) {
			case SadhnaGroup.TRD.v:
				return [
					['22:00', 25],
					['22:05', 20],
					['22:10', 15],
					['22:15', 10],
					['22:20', 5],
					['22:25', 0],
				]
			case SadhnaGroup.BSS.v:
				return [
					['22:00', 25],
					['22:15', 20],
					['22:30', 15],
					['22:45', 10],
					['23:00', 5],
					['23:15', 0],
					['23:30', -5],
				]
			default:
				return [
					['22:15', 25],
					['22:30', 20],
					['22:45', 15],
					['23:00', 10],
					['23:15', 5],
					['23:30', 0],
				]
		}
	},

	mrkListWake: () => {
		switch (level) {
			case SadhnaGroup.TRD.v:
				return [
					['03:45', 25],
					['03:30', 20],
					['03:55', 15],
					['04:00', 10],
					['04:05', 5],
					['04:10', 0],
				]
			case SadhnaGroup.BSS.v:
				return [
					['04:00', 25],
					['04:15', 20],
					['04:30', 15],
					['04:45', 10],
					['05:00', 5],
					['05:15', 0],
					['05:30', -5],
				]
			default:
				return [
					['04:45', 25],
					['05:00', 20],
					['05:15', 15],
					['05:30', 10],
					['05:45', 5],
					['06:00', 0],
				]
		}
	},

	mrkListDayRest: () => {
		switch (level) {
			case SadhnaGroup.TRD.v:
				return [
					[60, 25],
					[70, 20],
					[80, 15],
					[90, 10],
					[100, 5],
					[110, 0],
				]
			default:
				return [
					[60, 25],
					[75, 20],
					[90, 15],
					[105, 10],
					[120, 5],
					[135, 0],
					[150, -5],
				]
		}
	},

	mrkListChantEnd: () => {
		switch (level) {
			case SadhnaGroup.TRD.v:
				return [
					['07:15', 25],
					['11:00', 20],
					['13:00', 15],
					['14:30', 10],
					['17:00', 5],
					['19:00', 0],
				]
			default:
				return [
					['07:15', 25],
					['09:30', 20],
					['13:00', 15],
					['19:00', 10],
					['21:00', 5],
					['23:00', 0],
					['23:30', -5],
				]
		}
	},

	mrkListRead: () => {
		switch (level) {
			case SadhnaGroup.TRD.v:
				return [
					[10, 0],
					[20, 5],
					[30, 10],
					[40, 15],
					[50, 20],
					[60, 25],
				]
			case SadhnaGroup.BSS.v:
				return [
					[5, 0],
					[15, 5],
					[25, 10],
					[35, 15],
					[45, 20],
					[60, 25],
				]
			default:
				return [
					[0, 0],
					[5, 5],
					[10, 10],
					[20, 15],
					[25, 20],
					[30, 25],
					[60, 25],
				]
		}
	},

	mrkListHear: () => {
		switch (level) {
			case SadhnaGroup.TRD.v:
				return [
					[10, 0],
					[20, 5],
					[30, 10],
					[40, 15],
					[50, 20],
					[60, 25],
				]
			case SadhnaGroup.BSS.v:
				return [
					[5, 0],
					[15, 5],
					[25, 10],
					[35, 15],
					[45, 20],
					[60, 25],
				]
			default:
				return [
					[0, 0],
					[5, 5],
					[10, 10],
					[20, 15],
					[25, 20],
					[30, 25],
					[60, 25],
				]
		}
	},

	mrkListService: () => {
		switch (level) {
			case SadhnaGroup.TRD.v:
				return [
					[60, 0],
					[120, 5],
					[180, 10],
					[240, 15],
					[300, 20],
					[360, 25],
				]
			case SadhnaGroup.BSS.v:
				return [
					[10, 0],
					[15, 5],
					[20, 10],
					[30, 15],
					[40, 20],
					[45, 25],
				]
			default:
				return [
					[60, 0],
					[120, 5],
					[180, 10],
					[240, 15],
					[300, 20],
					[360, 25],
				]
		}
	},

	mrkMapMorningProgram: () => {
		switch (level) {
			case SadhnaGroup.TRD.v:
				return {
					[MorningProgram.OnTime.v]: 5,
					[MorningProgram.Late.v]: 3,
					[MorningProgram.Absent.v]: 0,
					[MorningProgram.NA.v]: 0,
				}
			default:
				return {
					[MorningProgram.NA.v]: 0,
				}
		}
	},

	getMaxTotal: () => {
		let maxTotalMarks = 0
		maxTotalMarks += SadhnaMarks.mrkListSleep()[0][1]
		maxTotalMarks += SadhnaMarks.mrkListWake()[0][1]
		maxTotalMarks += SadhnaMarks.mrkListDayRest()[0][1]
		maxTotalMarks += SadhnaMarks.mrkListChantEnd()[0][1]
		maxTotalMarks += SadhnaMarks.mrkListHear().slice(-1)[0][1]
		maxTotalMarks += SadhnaMarks.mrkListRead().slice(-1)[0][1]
		maxTotalMarks += SadhnaMarks.mrkListService().slice(-1)[0][1]
		maxTotalMarks +=
			SadhnaMarks.mrkMapMorningProgram()[MorningProgram.OnTime.v] || 0
		return maxTotalMarks
	},

	getTotalMarks: (data) => {
		let marksTotal = 0
		const sleepMarks = SadhnaMarks.calculateTimeMarks(
			data.sleepTime,
			SadhnaMarks.mrkListSleep(),
			true
		)
		const wakeupMarks = SadhnaMarks.calculateTimeMarks(
			data.wakeTime,
			SadhnaMarks.mrkListWake()
		)
		const dayRestMarks = SadhnaMarks.calculateDurationMarks(
			data.dayRest,
			SadhnaMarks.mrkListDayRest()
		)
		const chantingMarks = SadhnaMarks.calculateTimeMarks(
			data.chantEndTime,
			SadhnaMarks.mrkListChantEnd()
		)
		const hearMarks = SadhnaMarks.calculateDurationMarks(
			data.hearDuration,
			SadhnaMarks.mrkListHear()
		)
		const readingMarks = SadhnaMarks.calculateDurationMarks(
			data.readDuration,
			SadhnaMarks.mrkListRead()
		)
		const serviceMarks = SadhnaMarks.calculateDurationMarks(
			data.serviceDuration,
			SadhnaMarks.mrkListService()
		)
		const morningMarks = SadhnaMarks.calculateMorningProgramMarks(
			data,
			SadhnaMarks.mrkMapMorningProgram()
		)
		const totalMarks =
			sleepMarks +
			wakeupMarks +
			dayRestMarks +
			chantingMarks +
			hearMarks +
			readingMarks +
			serviceMarks +
			morningMarks

		return totalMarks
	},

	getPercentMarks: (data, sadhnaCategory) => {
		switch (sadhnaCategory) {
			case SadhnaCategory.Total:
				return (
					(SadhnaMarks.getTotalMarks(data) * 100) /
					SadhnaMarks.getMaxTotal()
				)

			case SadhnaCategory.Sleep:
				return (
					(SadhnaMarks.calculateTimeMarks(
						data.sleepTime,
						SadhnaMarks.mrkListSleep(),
						true
					) *
						100) /
					SadhnaMarks.mrkListSleep()[0][1]
				)

			case SadhnaCategory.Wakeup:
				return (
					(SadhnaMarks.calculateTimeMarks(
						data.wakeTime,
						SadhnaMarks.mrkListWake()
					) *
						100) /
					SadhnaMarks.mrkListWake()[0][1]
				)

			case SadhnaCategory.Day_rest:
				return (
					(SadhnaMarks.calculateDurationMarks(
						data.dayRest,
						SadhnaMarks.mrkListDayRest()
					) *
						100) /
					SadhnaMarks.mrkListDayRest()[0][1]
				)

			case SadhnaCategory.Chanting:
				return (
					(SadhnaMarks.calculateTimeMarks(
						data.chantEndTime,
						SadhnaMarks.mrkListChantEnd()
					) *
						100) /
					SadhnaMarks.mrkListChantEnd()[0][1]
				)

			case SadhnaCategory.Hearing:
				return (
					(SadhnaMarks.calculateDurationMarks(
						data.hearDuration,
						SadhnaMarks.mrkListHear()
					) *
						100) /
					SadhnaMarks.mrkListHear()[
						SadhnaMarks.mrkListHear().length - 1
					][1]
				)

			case SadhnaCategory.Reading:
				return (
					(SadhnaMarks.calculateDurationMarks(
						data.readDuration,
						SadhnaMarks.mrkListRead()
					) *
						100) /
					SadhnaMarks.mrkListRead()[
						SadhnaMarks.mrkListRead().length - 1
					][1]
				)

			case SadhnaCategory.Service:
				const serviceMax =
					SadhnaMarks.mrkListService()[
						SadhnaMarks.mrkListService().length - 1
					][1]
				return (
					(SadhnaMarks.calculateDurationMarks(
						data.serviceDuration,
						SadhnaMarks.mrkListService()
					) *
						100) /
					(serviceMax === 0 ? 1 : serviceMax)
				)

			case SadhnaCategory.Morning_program:
				const marks = SadhnaMarks.calculateMorningProgramMarks(
					data,
					SadhnaMarks.mrkMapMorningProgram()
				)
				const finalMark =
					(marks * 100) /
					(4 *
						(SadhnaMarks.mrkMapMorningProgram()[
							MorningProgram.OnTime.v
						] || 1))
				return finalMark

			default:
				return 0 // Handle default case if needed
		}
	},

	getIndividualPercentage: (data) => {
		const morningPercentage = SadhnaMarks.getPercentMarks(
			data,
			SadhnaCategory.Morning_program
		)
		const servicePercentage = SadhnaMarks.getPercentMarks(
			data,
			SadhnaCategory.Service
		)
		const readingPercentage = SadhnaMarks.getPercentMarks(
			data,
			SadhnaCategory.Reading
		)
		const hearingPercentage = SadhnaMarks.getPercentMarks(
			data,
			SadhnaCategory.Hearing
		)

		return {
			morningPercentage,
			servicePercentage,
			readingPercentage,
			hearingPercentage,
		}
	},

	getIndividualMarks: (data) => {
		const morningMarks = SadhnaMarks.calculateMorningProgramMarks(
			data,
			SadhnaMarks.mrkMapMorningProgram()
		)
		const serviceMarks = SadhnaMarks.calculateDurationMarks(
			data.serviceDuration,
			SadhnaMarks.mrkListService()
		)
		const readingMarks = SadhnaMarks.calculateDurationMarks(
			data.readDuration,
			SadhnaMarks.mrkListRead()
		)
		const hearingMarks = SadhnaMarks.calculateDurationMarks(
			data.hearDuration,
			SadhnaMarks.mrkListHear()
		)

		return {
			morningMarks,
			serviceMarks,
			readingMarks,
			hearingMarks,
		}
	},

	calculateDurationMarks: (value, marksList) => {
		if (value === null || value === undefined) return 0
		for (let i = 0; i < marksList.length; i++) {
			if (value <= marksList[i][0]) return marksList[i][1]
		}
		return marksList[marksList.length - 1][1]
	},

	calculateTimeMarks: (value, marksList, isSleep = false) => {
		if (!value) return 0
		for (let i = 0; i < marksList.length; i++) {
			if (i + 1 === marksList.length) {
				return marksList.last().second
			}
			if (isSleep && value <= '12:00') return marksList.slice(-1)[0][1]
			if (value <= marksList[i][0]) return marksList[i][1]
		}
		return 0
	},

	calculateMorningProgramMarks: (data, marksMap) => {
		return (
			(marksMap[data.mangalAarti] || 0) +
			(marksMap[data.darshanAarti] || 0) +
			(marksMap[data.morningClass] || 0) +
			(marksMap[data.prasadam] || 0)
		)
	},
}
