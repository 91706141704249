import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import React from 'react'
import { ROUTE } from '../../constants/route'
import { changePassword } from '../../services/auth'
import './style.scss'

class ResetPassword extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			old_password: '',
			password: '',
			showOldPassword: false,
			showPassword: false,
		}
	}

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		})
	}

	handleSubmit = (e) => {
		e.preventDefault()
		const { old_password, password } = this.state
		const payload = {
			old_password,
			password,
		}
		changePassword(payload).then(() => {
			this.props.history.push(ROUTE.DASHBOARD.path)
		})
	}

	render() {
		const { old_password, password, showOldPassword, showPassword } =
			this.state
		return (
            (<form
				noValidate
				onSubmit={this.handleSubmit}
				className="reset-password-container"
			>
                <Card className="reset-card parent">
					<CardHeader
						title="Change Password"
						subheader={
							<ul style={{ marginBottom: 0 }}>
								<li>
									Please use atleast 8 digit long password.
								</li>
								<li>
									You can also use aplhanumeric and special
									characters
								</li>
								<li>
									If you've forgot the password, please share
									the issue with General Secretary.
								</li>
							</ul>
						}
					/>
					<CardContent className="reset-card child">
						<FormControl
							variant="outlined"
							fullWidth
							className="m-bottom-16"
						>
							<InputLabel htmlFor="outlined-adornment-password">
								Old Password
							</InputLabel>
							<OutlinedInput
								id="outlined-adornment-password"
								type={showOldPassword ? 'text' : 'password'}
								value={old_password}
								name="old_password"
								onChange={this.handleChange}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => {
												this.setState(
													({ showOldPassword }) => ({
														showOldPassword:
															!showOldPassword,
													})
												)
											}}
                                            edge="end"
                                            size="large">
											{showOldPassword ? (
												<Visibility />
											) : (
												<VisibilityOff />
											)}
										</IconButton>
									</InputAdornment>
								}
								labelWidth={70}
							/>
						</FormControl>
						<FormControl fullWidth variant="outlined">
							<InputLabel htmlFor="outlined-adornment-password">
								New Password
							</InputLabel>
							<OutlinedInput
								id="outlined-adornment-password"
								type={showPassword ? 'text' : 'password'}
								value={password}
								onChange={this.handleChange}
								name="password"
								endAdornment={
									<InputAdornment position="end">
										<IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => {
												this.setState(
													({ showPassword }) => ({
														showPassword:
															!showPassword,
													})
												)
											}}
                                            edge="end"
                                            size="large">
											{showPassword ? (
												<Visibility />
											) : (
												<VisibilityOff />
											)}
										</IconButton>
									</InputAdornment>
								}
								labelWidth={70}
							/>
						</FormControl>
					</CardContent>
					<CardActions>
						<Button
							type="submit"
							variant="contained"
							color="primary"
						>
							Reset Password
						</Button>
					</CardActions>
				</Card>
            </form>)
        );
	}
}

export default ResetPassword
