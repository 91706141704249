import { Button, Typography } from '@mui/material'
import { format } from 'date-fns'
import React from 'react'
import { EVENT_BOOKING_STATUS } from '../../constants'
import { getBookingStatus } from '../../services/event'
import { exportFromJSON, getAreas } from '../../utils'
import {
	download,
	getAreaLeaders,
	getFacilitators,
	getFrontliners,
} from './helper'
import './style.scss'

export default class ReportContainer extends React.Component {
	render() {
		return (
			<div className="reports-container">
				<div className="item">
					<Typography variant="h5" gutterBottom>
						Download list of all area leaders
					</Typography>
					<Button
						color="primary"
						variant="contained"
						onClick={async () => {
							const response = await getAreaLeaders()
							download(response.data.data)
						}}
					>
						Download
					</Button>
				</div>
				<div className="item">
					<Typography variant="h5" gutterBottom>
						Download list of all facilitators
					</Typography>
					<Button
						color="primary"
						variant="contained"
						onClick={async () => {
							const response = await getFacilitators()
							download(response.data.data)
						}}
					>
						Download
					</Button>
				</div>
				<div className="item">
					<Typography variant="h5" gutterBottom>
						Download list of all frontliners
					</Typography>
					<Button
						color="primary"
						variant="contained"
						onClick={async () => {
							const response = await getFrontliners()
							download(response.data.data)
						}}
					>
						Download
					</Button>
				</div>
				<div className="item">
					<Typography variant="h5" gutterBottom>
						Download Viplava Stats
					</Typography>
					<Button
						color="primary"
						variant="contained"
						onClick={async () => {
							const todayDate = format(new Date(), 'dd-MM-yyyy')
							const areas = getAreas()
							const bookings = await getBookingStatus({
								per_page: 5000,
							})
							const data = areas.map((a) => ({
								Area: a.name,
								INACTIVE: 0,
								PENDING: 0,
								SUBSIDY_REQUESTED: 0,
								SUBSIDY_APPROVED: 0,
								REQUEST_DENIED: 0,
								ORDERED: 0,
								PAYMENT_INPROGRESS: 0,
								CONFIRMED: 0,
								FAILED: 0,
							}))

							bookings.data.data.forEach((b) => {
								const i = data.findIndex(
									(d) => d['Area'] === b.connectedAreaId.name
								)
								if (i >= 0) {
									data[i][EVENT_BOOKING_STATUS[b.status]] += 1
								}
							})
							exportFromJSON({
								data,
								fileName: 'Viplava Report ' + todayDate,
							})
						}}
					>
						Download
					</Button>
				</div>
			</div>
		)
	}
}
