import {
	DELETE_USER_FAILED,
	DELETE_USER_STARTED,
	DELETE_USER_SUCCESS,
	GET_USERS_FAILED,
	GET_USERS_STARTED,
	GET_USERS_SUCCESS,
	UPDATE_USER_FAILED,
	UPDATE_USER_STARTED,
	UPDATE_USER_SUCCESS,
} from '../constants/actions'
import { getUsers, searchUser, updateUser } from '../services/user'
import { extractErrorStr } from '../utils/network'
import { actionCreator } from '../utils/redux'

export const getUsersAction = (params, pageType) => (dispatch) => {
	if (pageType === 'non-coastok' || pageType === 'pending') {
		params.userRole = '0'
	} else if (pageType === 'approved') {
		params.userRole = '1,2,3,4,5,6,7'
	} else if (pageType === 'inactive') {
		params.status = '0'
	} else {
		// all
	}
	dispatch(actionCreator(GET_USERS_STARTED))
	return getUsers(params)
		.then((json) => {
			let users = json.data.data
			if (pageType === 'non-coastok') {
				users = json.data.data.filter((a) => {
					return a.chantingRounds < 4
				})
			} else if (pageType === 'pending') {
				users = json.data.data.filter((a) => {
					return a.chantingRounds >= 4
				})
			}
			dispatch(
				actionCreator(GET_USERS_SUCCESS, users, {
					total_results: users.length,
				})
			)
			return {
				users,
				total_results: json.data?.total_results,
				total_pages: json.data?.total_pages,
			}
		})
		.catch((error) => {
			dispatch(actionCreator(GET_USERS_FAILED, extractErrorStr(error)))
			return error
		})
}

export const searchUserAction = (params, pageType) => (dispatch) => {
	if (pageType === 'non-coastok' || pageType === 'pending') {
		params.userRole = '0'
		params.per_page = 6100
	} else if (pageType === 'approved') {
		params.userRole = '1,2,3,4,5,6,7'
	} else {
		// all
	}
	dispatch(actionCreator(GET_USERS_STARTED))
	return searchUser(params)
		.then((json) => {
			let users = json.data.data
			if (pageType === 'non-coastok') {
				users = json.data.data.filter((a) => {
					return a.chantingRounds < 4
				})
			} else if (pageType === 'pending') {
				users = json.data.data.filter((a) => {
					return a.chantingRounds >= 4
				})
			}
			dispatch(
				actionCreator(GET_USERS_SUCCESS, users, {
					total_results: users.length,
				})
			)
			return users
		})
		.catch((error) => {
			dispatch(actionCreator(GET_USERS_FAILED, extractErrorStr(error)))
			return error
		})
}

export const updateUserAction = (userId, params) => (dispatch) => {
	dispatch(actionCreator(UPDATE_USER_STARTED))
	return updateUser(userId, params)
		.then((json) => {
			let updatedUser = json.data
			dispatch(
				actionCreator(UPDATE_USER_SUCCESS, updatedUser, {
					total_results: json.data.total_results,
				})
			)
			return json
		})
		.catch((error) => {
			dispatch(actionCreator(UPDATE_USER_FAILED, extractErrorStr(error)))
			return error
		})
}

export const deleteUserAction = (userId, params) => (dispatch) => {
	dispatch(actionCreator(DELETE_USER_STARTED))
	return updateUser(userId, params)
		.then((json) => {
			let updatedUser = json.data
			dispatch(
				actionCreator(DELETE_USER_SUCCESS, updatedUser, {
					total_results: json.data.total_results,
				})
			)
			return json
		})
		.catch((error) => {
			dispatch(actionCreator(DELETE_USER_FAILED, extractErrorStr(error)))
			return error
		})
}
