import { API_URL, METHOD } from "../constants/network";
import { serialize } from "../utils";
import { fetchAPI } from "../utils/network";

export const getSummary = (body) => {
	return fetchAPI({
		url: API_URL.SUMMARY + serialize(body),
	});
};

export const validateToken = (token) => {
	return fetchAPI({
		url: API_URL.VALIDATE,
		method: METHOD.GET,
	});
};
