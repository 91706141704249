import { Button, TextField, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'
import { Formik } from 'formik'
import React, { useState } from 'react'
import { ROUTE } from '../../../constants/route'
import { EVENTS_UI_CONFIG } from '../../../data/event'
import useEventIdFromParams from '../../../hooks/useEventIdFromParams'

const ViplavaBridge = ({ eventIdFromProps } = {}) => {
	const eventIdFromParam = useEventIdFromParams()
	const eventId = eventIdFromProps || eventIdFromParam
	const event = EVENTS_UI_CONFIG.find((event) => event.uuid === eventId)

	const [registerElse, setRegisterElse] = useState(false)

	const registerMyself = () => {
		let userProfile = JSON.parse(localStorage.getItem('UserProfile'))
		if (!userProfile) {
			userProfile = JSON.parse(localStorage.getItem('USER_DETAILS'))
		}
		setRegisterElse(false)
		localStorage.setItem(
			'USER_DETAILS',
			JSON.stringify({
				email: userProfile.email,
				name: userProfile.initiatedName || userProfile.name,
				imageUrl: `https://res.cloudinary.com/dm1o3cvik/image/upload/v1725726615/braja/igppfiq29xsdrznyhn6c.png`,
			})
		)
		window.location.href = `${ROUTE.EVENT_REGISTER_PAGE.path}/${eventId}?email=${userProfile.email}`
	}

	return (
		<div className="viplava-page">
			<div className="register-container">
				<Typography variant="h3">Register for {event.title}</Typography>
				<Typography variant="h6">
					You can either register yourself or somebody else. Please
					choose out of the options below.
				</Typography>
				<Button
					color="primary"
					type="submit"
					variant="contained"
					onClick={registerMyself}
				>
					I want to register myself
				</Button>
				<Button
					color="primary"
					type="submit"
					variant="outlined"
					onClick={() => setRegisterElse(true)}
				>
					I want to register somebody else
				</Button>
			</div>
			{registerElse && (
				<Formik
					initialValues={{
						email: '',
					}}
					// validationSchema={basicValidationSchema}
					validate={(values) => {
						const errors = {}
						if (!values.email) {
							errors.email = 'Required'
						} else if (
							!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
								values.email
							)
						) {
							errors.email = 'Invalid email address'
						}
						return errors
					}}
					onSubmit={(values) => {
						localStorage.setItem(
							'USER_DETAILS',
							JSON.stringify({
								email: values.email,
								name: '',
								imageUrl: `https://res.cloudinary.com/dm1o3cvik/image/upload/v1725726615/braja/igppfiq29xsdrznyhn6c.png`,
							})
						)
						window.location.href = `${ROUTE.EVENT_REGISTER_PAGE.path}/${eventId}?email=${values.email}`
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						/* and other goodies */
					}) => (
						<form
							className="register-container"
							onSubmit={handleSubmit}
						>
							<TextField
								className="m-top-12"
								label="Enter Email"
								variant="outlined"
								value={values.email}
								name="email"
								onChange={(e) => {
									handleChange({
										target: {
											name: e.target.name,
											value: e.target.value.toLowerCase(),
										},
									})
								}}
								onBlur={handleBlur}
								fullWidth
								error={touched.email && errors.email}
								helperText={
									touched.email &&
									errors.email && <span>{errors.email}</span>
								}
							/>
							<Button
								color="primary"
								type="submit"
								startIcon={<Search />}
								variant="contained"
							>
								Check email
							</Button>
						</form>
					)}
				</Formik>
			)}
		</div>
	)
}

export default ViplavaBridge
