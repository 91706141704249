import * as React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { LineChart } from '@mui/x-charts/LineChart'

function AreaGradient({ color, id }) {
	return (
		<defs>
			<linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
				<stop offset="0%" stopColor={color} stopOpacity={0.5} />
				<stop offset="100%" stopColor={color} stopOpacity={0} />
			</linearGradient>
		</defs>
	)
}

AreaGradient.propTypes = {
	color: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
}

function getDaysInMonth(month, year) {
	const date = new Date(year, month, 0)
	const monthName = date.toLocaleDateString('en-US', {
		month: 'short',
	})
	const daysInMonth = date.getDate()
	const days = []
	let i = 1
	while (days.length < daysInMonth) {
		days.push(`${monthName} ${i}`)
		i += 1
	}
	return days
}

function getMonthText(monthNumber) {
	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	]

	return monthNames[monthNumber - 1] // monthNumber is 1-based
}

function getLastMonthPercentageChange(monthlySadhnaCounts) {
	const perChange = (
		(monthlySadhnaCounts[monthlySadhnaCounts.length - 2].totalCount -
			monthlySadhnaCounts[monthlySadhnaCounts.length - 3].totalCount) /
		monthlySadhnaCounts[monthlySadhnaCounts.length - 3].totalCount
	).toFixed(2)
	return perChange > 0 ? `+${perChange}` : `-${perChange}`
}

export default function MonthlySadhnaChart({ monthlySadhnaCounts }) {
	const theme = useTheme()

	const xAxisData = monthlySadhnaCounts.map(
		(el) => `${getMonthText(el.month)} ${el.year}`
	)

	const colorPalette = [
		theme.palette.primary.light,
		theme.palette.primary.main,
		theme.palette.primary.dark,
	]

	if (monthlySadhnaCounts.length === 0) {
		return null
	}

	return (
		<Card variant="outlined" sx={{ width: '100%' }}>
			<CardContent>
				<Typography component="h2" variant="subtitle2" gutterBottom>
					Monthly Sadhna Updates
				</Typography>
				<Stack sx={{ justifyContent: 'space-between' }}>
					<Stack
						direction="row"
						sx={{
							alignContent: { xs: 'center', sm: 'flex-start' },
							alignItems: 'center',
							gap: 1,
						}}
					>
						<Typography variant="h4" component="p">
							{
								monthlySadhnaCounts[
									monthlySadhnaCounts.length - 2
								].totalCount
							}
						</Typography>
						<Chip
							size="small"
							color={
								getLastMonthPercentageChange(
									monthlySadhnaCounts
								) > 0
									? 'success'
									: 'error'
							}
							label={`${getLastMonthPercentageChange(
								monthlySadhnaCounts
							)}%`}
						/>
					</Stack>
					<Typography
						variant="caption"
						sx={{ color: 'text.secondary' }}
					>
						Sadhna updates last month
					</Typography>
				</Stack>
				<LineChart
					colors={colorPalette}
					xAxis={[
						{
							scaleType: 'point',
							data: xAxisData,
							tickInterval: (index, i) => (i + 1) % 5 === 0,
						},
					]}
					series={[
						{
							id: 'direct',
							label: 'Total Monthly Count',
							showMark: false,
							curve: 'linear',
							stack: 'total',
							area: true,
							stackOrder: 'ascending',
							data: monthlySadhnaCounts.map(
								(el) => el.totalCount
							),
						},
					]}
					height={250}
					margin={{ left: 50, right: 20, top: 20, bottom: 20 }}
					grid={{ horizontal: true }}
					sx={{
						'& .MuiAreaElement-series-organic': {
							fill: "url('#organic')",
						},
						'& .MuiAreaElement-series-referral': {
							fill: "url('#referral')",
						},
						'& .MuiAreaElement-series-direct': {
							fill: "url('#direct')",
						},
					}}
					slotProps={{
						legend: {
							hidden: true,
						},
					}}
				>
					<AreaGradient
						color={theme.palette.primary.dark}
						id="direct"
					/>
					{/* <AreaGradient
						color={theme.palette.primary.main}
						id="referral"
					/>
					<AreaGradient
						color={theme.palette.primary.light}
						id="direct"
					/> */}
				</LineChart>
			</CardContent>
		</Card>
	)
}
