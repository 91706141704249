import {
	GET_EVENT_DATA_FAILED,
	GET_EVENT_DATA_STARTED,
	GET_EVENT_DATA_SUCCESS,
	GET_SUBSIDY_LIST_FAILED,
	GET_SUBSIDY_LIST_STARTED,
	GET_SUBSIDY_LIST_SUCCESS,
	GET_CASH_LIST_FAILED,
	GET_CASH_LIST_STARTED,
	GET_CASH_LIST_SUCCESS,
	GET_FUND_BALANCE_FAILED,
	GET_FUND_BALANCE_STARTED,
	GET_FUND_BALANCE_SUCCESS,
	APPROVE_SUBSIDY_FAILED,
	APPROVE_SUBSIDY_STARTED,
	APPROVE_SUBSIDY_SUCCESS,
	APPROVE_CASH_FAILED,
	APPROVE_CASH_STARTED,
	APPROVE_CASH_SUCCESS,
	REJECT_SUBSIDY_FAILED,
	REJECT_SUBSIDY_STARTED,
	REJECT_SUBSIDY_SUCCESS,
	GET_INVENTORY_DATA_STARTED,
	GET_INVENTORY_DATA_SUCCESS,
	CREATE_INVENTORY_DATA_STARTED,
	CREATE_INVENTORY_DATA_SUCCESS,
	CREATE_INVENTORY_DATA_FAILURE,
	GET_INVENTORY_DATA_FAILURE,
	GET_ATTENDANCE_QRS_STARTED,
	GET_ATTENDANCE_QRS_SUCCESS,
	GET_ATTENDANCE_QRS_FAILURE,
	CREATE_ATTENDANCE_QR_STARTED,
	CREATE_ATTENDANCE_QR_SUCCESS,
	CREATE_ATTENDANCE_QR_FAILURE,
	GET_USER_ATTENDANCE_STARTED,
	GET_USER_ATTENDANCE_FAILURE,
	GET_USER_ATTENDANCE_SUCCESS,
} from '../constants/actions'
import {
	approveCashRequest,
	approveSubsidyRequest,
	createAttendanceQR,
	createInventryData,
	getAttendanceQRData,
	getCashList,
	getFundBalance,
	getInventoryData,
	getSubsidyList,
	getUserAttendanceList,
	rejectSubsidyRequest,
	updateInventryData,
} from '../services/event'
import { getEventDetails } from '../services/viplava'
import { getAreas } from '../utils'
import { extractErrorStr } from '../utils/network'
import { actionCreator } from '../utils/redux'

export const getEventDetailsAction = (params) => (dispatch) => {
	dispatch(actionCreator(GET_EVENT_DATA_STARTED))
	return getEventDetails(params)
		.then((json) => {
			dispatch(
				actionCreator(
					GET_EVENT_DATA_SUCCESS,
					json?.result?.length ? json?.result : [json?.result]
				)
			)
			return json
		})
		.catch((error) => {
			dispatch(
				actionCreator(GET_EVENT_DATA_FAILED, extractErrorStr(error))
			)
			return error
		})
}

export const getSubsidyListAction = (params) => (dispatch) => {
	dispatch(actionCreator(GET_SUBSIDY_LIST_STARTED))
	return getSubsidyList(params)
		.then((json) => {
			dispatch(actionCreator(GET_SUBSIDY_LIST_SUCCESS, json.data))
			return json
		})
		.catch((error) => {
			dispatch(
				actionCreator(GET_SUBSIDY_LIST_FAILED, extractErrorStr(error))
			)
			return error
		})
}

export const getCashListAction = (params) => (dispatch) => {
	dispatch(actionCreator(GET_CASH_LIST_STARTED))
	return getCashList(params)
		.then((json) => {
			dispatch(actionCreator(GET_CASH_LIST_SUCCESS, json.data))
			return json
		})
		.catch((error) => {
			dispatch(
				actionCreator(GET_CASH_LIST_FAILED, extractErrorStr(error))
			)
			return error
		})
}

export const getFundBalanceAction = (params) => (dispatch) => {
	dispatch(actionCreator(GET_FUND_BALANCE_STARTED))
	const area = getAreas()[0]
	const areaId = params || area?._id
	return getFundBalance(areaId)
		.then((json) => {
			dispatch(actionCreator(GET_FUND_BALANCE_SUCCESS, json.data))
			return json
		})
		.catch((error) => {
			dispatch(
				actionCreator(GET_FUND_BALANCE_FAILED, extractErrorStr(error))
			)
			return error
		})
}

export const approveSubsidyRequestAction =
	({ id, amountAfterSubsidy, comments, subsidy }) =>
	(dispatch) => {
		dispatch(actionCreator(APPROVE_SUBSIDY_STARTED))
		return approveSubsidyRequest({ id, amountAfterSubsidy, comments })
			.then((json) => {
				dispatch(
					actionCreator(APPROVE_SUBSIDY_SUCCESS, { id, subsidy })
				)
				return json
			})
			.catch((error) => {
				dispatch(
					actionCreator(
						APPROVE_SUBSIDY_FAILED,
						extractErrorStr(error)
					)
				)
				return error
			})
	}

export const approveCashRequestAction =
	({ id, cashRecieved }) =>
	(dispatch) => {
		dispatch(actionCreator(APPROVE_CASH_STARTED))
		return approveCashRequest({ id, cashRecieved })
			.then((json) => {
				dispatch(actionCreator(APPROVE_CASH_SUCCESS, { id }))
				return json
			})
			.catch((error) => {
				dispatch(
					actionCreator(APPROVE_CASH_FAILED, extractErrorStr(error))
				)
				return error
			})
	}

export const rejectSubsidyRequestAction =
	({ id, comments }) =>
	(dispatch) => {
		dispatch(actionCreator(REJECT_SUBSIDY_STARTED))
		return rejectSubsidyRequest({ id, comments })
			.then((json) => {
				dispatch(actionCreator(REJECT_SUBSIDY_SUCCESS, { id }))
				return json
			})
			.catch((error) => {
				dispatch(
					actionCreator(REJECT_SUBSIDY_FAILED, extractErrorStr(error))
				)
				return error
			})
	}

export const getInventoryRequestAction =
	({ id, eventId, accomodationCategory, page, per_page, ...rest }) =>
	(dispatch) => {
		dispatch(actionCreator(GET_INVENTORY_DATA_STARTED))
		return getInventoryData({
			id,
			eventId,
			accomodationCategory,
			page,
			per_page,
			...rest,
		})
			.then((json) => {
				dispatch(
					actionCreator(GET_INVENTORY_DATA_SUCCESS, {
						inventoryData: json.data,
					})
				)
				return json
			})
			.catch((error) => {
				dispatch(
					actionCreator(
						GET_INVENTORY_DATA_FAILURE,
						extractErrorStr(error)
					)
				)
				return error
			})
	}

export const createOrUpdateInventoryRequestAction =
	({ id, inventoryData }) =>
	(dispatch) => {
		dispatch(actionCreator(CREATE_INVENTORY_DATA_STARTED))
		const createOrUpdateInventryData = id
			? updateInventryData
			: createInventryData
		console.log({ id })
		return createOrUpdateInventryData(inventoryData, id)
			.then((json) => {
				dispatch(
					actionCreator(CREATE_INVENTORY_DATA_SUCCESS, {
						inventoryData: json.data,
					})
				)
				return json
			})
			.catch((error) => {
				dispatch(
					actionCreator(
						CREATE_INVENTORY_DATA_FAILURE,
						extractErrorStr(error)
					)
				)
				return error
			})
	}

export const getAttendanceQRsRequestAction =
	({ id, page, per_page, status, ...rest }) =>
	(dispatch) => {
		dispatch(actionCreator(GET_ATTENDANCE_QRS_STARTED))
		return getAttendanceQRData({
			id,
			status,
			page,
			per_page,
			...rest,
		})
			.then((json) => {
				dispatch(
					actionCreator(GET_ATTENDANCE_QRS_SUCCESS, {
						data: json.data,
					})
				)
				return json
			})
			.catch((error) => {
				dispatch(
					actionCreator(
						GET_ATTENDANCE_QRS_FAILURE,
						extractErrorStr(error)
					)
				)
				return error
			})
	}

export const getUserAttendanceRequestAction =
	({ id, eventId, accomodationCategory, page, per_page, ...rest }) =>
	(dispatch) => {
		dispatch(actionCreator(GET_USER_ATTENDANCE_STARTED))
		return getUserAttendanceList({
			id,
			eventId,
			accomodationCategory,
			page,
			per_page,
			...rest,
		})
			.then((json) => {
				dispatch(
					actionCreator(GET_USER_ATTENDANCE_SUCCESS, {
						data: json.data,
					})
				)
				return json
			})
			.catch((error) => {
				dispatch(
					actionCreator(
						GET_USER_ATTENDANCE_FAILURE,
						extractErrorStr(error)
					)
				)
				return error
			})
	}

export const createOrUpdateAttendanceQRRequestAction =
	({ id, data }) =>
	(dispatch) => {
		dispatch(actionCreator(CREATE_ATTENDANCE_QR_STARTED))
		const createOrUpdateInventryData = id
			? createAttendanceQR
			: createAttendanceQR
		return createOrUpdateInventryData(data, id)
			.then((json) => {
				dispatch(
					actionCreator(CREATE_ATTENDANCE_QR_SUCCESS, {
						data: json.data,
					})
				)
				return json
			})
			.catch((error) => {
				dispatch(
					actionCreator(
						CREATE_ATTENDANCE_QR_FAILURE,
						extractErrorStr(error)
					)
				)
				return error
			})
	}
