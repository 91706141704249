import React from 'react'
import { connect } from 'react-redux'
import {
	Button,
	Chip,
	IconButton,
	InputAdornment,
	Modal,
	Paper,
	TextField,
	Popover,
	Slider,
	Typography,
} from '@mui/material'

import PopupState, {
	bindTrigger,
	bindPopover,
	bindToggle,
} from 'material-ui-popup-state'
import { Autocomplete } from '@mui/lab'
import { DataGrid, GridSearchIcon } from '@mui/x-data-grid'
import { format, parseISO } from 'date-fns'
import {
	Delete,
	Edit,
	Visibility,
	CloudDownload,
	Apartment,
	Face,
	Tune,
	CheckCircleOutline,
	CheckCircleRounded,
} from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import UpdateUserPopup from './UpdateUser'
import { closeDialog, showDialog } from '../../actions'
import { getUsersAction } from '../../actions/user'
import { CLASS_LEVEL, GENDER, MARITAL_STATUS, USER_ROLE } from '../../constants'
import { ROUTE } from '../../constants/route'
import Tooltip from '@mui/material/Tooltip'
import { getAreaDetails } from '../../services/area'
import { updateUserAction, deleteUserAction } from '../../actions/user'
import './style.scss'
import {
	exportFromJSON,
	getCurrentUser,
	isAreaLeader,
	isAsstDirector,
	isDirector,
	isGenSecretary,
	isMobileScreen,
} from '../../utils'
import { getUsers } from '../../services/user'
import { buildSearchParam } from '../../utils/index'
import store from '../../store'
import { HIDE_LOADER, SHOW_LOADER } from '../../constants/actions'
import { withRouter } from '../../hooks/withRouter'
import { CustomDataGrid } from '../../components/CustomTable/CustomDataGrid'

const PREFIX = 'index'

const classes = {
	tableHeader: `${PREFIX}-tableHeader`,
}

const Root = styled('div')({
	[`& .${classes.tableHeader}`]: {
		padding: '20px',
		marginBottom: '20px',
	},
})

function ValueLabelComponent(props) {
	const { children, value } = props

	return (
		<Tooltip
			enterTouchDelay={0}
			placement="top"
			title={value === 17 ? '>16' : value}
		>
			{children}
		</Tooltip>
	)
}

class UserContainer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			modalData: null,
			filters: {
				pageSize: 10,
				page: 0,
				userRole:
					props.router.location.pathname === ROUTE.PENDING_USER.path
						? 0
						: undefined,
				status:
					props.router.location.pathname === ROUTE.INACTIVE_USER.path
						? 0
						: undefined,
			},
			total_results: 0,
			total_pages: 0,
			areaOptions: [],
			sliderValue: [0, 17],
			filterConnectedArea: {},
		}
		this.isMobile = isMobileScreen()
	}

	componentDidMount() {
		const { _id, userRole } = getCurrentUser()
		this.handleUserData()
		getAreaDetails({ per_page: 100 }).then((res) => {
			this.setState({
				areaOptions: res.data.data,
			})
		})
		getUsers({
			per_page: 500,
			userRole: 3,
			areaLeader: userRole === 4 ? _id : undefined,
		}).then((res) => {
			this.setState({
				facilitatorOptions: res.data.data.map((a) => ({
					...a,
					name: a.initiatedName || a.name,
				})),
			})
		})
		getUsers({
			per_page: 500,
			userRole: 2,
			areaLeader: userRole === 4 ? _id : undefined,
		}).then((res) => {
			this.setState({
				frontlinerOptions: res.data.data.map((a) => ({
					...a,
					name: a.initiatedName || a.name,
				})),
			})
		})
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.router?.params?.pageType !==
			this.props.router?.params?.pageType
		) {
			this.handleUserData()
			this.setState({
				searchValue: '',
			})
		}
	}

	viewUser = (row) => {
		this.setState({
			modalData: row,
			modalType: 'VIEW',
		})
	}

	editUser = (row) => {
		this.setState({
			modalData: row,
			modalType: 'EDIT',
		})
	}

	onModalClose = () => {
		this.setState({
			modalData: null,
		})
	}

	marks = [
		{
			value: 0,
			label: '0',
		},
		{
			value: 1,
			label: '1',
		},
		{
			value: 2,
			label: '2',
		},
		{
			value: 3,
			label: '3',
		},
		{
			value: 4,
			label: '4',
		},
		{
			value: 5,
			label: '5',
		},
		{
			value: 6,
			label: '6',
		},
		{
			value: 7,
			label: '7',
		},
		{
			value: 8,
			label: '8',
		},
		{
			value: 9,
			label: '9',
		},
		{
			value: 10,
			label: '10',
		},
		{
			value: 11,
			label: '11',
		},
		{
			value: 12,
			label: '12',
		},
		{
			value: 13,
			label: '13',
		},
		{
			value: 14,
			label: '14',
		},
		{
			value: 15,
			label: '15',
		},
		{
			value: 16,
			label: '16',
		},
		{
			value: 17,
			label: ' >16',
		},
	]

	handleUserData = (pageChangeParams) => {
		const {
			router: {
				params: { pageType },
			},
			getUsersAction,
			userProfile,
		} = this.props
		const { filters, searchValue, filterConnectedArea, filterFacilitator } =
			this.state
		const params = {
			per_page: pageChangeParams?.pageSize || filters.pageSize,
			page: pageChangeParams?.page + 1 || 1,
		}
		const user =
			userProfile || JSON.parse(localStorage.getItem('UserProfile'))
		if (isAreaLeader(user)) {
			// area leader
			// find members under his area
			params.areaLeader = user?._id
		} else if (isAsstDirector(user)) {
			// assistant Director
			params.assistantDirector = user?._id
		} else {
			// do nothing
		}

		getUsersAction(
			{
				...filters,
				...params,
				...buildSearchParam(searchValue),
				connectedAreaId: filterConnectedArea?._id || '',
				facilitator: filterFacilitator?._id || '',
				page: filters.page + 1,
				per_page: filters.pageSize,
			},
			pageType
		).then((res) => {
			this.setState((state) => ({
				...state,
				filters: {
					...state.filters,
					total_results: res.total_results,
					total_pages: res.total_pages,
				},
			}))
		})
	}

	deleteUser = (user) => {
		if (user._id) {
			this.props.showDialog({
				title: 'Delete this User?',
				description:
					"Are you sure to delete this User? This action won't be reverted",
				actions: [
					{
						action: this.props.closeDialog,
						text: 'Cancel',
						color: 'secondary',
					},
					{
						action: () => {
							this.props
								.deleteUserAction(user._id, {
									status: 0,
								})
								.then(() => {
									this.props.closeDialog()
								})
						},
						text: 'Yes, Delete User',
					},
				],
			})
		}
	}

	downloadData = (users) => {
		const fileName = 'users'
		exportFromJSON({
			data: users
				.filter((user) => {
					return this.state.searchValue
						? this.searchUserRow(user, this.state.searchValue)
						: true
				})
				.map((user) => ({
					Name: user.initiatedName || user.name,
					Email: user.email,
					Phone: user.phone,
					'Date of birth': user.dob,
					Facilitator:
						user?.facilitator?.initiatedName ||
						user?.facilitator?.name,
					Frontliner:
						user?.frontliner?.initiatedName ||
						user?.frontliner?.name,
					'Expertise Area': user.expertiseArea,
					Profession: user.profession,
					'Is Facilitator?': user.roles.includes(3) ? 'Yes' : 'No',
					'Is Frontliner?': user.roles.includes(2) ? 'Yes' : 'No',
					'Is Approved Member?': user.roles.includes(1)
						? 'Yes'
						: 'No',
					'Is Non Coastok?': user.userRole === 0,
					Chanting: user.chantingRounds,
					'Class Level': CLASS_LEVEL[user.sadhnaGroup],
					Gender: GENDER[user.gender],
					'Connected by': user.mentorData?.name,
					'Connected since': user.createdAt,
					'Area Leader':
						user?.areaLeader?.initiatedName ||
						user?.areaLeader?.name,
					'Assistant Director':
						user?.assistantDirector?.initiatedName ||
						user?.assistantDirector?.name,
					'Connected Area': user.connectedAreaId?.name,
				})),
			fileName,
		})
	}
	download = () => {
		const {
			router: {
				params: { pageType },
			},
		} = this.props
		const { filters } = this.state
		const params = {
			pageType,
			...filters,
			connectedAreaId: this.state.filterConnectedArea?._id,
			facilitator: this.state.filterFacilitator?._id,
			page: 1,
			per_page: 30000,
		}
		if (pageType === 'non-coastok' || pageType === 'pending') {
			params.userRole = '0'
		} else if (pageType === 'approved') {
			params.userRole = '1,2,3,4,5,6,7'
		} else if (pageType === 'inactive') {
			params.status = '0'
		}
		store.dispatch({ type: SHOW_LOADER })
		getUsers(params)
			.then((json) => {
				store.dispatch({ type: HIDE_LOADER })
				let users = json.data.data
				if (pageType === 'non-coastok') {
					users = json.data.data.filter((a) => {
						return a.chantingRounds < 4
					})
				} else if (pageType === 'pending') {
					users = json.data.data.filter((a) => {
						return a.chantingRounds >= 4
					})
				}
				this.downloadData(users)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	applyFilter = () => {
		console.log(this.state.filterConnectedArea, this.state.sliderValue)
	}

	handleSearch = (searchString) => {
		this.setState(
			{
				searchValue: searchString,
			},
			() => {
				this.handleUserData()
			}
		)
	}

	searchUserRow = (user, searchValue) => {
		const searchValueLc = searchValue?.toLowerCase()
		const userName = user.name?.toLowerCase()
		const userIName = user.initiatedName?.toLowerCase()
		const userArea = user.connectedAreaId?.name?.toLowerCase()
		const userPhone = user.phone?.toLowerCase()
		const userEmail = user.email?.toLowerCase()
		if (
			userName?.includes(searchValueLc) ||
			userIName?.includes(searchValueLc) ||
			userArea?.includes(searchValueLc) ||
			userPhone?.includes(searchValueLc) ||
			userEmail?.includes(searchValueLc)
		) {
			return true
		}
		return false
	}

	approveUser = (user) => {
		const { updateUserAction, showDialog, closeDialog } = this.props
		if (user._id) {
			//approve user
			showDialog({
				title: 'Approve this User?',
				description:
					"Are you sure to approve this User? This action won't be reverted",
				actions: [
					{
						action: this.props.closeDialog,
						text: 'Cancel',
						color: 'secondary',
					},
					{
						action: () => {
							updateUserAction(user._id, {
								userRole: 1,
							}).then(() => {
								closeDialog()
							})
						},
						text: 'Yes, Approve User',
					},
				],
			})
		}
	}

	activateUser = (user) => {
		const { updateUserAction, showDialog, closeDialog } = this.props
		if (user._id) {
			//activate user
			showDialog({
				title: 'Activate this User?',
				description:
					"Are you sure to activate this User? This action won't be reverted",
				actions: [
					{
						action: this.props.closeDialog,
						text: 'Cancel',
						color: 'secondary',
					},
					{
						action: () => {
							updateUserAction(user._id, {
								status: 1,
							}).then(() => {
								closeDialog()
							})
						},
						text: 'Yes, Activate User',
					},
				],
			})
		}
	}

	onPageSizeChange = ({ page, pageSize }) => {
		this.setState(
			(state) => ({
				...state,
				filters: {
					...this.state.filters,
					pageSize,
					page,
				},
			}),
			() => {
				this.handleUserData()
			}
		)
	}

	populateData = (users, isEditAccess) => {
		const user = getCurrentUser()
		const rowWidthConfig = {
			name: { default: { flex: 0.2 }, mobile: { width: 150 } },
			email: { default: { flex: 0.25 }, mobile: { width: 150 } },
			phone: { default: { width: 130 } },
			facilitatorName: { default: { flex: 0.2 }, mobile: { width: 150 } },
			connectedArea: { default: { flex: 0.3 }, mobile: { width: 150 } },
			chantingRounds: { default: { flex: 0.15 }, mobile: { width: 100 } },
			user: {
				default: {
					width: 220,
				},
			},
		}

		const headCells = [
			{
				field: 'name',
				numeric: false,
				headerName: 'Name',
				sortable: false,
			},
			{
				field: 'email',
				numeric: false,
				headerName: 'Email',
				sortable: false,
			},
			{
				field: 'phone',
				numeric: false,
				headerName: 'Phone',
				sortable: false,
			},
			{
				field: 'facilitatorName',
				numeric: false,
				headerName: 'Facilitator',
				sortable: false,
			},
			{
				field: 'chantingRounds',
				numeric: false,
				headerName: 'Chanting Rounds',
				sortable: true,
			},
			{
				field: 'connectedArea',
				numeric: false,
				headerName: 'Area',
				sortable: false,
			},
			{
				field: 'user',
				headerName: 'Actions',
				sortable: false,
				filterable: false,
				renderCell: (params) => (
					<strong>
						<IconButton
							onClick={() => this.viewUser(params.value)}
							aria-label="View"
							color="primary"
							title="View"
							size="large"
						>
							<Visibility />
						</IconButton>
						<IconButton
							onClick={() => this.editUser(params.value)}
							aria-label="Edit"
							color="primary"
							title="Edit"
							size="large"
						>
							<Edit />
						</IconButton>
						{this.props.router?.location.pathname ===
							ROUTE.PENDING_USER.path && (
							<IconButton
								onClick={() => this.approveUser(params.value)}
								aria-label="Approve"
								color="primary"
								title="Approve"
								size="large"
							>
								<CheckCircleOutline />
							</IconButton>
						)}
						{this.props.router?.location.pathname ===
							ROUTE.INACTIVE_USER.path && (
							<IconButton
								onClick={() => this.activateUser(params.value)}
								aria-label="Activate"
								color="primary"
								title="Activate"
								size="large"
							>
								<CheckCircleRounded />
							</IconButton>
						)}
						<IconButton
							onClick={() => this.deleteUser(params.value)}
							aria-label="Delete"
							color="primary"
							title="Delete"
							size="large"
						>
							<Delete />
						</IconButton>
					</strong>
				),
			},
		]
		const data = [
			{
				btnLabel: 'View',
				btnClick: this.viewUser,
			},
		]
		if (isEditAccess) {
			data.push({
				btnLabel: 'Edit',
				btnClick: this.editUser,
			})
		}
		const {
			loading,
			classes,
			router: {
				params: { pageType },
			},
		} = this.props
		const {
			modalData,
			modalType,
			areaOptions,
			frontlinerOptions,
			facilitatorOptions,
		} = this.state

		const rows = users.map((user) => ({
			id: user._id,
			email: user.email,
			phone: user.phone,
			name: user.initiatedName || user.name,
			connectedArea: user?.connectedAreaId?.name,
			chantingRounds: user?.chantingRounds,
			facilitatorName: user?.facilitatorName,
			user,
		}))

		return (
			<Root className="user-container">
				{modalData && (
					<Modal open={!!modalData} onClose={this.onModalClose}>
						{modalType === 'VIEW' ? (
							<div className="c-modal user-card">
								<div className="labels">
									{modalData?.connectedAreaId?.name && (
										<Chip
											icon={<Apartment />}
											color="secondary"
											label={
												modalData?.connectedAreaId?.name
											}
										/>
									)}
									{typeof modalData.gender === 'number' && (
										<Chip
											icon={<Face />}
											color="secondary"
											label={GENDER[modalData.gender]}
										/>
									)}
									{typeof modalData.maritalStatus ===
										'number' && (
										<Chip
											color="secondary"
											label={
												MARITAL_STATUS[
													modalData.maritalStatus
												]
											}
										/>
									)}
								</div>
								<Paper
									variant="outlined"
									className="user-details"
								>
									<div className="profile-image">
										<img
											src={modalData.imageUrl}
											alt="profile_image"
											referrerpolicy="no-referrer"
										/>
									</div>
									<div className="profile-details">
										<p>
											<b>
												{modalData.initiatedName ||
													modalData.name}
											</b>
										</p>
										{typeof modalData.userRole ===
											'number' && (
											<p>
												{USER_ROLE[modalData.userRole]}
											</p>
										)}
										<p>{modalData.email}</p>
										{modalData.phone &&
											modalData.phone !== '0' && (
												<p>{modalData.phone}</p>
											)}
										<p>{`Facilitator: ${
											(modalData.facilitator &&
												(modalData.facilitator
													.initiatedName ||
													modalData.facilitator
														.name)) ||
											'Unknown'
										}`}</p>
										<p>
											Chanting {modalData.chantingRounds}{' '}
											rounds
										</p>
										{modalData.dob && (
											<p>
												Date of Birth:{' '}
												{new Date(
													modalData.dob
												).toDateString()}
											</p>
										)}
									</div>
								</Paper>
								<div className="profile-footer">
									{
										<div>{`Connected since ${
											modalData.connectedSince
												? format(
														parseISO(
															modalData.connectedSince
														),
														'dd MMM yyyy'
												  )
												: 'is not known.'
										}`}</div>
									}
									{modalData.chantingTarget2 && (
										<div>{`Reached 2 Rounds target on ${format(
											parseISO(modalData.chantingTarget2),
											'dd MMM yyyy'
										)}`}</div>
									)}
									{modalData.chantingTarget4 && (
										<div>{`Reached 4 Rounds target on ${format(
											parseISO(modalData.chantingTarget4),
											'dd MMM yyyy'
										)}`}</div>
									)}
									{modalData.expertiseArea && (
										<div>{`Expertise in ${modalData.expertiseArea}`}</div>
									)}
									{modalData.profession && (
										<div>{`${modalData.profession} by Profession`}</div>
									)}
								</div>
							</div>
						) : (
							<div className="c-modal">
								<UpdateUserPopup
									userId={modalData._id}
									user={modalData}
									showApprove={pageType === 'pending'}
									closeHandler={this.onModalClose}
									updateUserTableDataHandler={
										this.handleUserData
									}
								/>
							</div>
						)}
					</Modal>
				)}
				<Paper className="p-3">
					<div className="table-header-container">
						<TextField
							id="input-with-icon-textfield"
							label="Search"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<GridSearchIcon />
									</InputAdornment>
								),
							}}
							onChange={(e) => this.handleSearch(e.target.value)}
							autoComplete="off"
							value={this.state.searchValue}
							className={'search-field'}
						/>
						<div>
							<PopupState
								variant="popover"
								popupId="filter-popup-popover"
							>
								{(popupState) => (
									<>
										<Button
											startIcon={<Tune />}
											color="primary"
											variant="contained"
											{...bindTrigger(popupState)}
										>
											Filter
										</Button>
										<Popover
											{...bindPopover(popupState)}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'center',
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'center',
											}}
										>
											<div style={{ padding: 15 }}>
												<h2>Filter</h2>
												<Typography
													id="range-slider"
													gutterBottom
												>
													Chanting Round
												</Typography>

												<Slider
													value={
														this.state.sliderValue
													}
													onChange={(
														event,
														sliderValue
													) => {
														this.setState({
															sliderValue,
														})
													}}
													min={0}
													step={1}
													max={17}
													valueLabelDisplay="auto"
													aria-labelledby="range-slider"
													getAriaValueText={(value) =>
														value
													}
													getAriaLabel={(value) =>
														value === 17
															? ' >' + 16
															: value
													}
													ValueLabelComponent={
														ValueLabelComponent
													}
													marks={this.marks}
												/>

												{(isGenSecretary(user) ||
													isDirector(user)) && (
													<Autocomplete
														className="m-top-16"
														options={areaOptions}
														getOptionLabel={(
															option
														) => option.name || ''}
														filterSelectedOptions
														onChange={(
															e,
															filterConnectedArea
														) => {
															this.setState(
																{
																	filterConnectedArea,
																},
																() => {
																	this.handleUserData()
																}
															)
														}}
														value={
															this.state
																.filterConnectedArea
														}
														renderInput={(
															params
														) => (
															<TextField
																{...params}
																variant="outlined"
																label="Connected Area"
																placeholder="Start typing Area Name"
															/>
														)}
													/>
												)}
												<Autocomplete
													className="m-top-16"
													options={facilitatorOptions}
													getOptionLabel={(option) =>
														option.name || ''
													}
													filterSelectedOptions
													onChange={(
														e,
														filterFacilitator
													) => {
														this.setState(
															{
																filterFacilitator,
															},
															() => {
																this.handleUserData()
															}
														)
													}}
													value={
														this.state
															.filterFacilitator
													}
													renderInput={(params) => (
														<TextField
															{...params}
															variant="outlined"
															label="Facilitator"
															placeholder="Start typing Facilitator name"
														/>
													)}
												/>
												<Autocomplete
													className="m-top-16"
													options={frontlinerOptions}
													getOptionLabel={(option) =>
														option.name || ''
													}
													filterSelectedOptions
													onChange={(
														e,
														filterFrontliner
													) => {
														this.setState({
															filterFrontliner,
														})
													}}
													value={
														this.state
															.filterFrontliner
													}
													renderInput={(params) => (
														<TextField
															{...params}
															variant="outlined"
															label="Frontliner"
															placeholder="Start typing Frontliner Name"
														/>
													)}
												/>
												<div
													style={{
														display: 'flex',
														justifyContent:
															'flex-end',
														marginTop: 14,
													}}
												>
													<Button
														color="primary"
														variant="contained"
														{...bindToggle(
															popupState
														)}
													>
														OK
													</Button>
												</div>
											</div>
										</Popover>
									</>
								)}
							</PopupState>
							&nbsp; &nbsp; &nbsp;
							<Button
								startIcon={<CloudDownload />}
								color="primary"
								variant="contained"
								onClick={() => this.download()}
							>
								Download
							</Button>
						</div>
					</div>
				</Paper>
				<div style={{ background: 'white' }}>
					<CustomDataGrid
						rows={rows}
						columns={headCells.map((col) => ({
							...col,
							sortable: true,
							disableClickEventBubbling: true,
							// resizable: true,
							...(this.isMobile
								? rowWidthConfig[col.field].mobile ||
								  rowWidthConfig[col.field].default
								: rowWidthConfig[col.field].default),
						}))}
						pageSize={this.state.filters.per_page}
						rowCount={this.state.filters.total_results}
						loading={loading}
						paginationModel={this.state.filters}
						onPaginationModelChange={(page) =>
							this.onPageSizeChange({
								pageSize: page.pageSize,
								page:
									page.pageSize != this.state.filters.pageSize
										? 0
										: page.page,
							})
						}
					/>
				</div>
			</Root>
		)
	}

	render() {
		const { userProfile } = this.props
		const { users } = this.props
		const filteredUsers = users
			?.filter((user) => {
				return this.state.searchValue
					? this.searchUserRow(user, this.state.searchValue)
					: true
			})
			.filter((user) => {
				if (
					this.state.sliderValue[1] !== 17 &&
					user.chantingRounds >= this.state.sliderValue[0] &&
					user.chantingRounds <= this.state.sliderValue[1]
				) {
					return true
				} else if (
					this.state.sliderValue[1] === 17 &&
					user.chantingRounds >= this.state.sliderValue[0] &&
					user.chantingRounds <= 1000
				) {
					return true
				}
				return false
			})
			.filter((user) => {
				if (
					this.state.filterConnectedArea &&
					this.state.filterConnectedArea._id
				) {
					if (
						user.connectedAreaId !== undefined &&
						user.connectedAreaId !== '' &&
						user.connectedAreaId?._id &&
						user.connectedAreaId?._id ===
							this.state.filterConnectedArea._id
					) {
						return true
					} else {
						return false
					}
				} else return true
			})
			.filter((user) => {
				if (
					this.state.filterFacilitator &&
					this.state.filterFacilitator._id
				) {
					if (
						user.facilitator !== undefined &&
						user.facilitator !== '' &&
						user.facilitator?._id &&
						user.facilitator?._id ===
							this.state.filterFacilitator._id
					) {
						return true
					} else {
						return false
					}
				} else return true
			})
			.filter((user) => {
				if (
					this.state.filterFrontliner &&
					this.state.filterFrontliner._id
				) {
					if (
						user.frontliner !== undefined &&
						user.frontliner !== '' &&
						user.frontliner?._id &&
						user.frontliner?._id === this.state.filterFrontliner._id
					) {
						return true
					} else {
						return false
					}
				} else return true
			})
			.filter((user) => {
				if (typeof this.state.filters.userRole !== 'undefined') {
					if (user.userRole === this.state.filters.userRole) {
						return true
					} else {
						return false
					}
				} else return true
			})
			.map((u) => ({
				...u,
				facilitatorName:
					u?.facilitator?.initiatedName || u?.facilitator?.name,
			}))
		return (
			<div className="user-container">
				<div>
					{isAreaLeader(userProfile) || isGenSecretary(userProfile)
						? this.populateData(filteredUsers, true)
						: this.populateData(filteredUsers, false)}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	userProfile: state.commonContent.user,
	users: state.userReducer.users,
	total_results: state.userReducer.total_results,
	loading: state.userReducer.loading,
})

const mapDispatchToProps = {
	getUsersAction,
	closeDialog,
	showDialog,
	updateUserAction,
	deleteUserAction,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(UserContainer))
