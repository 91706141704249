import { API_URL, METHOD } from "../constants/network";
import { serialize } from "../utils";
import { fetchAPI } from "../utils/network";

export const getStaticContent = (params) => {
	return fetchAPI({
		url: `${API_URL.STATIC}${serialize(params)}`,
	});
};

export const updateStaticContent = (fileId, params) => {
	return fetchAPI({
		url: `${API_URL.STATIC}/${fileId}`,
		method: METHOD.PUT,
		body: {
			...params,
		},
	});
};

export const createStaticContent = (fileData) => {
	return fetchAPI({
		url: API_URL.STATIC,
		method: METHOD.POST,
		body: fileData,
	});
};
