import React, { Component, useEffect } from 'react'
import { AppBar, Toolbar, Typography } from '@mui/material'
import Logo from '../../assets/images/logo-mini-2.png'
import './style.scss'
import NavbarComponent from '../Navbar'
import { loginByToken } from '../../services/auth'
import {
	ADMIN_PANEL_SITES,
	ROUTE,
	WITHOUT_HEADER_PAGES,
} from '../../constants/route'
import { NavLink, matchPath, useLocation, useNavigate } from 'react-router-dom'

const HeaderComponent = (props) => {
	const location = useLocation()
	const navigate = useNavigate()

	useEffect(() => {
		const search = location.search

		const match = ADMIN_PANEL_SITES.find((path) => {
			return matchPath(
				{
					path: path,
					exact: true,
				},
				location.pathname
			)
		})
		if (search && match) {
			const [key, value] = location.search.split('=')
			if (key.includes('token') && value) {
				loginByToken(value).then((res) => {
					const userProfile = res.data[0]
					userProfile.loggedIn = true
					const token = res.data[0]?.token
					localStorage.setItem('Token', token)
					localStorage.setItem(
						'UserProfile',
						JSON.stringify(userProfile)
					)
					if (
						location.pathname === ROUTE.DASHBOARD.path ||
						location.pathname === ROUTE.LOGIN.path ||
						location.pathname === ROUTE.HOME.path
					)
						navigate(ROUTE.DASHBOARD.path)
				})
			}
		}
	}, [])

	const match = WITHOUT_HEADER_PAGES.find((path) => {
		return matchPath(
			{
				path: path,
				exact: true,
			},
			location.pathname
		)
	})

	if (match) {
		return null
	}

	return (
		<header className="app-header">
			<AppBar position="static">
				<Toolbar className={'header-container'}>
					{localStorage.getItem('UserProfile') ? (
						<NavLink to="/dashboard" exact={true}>
							<LogoContainer />
						</NavLink>
					) : (
						<LogoContainer />
					)}

					<NavbarComponent showLinks={false} {...props} />
				</Toolbar>
			</AppBar>
		</header>
	)
}

export const LogoContainer = () => {
	return (
		<div className="logo-container">
			<div className="logo-img-wrapper">
				<img alt="COASTOK" src={Logo} />
			</div>
			<Typography variant="h2" className="logo-title">
				COASTOK
			</Typography>
		</div>
	)
}

export default HeaderComponent
