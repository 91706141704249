import {
	Button,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Modal,
	Select,
	TextField,
	Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import '../style.scss'
import {
	createOrUpdateInventoryRequestAction,
	getInventoryRequestAction,
} from '../../../actions/event'
import { GridLoadIcon } from '@mui/x-data-grid'
import { getAccomodationName, getAreas, isMobileScreen } from '../../../utils'
import { ACCOMODATION_CATEGORY } from '../../../constants'
import { CancelRounded, Delete, Done, Edit } from '@mui/icons-material'
import { closeDialog, showDialog } from '../../../actions'
import { CustomDataGrid } from '../../../components/CustomTable/CustomDataGrid'

const PREFIX = 'InventoryScreen'

const classes = {
	body: `${PREFIX}-body`,
	modalRoot: `${PREFIX}-modalRoot`,
	modal: `${PREFIX}-modal`,
	paper: `${PREFIX}-paper`,
}

const Root = styled('div')({
	[`& .${classes.body}`]: {
		flexDirection: 'column',
	},
	[`& .${classes.modalRoot}`]: {
		// height: 300,
		position: 'absolute',
		flexGrow: 1,
		minWidth: 300,
		transform: 'translateZ(0)',
		// The position fixed scoping doesn't work in IE 11.
		// Disable this demo to preserve the others.
		'@media all and (-ms-high-contrast: none)': {
			display: 'none',
		},
	},
	[`& .${classes.modal}`]: {
		display: 'flex',
		// padding: theme.spacing(1),
		alignItems: 'center',
		justifyContent: 'center',
	},
	[`& .${classes.paper}`]: {
		width: 400,
		backgroundColor: 'white',
		border: '2px solid #000',
		borderRadius: '8px',
		// boxShadow: theme.shadows[5],
		padding: '40px',
		lineHeight: '1.44',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
})

const InventoryScreen = ({ eventId }) => {
	const [filters, setFilters] = useState({
		areaId: 'ALL',
		pageSize: 10,
		page: 0,
	})
	const [inventoryModal, setInventoryModal] = useState(null)
	const eventReducer = useSelector((state) => state.eventReducer)
	const events = eventReducer.events
	const loading = eventReducer.loading
	const inventoryData = eventReducer?.inventoryData
	const currEvent = events.find((e) => e.eventId === eventId)

	const dispatch = useDispatch()

	useEffect(() => {
		loadInitialData()
	}, [filters.page, filters.per_page, filters.areaId])

	const loadInitialData = () => {
		const areaId = filters.areaId === 'ALL' ? undefined : filters.areaId
		dispatch(
			getInventoryRequestAction({
				...filters,
				page: filters.page + 1,
				per_page: filters.pageSize,
				id: '',
				eventId,
				accomodationCategory: '',
				areaId,
			})
		)
	}
	const rowWidthConfig = {
		hotelName: { default: { flex: 0.15 }, mobile: { width: 150 } },
		accomodationCategory: {
			default: { flex: 0.1 },
			mobile: { width: 150 },
		},
		hotelAddress: { default: { flex: 0.2 }, mobile: { width: 150 } },
		left: { default: { flex: 0.1 }, mobile: { width: 200 } },
		booked: { default: { flex: 0.1 }, mobile: { width: 150 } },
		total: { default: { flex: 0.12 }, mobile: { width: 150 } },
		areaName: { default: { flex: 0.2 } },
		eventName: { default: { flex: 0.2 }, mobile: { width: 150 } },
		id: { default: { flex: 0.1 }, mobile: { width: 150 } },
	}
	const headCells = [
		{
			field: 'hotelName',
			numeric: false,
			headerName: 'Hotel Name',
			sortable: false,
		},
		{
			field: 'hotelAddress',
			numeric: false,
			headerName: 'Hotel Address',
			sortable: false,
		},
		{
			field: 'accomodationCategory',
			numeric: false,
			headerName: 'Accomodation Category',
			sortable: false,
			renderCell: (params) => (
				<Typography>
					{getAccomodationName(params.row?.accomodationCategory)}
				</Typography>
			),
		},
		{
			field: 'left',
			numeric: true,
			headerName: 'Booking Left',
			sortable: false,
		},
		{
			field: 'booked',
			numeric: true,
			headerName: 'Total Booking',
			sortable: false,
		},
		{
			field: 'total',
			numeric: true,
			headerName: 'Total Available Booking',
			sortable: false,
		},
		{
			field: 'areaName',
			numeric: false,
			headerName: 'Area Name',
			sortable: false,
		},
		{
			field: 'eventName',
			numeric: false,
			headerName: 'Event Name',
			sortable: false,
		},
		{
			field: 'id',
			headerName: 'Actions',
			sortable: false,
			filterable: false,
			renderCell: (params) => (
				<>
					<IconButton
						onClick={() => {
							setInventoryModal(params?.row)
						}}
						aria-label="Edit"
						color="primary"
						title="Edit"
						size="large"
					>
						<Edit />
					</IconButton>
					<IconButton
						onClick={() => {
							dispatch(
								showDialog({
									title: 'Are you sure you want to delete this?',
									description:
										"Are you sure to delete this entry request? This action won't be reverted",
									actions: [
										{
											action: dispatch(closeDialog()),
											text: 'Cancel',
											color: 'secondary',
										},
										{
											action: () => {
												submitRequest(params?.row?.id)
											},
											text: 'Yes, Delete',
										},
									],
								})
							)
						}}
						aria-label="Delete"
						color="red"
						title="Delete"
						size="large"
					>
						<Delete />
					</IconButton>
				</>
			),
		},
	]

	const onPageSizeChange = ({ page, pageSize }) => {
		setFilters((f) => ({
			...f,
			page,
			pageSize,
		}))
	}

	const onChange = (key, value) => {
		setInventoryModal((prevValue) => ({
			...prevValue,
			[key]: value,
		}))
	}

	const validateData = (data) => {
		return (
			data?.hotelName &&
			data?.hotelAddress &&
			data?.total > 0 &&
			data?.accomodationCategory != null &&
			data?.accomodationCategory != 'select' &&
			data?.areaId &&
			data?.areaId != 'select'
		)
	}

	const submitRequest = (deleteId = false) => {
		if (!validateData(inventoryModal) && !deleteId) return
		if (!deleteId) {
			const { id } = inventoryModal
			inventoryModal.festType = currEvent.festType
			inventoryModal.eventId = eventId
			dispatch(
				createOrUpdateInventoryRequestAction({
					id,
					inventoryData: inventoryModal,
				})
			).then((resp) => {
				setInventoryModal(null)
				loadInitialData()
			})
		} else {
			dispatch(closeDialog())
			console.log(deleteId)
			dispatch(
				createOrUpdateInventoryRequestAction({
					id: deleteId,
					inventoryData: { status: 0 },
				})
			).then((resp) => {
				setInventoryModal(null)
				loadInitialData()
			})
		}
	}

	const onFilterChange = (name, value) => {
		setFilters((f) => ({
			...f,
			[name]: value,
			page: 1,
		}))
	}

	return (
		<Root className="screen overview-screen">
			<div className="filter-header">
				<Typography variant="h4">{currEvent?.title}</Typography>
				<Button
					variant="contained"
					color="primary"
					onClick={() => setInventoryModal({})}
				>
					Create Inventory
				</Button>
			</div>
			{!!inventoryModal && (
				<div className={classes.modalRoot}>
					<Modal
						open={!!inventoryModal}
						onClose={() => setInventoryModal(null)}
						className={classes.modal}
					>
						<div className={classes.paper}>
							<form noValidate autoComplete="off">
								<Typography variant="h3">
									{inventoryModal?._id
										? 'Update Inventory'
										: 'Create Inventory'}
								</Typography>
								<TextField
									className="m-top-16"
									label="Hotel Name"
									variant="outlined"
									defaultValue={inventoryModal.hotelName}
									value={inventoryModal.hotelName}
									name="hotelName"
									onChange={(e) =>
										onChange(e.target.name, e.target.value)
									}
									fullWidth
								/>
								<TextField
									className="m-top-16"
									label="Hotel Address"
									variant="outlined"
									defaultValue={inventoryModal.hotelAddress}
									value={inventoryModal.hotelAddress}
									name="hotelAddress"
									onChange={(e) =>
										onChange(e.target.name, e.target.value)
									}
									fullWidth
								/>
								<TextField
									className="m-top-16"
									label="Total Accommodation"
									variant="outlined"
									type="number"
									defaultValue={inventoryModal.total}
									value={inventoryModal.total}
									name="total"
									onChange={(e) =>
										onChange(e.target.name, e.target.value)
									}
									fullWidth
								/>
								<Select
									labelId="demo-simple-select-label"
									className="m-top-16"
									defaultValue={
										inventoryModal.accomodationCategory ??
										'select'
									}
									value={
										inventoryModal.accomodationCategory ??
										'select'
									}
									label="Accommodation Category"
									name="accomodationCategory"
									variant="outlined"
									onChange={(e) =>
										onChange(e.target.name, e.target.value)
									}
									fullWidth
								>
									<MenuItem value="select">
										Select Accommodation
									</MenuItem>
									{Object.entries(ACCOMODATION_CATEGORY).map(
										(accommodation) => (
											<MenuItem
												value={accommodation[1].id}
											>
												{accommodation[1].name}
											</MenuItem>
										)
									)}
								</Select>
								<Select
									defaultValue={
										inventoryModal.areaId ?? 'select'
									}
									value={inventoryModal.areaId ?? 'select'}
									onChange={(e) =>
										onChange(e.target.name, e.target.value)
									}
									label="Area"
									name="areaId"
									className="m-top-16"
									variant="outlined"
									fullWidth
								>
									<MenuItem value={'select'}>
										All Areas
									</MenuItem>
									{getAreas().map((area) => (
										<MenuItem value={area._id}>
											{area.name}
										</MenuItem>
									))}
								</Select>
							</form>

							<div className="m-top-16">
								<Button
									variant="contained"
									color="secondary"
									startIcon={<CancelRounded />}
									onClick={() => setInventoryModal(null)}
									aria-label="Reject"
								>
									Close
								</Button>
								&nbsp; &nbsp; &nbsp;
								{loading ? (
									<GridLoadIcon />
								) : (
									<Button
										variant="contained"
										color="primary"
										startIcon={<Done />}
										onClick={() => submitRequest()}
										aria-label="Reject"
									>
										{inventoryModal?.id
											? 'Update'
											: 'Create'}
									</Button>
								)}
							</div>
						</div>
					</Modal>
				</div>
			)}
			<div className="filter-header">
				<FormControl variant="outlined">
					<InputLabel id="demo-simple-select-outlined-label">
						Area
					</InputLabel>
					<Select
						className="area-input"
						value={filters.areaId}
						onChange={(e) =>
							onFilterChange(e.target.name, e.target.value)
						}
						label="Area"
						name="areaId"
					>
						<MenuItem value={'ALL'}>All Areas</MenuItem>
						{getAreas().map((area, i) => (
							<MenuItem value={area._id} key={i}>
								{area.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
			<div className="m-top-16">
				<CustomDataGrid
					rows={
						inventoryData?.data?.map((inv) => ({
							id: inv._id,
							hotelName: inv.hotelName,
							hotelAddress: inv.hotelAddress,
							left: inv.left,
							booked: inv.booked,
							total: inv.total,
							areaName: inv.areaId?.name,
							areaId: inv.areaId?._id,
							accomodationCategory: inv.accomodationCategory,
							eventName: inv.eventId?.title,
							festType: inv.eventId?.festType ?? 2,
						})) || []
					}
					columns={headCells?.map((col) => ({
						...col,
						sortable: true,
						disableClickEventBubbling: true,
						// resizable: true,
						...(isMobileScreen()
							? rowWidthConfig[col.field].mobile ||
							  rowWidthConfig[col.field].default
							: rowWidthConfig[col.field].default),
					}))}
					pageSize={filters.per_page}
					rowCount={inventoryData?.total_results}
					loading={loading}
					paginationModel={filters}
					onPaginationModelChange={(page) =>
						onPageSizeChange({
							pageSize: page.pageSize,
							page:
								page.pageSize != filters.pageSize
									? 0
									: page.page,
						})
					}
				/>
			</div>
		</Root>
	)
}

export default InventoryScreen
