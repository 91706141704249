import { InputAdornment, Paper, TextField } from '@mui/material'
import { GridSearchIcon } from '@mui/x-data-grid'
import React, { useState } from 'react'
import './style.scss'
import { CustomDataGrid } from '../CustomTable/CustomDataGrid'

const Table = (props) => {
	const [filters, setFilters] = useState({
		page: 0,
		pageSize: 10,
	})
	const {
		data,
		header,
		searchTerm,
		actionButtons: ActionButtons,
		loading = false,
	} = props || {}

	return (
		<div className="table-container">
			<Paper className="p-3">
				<div className="table-header-container">
					{searchTerm && (
						<TextField
							id="input-with-icon-textfield"
							label="Search"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<GridSearchIcon />
									</InputAdornment>
								),
							}}
							onChange={(e) => searchTerm(e.target.value)}
							className="search-field"
						/>
					)}
					<ActionButtons />
				</div>
			</Paper>
			<div style={{ minHeight: 800, width: '100%', background: 'white' }}>
				<CustomDataGrid
					rows={data}
					columns={header.map((col) => ({
						...col,
						disableClickEventBubbling: true,
					}))}
					loading={loading}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: filters.pageSize,
							},
						},
					}}
					paginationModel={filters}
					rowCount={data?.length}
					paginationMode="client"
					onPaginationModelChange={(page) =>
						setFilters({
							pageSize: page.pageSize,
							page:
								page.pageSize != filters.pageSize
									? 0
									: page.page,
						})
					}
				/>
			</div>
		</div>
	)
}

export default Table
