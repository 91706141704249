import { CLASS_LEVEL } from "../../constants";
import {getUsers} from "../../services/user";
import { exportFromJSON } from "../../utils";

export const getAreaLeaders = () => {
    return getUsers({
        userRole: 4,
        per_page: 500
    })
}

export const getFacilitators = () => {
    return getUsers({
        userRole: 3,
        per_page: 500
    })
}

export const getFrontliners = () => {
    return getUsers({
        userRole: 2,
        per_page: 500
    })
}

export const download = (users) => {
    const fileName = "users";
    exportFromJSON({
        data: users
            .map((user) => ({
                "Name": user.initiatedName || user.name,
                "Email": user.email,
                "Phone": user.phone,
                "Date of birth": user.dob,
                "Facilitator": user?.facilitator?.initiatedName || user?.facilitator?.name,
                "Frontliner": user?.frontliner?.initiatedName || user?.frontliner?.name,
                "Expertise Area": user.expertiseArea,
                "Profession": user.profession,
                "Chanting": user.chantingRounds,
                "Class Level": CLASS_LEVEL[user.sadhnaGroup],
                "Area Leader": user?.areaLeader?.initiatedName || user?.areaLeader?.name,
                "Assistant Director":
                    user?.assistantDirector?.initiatedName ||
                    user?.assistantDirector?.name,
                "Connected Area": user.connectedAreaId?.name,
            })),
        fileName,
    });
};