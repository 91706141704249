import { Step, StepLabel, Stepper } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import { ROUTE } from '../../../constants/route'
import { getQueryObj, getReferrer } from '../../../utils'
import '../style.scss'
import {
	confirmViplavaRegistration,
	getBookingDetails,
	getEventDetails,
	getUserByUid,
	updateBooking,
	updateWebUser,
} from '../../../services/viplava'
import { connect } from 'react-redux'
import { HIDE_MAIN_LOADER, SHOW_MAIN_LOADER } from '../../../constants/actions'
import { basicValidationSchema, calculateTotal, getAmounts } from '../utils'
import { Formik } from 'formik'
import BasicInfo from './BasicInfoScreen'
import AdditionalInfo from './AdditionalInfoScreen'
import PaymentSection from './PaymentScreen'
import AcknowledgmentScreen from './AcknowledgementScreen'
import { loginByToken } from '../../../services/auth'
import { GOOGLE_CLIENT_ID } from '../../../constants/config'
import { EVENTS_UI_CONFIG } from '../../../data/event'
import {
	convertToDateObject,
	getFormattedDate,
} from '../../../components/Calender/helpers'
import { withRouter } from '../../../hooks/withRouter'
import { GoogleLogin } from '@react-oauth/google'

const PREFIX = 'ViplavaRegister'

const classes = {
	pointerHand: `${PREFIX}-pointerHand`,
}

const Root = styled('div')(({ theme }) => ({
	[`& .${classes.pointerHand}`]: {
		cursor: 'pointer',
	},
}))

class ViplavaRegister extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			stage: 0,
			userDetails: {},
			emailConfirmModal: false,
			userCreated: false,
			totalAmount: calculateTotal(getAmounts({})),
			event: null,
			orderIdUpdated: false,
			isTransportSelected: 1,
			eventId: this.props.router?.params?.eventId,
		}
		this.steps = ['Fill details', 'Select Amentities', 'Payment']
	}

	componentDidMount() {
		const data = getQueryObj(this.props.router?.location?.search)
		if (data.token) {
			// alert(JSON.stringify(data));
			loginByToken(data.token).then((res) => {
				const userProfile = res.data[0]
				const { _id, roles } = userProfile
				userProfile.loggedIn = true
				// const token = res.data[0]?.token
				// localStorage.setItem('Token', token)
				localStorage.setItem('UserProfile', JSON.stringify(userProfile))
				// check if the user is an facilitator or above role?
				// provide multiple entry options if this is the case.
				// but for now the person can register himself rather than registering others.
				this.firstEncounter({ _id })
			})
		} else {
			this.firstEncounter(data)
		}
		this.setEventId(this.props.router?.params?.eventId)
	}

	setEventId = (eventId) => {
		this.setState({
			eventId,
		})
		getEventDetails().then(({ result }) => {
			const event = result.find((event) => event.eventId === eventId)
			this.setState((state) => ({
				...state,
				event,
				totalAmount: calculateTotal(getAmounts({ event })),
			}))
		})
	}

	firstEncounter = (data) => {
		getUserByUid({ email: data.email, user_id: data._id }).then(
			({ result }) => {
				if (result?.email) {
					const tempState = {
						userDetails: {
							_id: result._id,
							email: data.email || result.email,
							name: result.name,
							initiatedName: result.initiatedName,
							imageUrl:
								result.imageUrl ||
								'https://res.cloudinary.com/dm1o3cvik/image/upload/v1725726615/braja/igppfiq29xsdrznyhn6c.png',
							phone: result.phone,
							dob: convertToDateObject(result.dob),
							education: result.education,
							connectedAreaId: result.connectedAreaId,
							facilitator: result.facilitatorId,
							chantingRounds: result.chantingRounds,
							userRole: result.userRole,
							userCategory: result.userCategory || 'YOUTH',
							sadhnaGroup:
								result.userCategory === 'YOUTH'
									? result.sadhnaGroup || 1
									: 0,
						},
						userCreated: true,
					}
					// userId: 62d6fbc5e7cd03b240c0f5df
					getBookingDetails(
						result._id,
						this.props.router?.params?.eventId
					)
						.then((resp) => {
							if (resp.result?.bookingId) {
								tempState.booking = resp.result
							}
							if (
								resp.result?.status === 'CONFIRMED' ||
								resp.result?.status === 'FAILED' ||
								resp.result?.status === 'SUBSIDY_REQUESTED' ||
								resp.result?.status === 'CASH_PENDING' ||
								resp.result?.status === 'CASH_CONFIRMED' ||
								resp.result?.status === 'PARTIALLY_PAID' ||
								resp.result?.status === 'CASH_PARTIALLY_PAID' ||
								resp.result?.status ===
									'PREPAID_SUBSIDY_REQUESTED'
							) {
								this.setState({
									stage: 3,
								})
							} else if (
								resp.result?.status === 'ORDERED' ||
								resp.result?.status === 'APPROVED' ||
								resp.result?.status === 'SUBSIDY_APPROVED'
							) {
								this.setState({
									stage: 2,
								})
							} else if (resp.result?.status === 'PENDING') {
								this.setState({
									stage: 1,
								})
							}
							this.setState(tempState)
						})
						.catch((e) => {
							this.setState(tempState)
						})
				} else {
					// check the localStorage
					try {
						const userDetails = JSON.parse(
							localStorage.getItem('USER_DETAILS')
						)
						if (userDetails) {
							this.setState({ userDetails })
						} else {
							this.setState({
								emailConfirmModal: true,
							})
						}
					} catch (e) {
						this.setState({
							emailConfirmModal: true,
						})
					}
				}
			}
		)
	}

	updateAmount = (updatedAmount) => {
		this.setState({
			totalAmount: updatedAmount,
		})
	}

	unsetOrderIdUpdated = () => {
		this.setState({
			orderIdUpdated: false,
		})
	}

	setOrderIdUpdated = () => {
		this.setState({
			orderIdUpdated: true,
		})
	}

	saveBasicInfo = (newData) => {
		const { userDetails } = this.state
		const { showLoader, hideLoader } = this.props
		const combinedDetails = { ...userDetails, ...newData }
		const {
			_id,
			chantingRounds,
			connectedAreaId,
			education,
			email,
			facilitator,
			imageUrl,
			initiatedName,
			name,
			dob,
			phone,
			userRole,
			userCategory,
			sadhnaGroup,
		} = combinedDetails
		this.setState(({ userDetails }) => ({
			userDetails: { ...userDetails, ...newData },
		}))
		if (
			combinedDetails.email &&
			combinedDetails.name &&
			combinedDetails.education &&
			combinedDetails.dob &&
			combinedDetails.phone &&
			(combinedDetails?.connectedAreaId ||
				combinedDetails?.connectedAreaId?._id) &&
			combinedDetails.facilitator &&
			combinedDetails.chantingRounds >= 2 &&
			combinedDetails.userCategory
		) {
			showLoader()
			updateWebUser({
				_id,
				chantingRounds,
				connectedAreaId: connectedAreaId?._id || connectedAreaId,
				email,
				facilitator: facilitator?._id || facilitator,
				imageUrl,
				initiatedName,
				name,
				education: education?.name || education,
				dob: getFormattedDate(dob),
				phone,
				userRole,
				gender: 1,
				userCategory,
				sadhnaGroup,
			})
				.then(({ result }) => {
					this.setState(
						{
							userDetails: {
								_id: result._id,
								email: result.email,
								name: result.name,
								education: result.education,
								dob: convertToDateObject(result.dob),
								initiatedName: result.initiatedName,
								imageUrl: result.imageUrl,
								phone: result.phone,
								connectedAreaId: result.connectedAreaId,
								facilitator: result.facilitatorId,
								chantingRounds: result.chantingRounds,
								userRole: result.userRole,
								gender: result.gender || 1,
								userCategory: result.userCategory || 'YOUTH',
								sadhnaGroup:
									result.userCategory === 'YOUTH'
										? result.sadhnaGroup || 1
										: 0,
							},
							userCreated: true,
						},
						() => {
							if (result._id) {
								this.handleNext()
							}
						}
					)
				})
				.finally(() => {
					hideLoader()
				})
		} else {
			alert('Please fill all details')
		}
	}

	updateBooking = () => {
		const eventId = this.props.router?.params?.eventId
		const userId = this.state.userDetails._id
		updateBooking({
			userId,
			accomodationCategory: 0,
			transportationAmount: 0,
			eventId: eventId || this.props.router?.params?.eventId,
			tokenAmount: 500,
		})
			.then((resp) => {
				if (resp.result.bookingId) {
					if (
						this.props.router?.params?.eventId !== eventId &&
						eventId !== 'undefined' &&
						eventId
					) {
						// we have to redirect to othere event with event id: eventId
						window.location.href = window.location.href.replace(
							this.props.router?.params?.eventId,
							eventId
						)
					} else {
						this.handleNext()
						this.setState({
							booking: resp.result,
						})
					}
				}
			})
			.catch((err) => {
				console.log('errrrrrrrrrrrrrrrrrrr', err)
			})
	}

	saveAddnInfo = (newData) => {
		const { userDetails, booking } = this.state
		const combinedDetails = { ...userDetails, ...newData, booking }
		const {
			_id,
			accomodationCategory,
			registrationAmount,
			donationAmount,
			accomodationAmount,
			transportationAmount,
			outfitCount,
			referrer,
			cf1_value,
			cf2_value,
		} = combinedDetails
		if (_id) {
			this.setState({
				isTransportSelected: transportationAmount,
			})
			const eventId = this.props.router?.params?.eventId
			updateBooking({
				userId: _id,
				bookingId: booking?.bookingId,
				accomodationCategory,
				registrationAmount,
				donationAmount,
				accomodationAmount,
				transportationAmount,
				outfitCount,
				eventId,
				tokenAmount: newData.tokenAmount,
				referrer,
				cf1: 'NUMBER_OF_BSS_CAMP',
				cf2: 'PBT_YEAR',
				cf1_value,
				cf2_value,
			})
				.then((resp) => {
					this.setState(
						{
							booking: resp.result,
						},
						() => {
							this.handleNext()
						}
					)
				})
				.catch((err) => {
					console.log('errrrrrrrrrrrrrrrrrrr', err)
				})
		}
	}

	onRaiseSubsidyRequest = (booking) => {
		this.setState({
			booking,
			stage: 3,
		})
	}

	onPaymentConfirmation = () => {
		confirmViplavaRegistration(this.state.booking.bookingId)
			.then(({ result }) => {
				this.setState({
					paymentDone: true,
					failureReason: '',
					stage: 3,
					booking: result,
				})
			})
			.catch((err) => {
				console.log(err)
				this.setState((state) => ({
					paymentDone: true,
					failureReason: '',
					stage: 3,
					booking: {
						...state.booking,
						status: 'FAILED',
					},
				}))
			})
	}

	getStepContent = (stepIndex) => {
		const {
			userDetails,
			totalAmount,
			booking,
			paymentDone,
			failureReason,
			event,
			orderIdUpdated,
			isTransportSelected,
		} = this.state
		const eventId = this.state.eventId
		const eventUiConfig = EVENTS_UI_CONFIG.find(
			(event) => event.uuid === eventId
		)
		const FormHeader = (
			<Root className="viplava-form-header">
				<img
					className="profile-img"
					src={userDetails.imageUrl}
					alt="Profile"
					referrerpolicy="no-referrer"
				/>
				{stepIndex ? (
					eventUiConfig.showAmount && (
						<div className="payment-details">
							<div className="small">Total Amount</div>
							<div className="cost">₹{totalAmount}</div>
						</div>
					)
				) : (
					<div className="payment-details">
						<div className="small" style={{ margin: '20px' }}>
							Please update your profile information here
						</div>
					</div>
				)}
			</Root>
		)
		switch (stepIndex) {
			case 0:
				return (
					<Formik
						initialValues={{
							email: userDetails.email || '',
							name: userDetails.name || '',
							initiatedName: userDetails.initiatedName || '',
							connectedAreaId: userDetails.connectedAreaId || '',
							phone: userDetails.phone || '',
							education: userDetails.education || '',
							dob: userDetails.dob || '',
							facilitator: userDetails.facilitator || '',
							chantingRounds: userDetails.chantingRounds || 0,
							userRole: userDetails.userRole || 0,
							userCategory: userDetails.userCategory || 'YOUTH',
							sadhnaGroup:
								userDetails.userCategory === 'YOUTH'
									? userDetails.sadhnaGroup || 1
									: 0,
							cf1_value: '',
							cf2_value: '',
						}}
						validationSchema={basicValidationSchema({
							minimumChantingRounds:
								eventUiConfig.eligibility
									?.minimumChantingRounds || 0,
						})}
						// validate={(values) => {
						//   const errors = {};
						//   if (!values.email) {
						//     errors.email = "Required";
						//   } else if (
						//     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
						//   ) {
						//     errors.email = "Invalid email address";
						//   }
						//   return errors;
						// }}
						onSubmit={(values, { setSubmitting }) => {
							this.saveBasicInfo(values)
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							validateForm,
							setErrors,
							/* and other goodies */
						}) => (
							<form noValidate className="register-container">
								{FormHeader}
								<BasicInfo
									errors={errors}
									touched={touched}
									handleChange={handleChange}
									handleBlur={handleBlur}
									userDetails={values}
									onSubmit={(values) => {
										console.log('Handle Submit')
										validateForm().then((errors) => {
											if (
												Object.keys(errors).length === 0
											) {
												// If no errors, proceed with submitting the form
												handleSubmit(values)
											} else {
												setErrors(errors)
												// If there are errors, you can handle them here
												console.log(
													'Validation errors:',
													errors
												)
											}
										})
									}}
									eventId={eventId}
								/>
							</form>
						)}
					</Formik>
				)
			case 1:
				return (
					<form noValidate className="register-container">
						{FormHeader}
						<AdditionalInfo
							userDetails={userDetails}
							booking={booking}
							onSubmit={this.saveAddnInfo}
							totalAmount={totalAmount}
							updateAmount={this.updateAmount}
							event={event}
							eventId={eventId}
						/>
					</form>
				)
			case 2:
				return (
					<PaymentSection
						eventId={eventId}
						userDetails={userDetails}
						booking={booking}
						onRaiseSubsidyRequest={this.onRaiseSubsidyRequest}
						onPaymentSuccess={this.onPaymentConfirmation}
						onPaymentFailure={(failureObj) => {
							this.setState({
								paymentDone: false,
								failureObj: failureObj,
								stage: 3,
							})
						}}
						isTransportSelected={isTransportSelected}
						orderIdUpdated={orderIdUpdated}
						unsetOrderIdUpdated={this.unsetOrderIdUpdated}
					/>
				)
			default:
				if (booking?.bookingReferenceNo) {
					return (
						<AcknowledgmentScreen
							name={userDetails.initiatedName || userDetails.name}
							paymentDone={paymentDone}
							failureReason={failureReason}
							bookingStatus={booking.status}
							bookingReferenceNo={booking.bookingReferenceNo}
							bookingDateTime={booking.bookingDateTime}
							eventName={booking.eventName}
							finalAmount={booking.finalAmount}
							amountAfterSubsidy={booking.amountAfterSubsidy}
							userDetails={userDetails}
							paidAmount={booking.paidAmount}
							booking={booking}
							onPaymentSuccess={this.onPaymentConfirmation}
							eventUiConfig={eventUiConfig}
							onPaymentFailure={(failureObj) => {
								this.setState({
									paymentDone: false,
									failureObj: failureObj,
									stage: 3,
								})
							}}
						/>
					)
				}
		}
	}

	handleNext = () => {
		this.setState((state) => ({ stage: state.stage + 1 }))
	}

	handleBack = () => {
		this.setState((state) => ({ stage: state.stage - 1 }))
	}

	handleReset = () => {
		this.setState({
			stage: 0,
		})
	}

	render() {
		const { stage, userDetails, emailConfirmModal } = this.state
		const {} = this.props
		return (
			<div className="viplava-page">
				{emailConfirmModal && (
					<div className="email-confirm-modal">
						<div className="message">
							Sorry but we found your email invalid. Kindly verify
							your email again
						</div>
						<GoogleLogin
							buttonText="Verify with Google"
							useOneTap
							onSuccess={(res) => {
								localStorage.setItem(
									'USER_DETAILS',
									JSON.stringify({
										email: res.profileObj.email,
										name: res.profileObj.name,
										imageUrl: res.profileObj.imageUrl,
									})
								)
								this.setState({
									emailConfirmModal: false,
								})
								window.location.href = `${
									ROUTE.EVENT_REGISTER_PAGE.path
								}/${this.props.router?.params?.eventId}?email=${
									res.profileObj.email
								}&referrer=${getReferrer()}`
							}}
							onError={() => {}}
							cookiePolicy={'single_host_origin'}
							className="white-border-button"
						/>
					</div>
				)}
				<Stepper
					activeStep={stage}
					alternativeLabel
					style={{ marginTop: 30, backgroundColor: 'white' }}
				>
					{this.steps.map((label, index) => (
						<Step
							key={label}
							className={classes.pointerHand}
							onClick={() => {
								if (stage < 3 && stage > index)
									this.setState({
										stage: index,
									})
							}}
						>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
				{userDetails?.email && this.getStepContent(stage)}
			</div>
		)
	}
}

const mapStateToProps = null

const mapDispatchToProps = (dispatch) => ({
	showLoader: () => dispatch({ type: SHOW_MAIN_LOADER }),
	hideLoader: () => dispatch({ type: HIDE_MAIN_LOADER }),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(ViplavaRegister))
