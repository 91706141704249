import { ALERT } from "../constants";
import {
	GET_PLEDGE_DETAIL_FAILED,
	GET_PLEDGE_DETAIL_STARTED,
	GET_PLEDGE_DETAIL_SUCCESS,
	GET_PLEDGE_SUMMARY_FAILED,
	GET_PLEDGE_SUMMARY_STARTED,
	GET_PLEDGE_SUMMARY_SUCCESS,
	GET_PLEDGE_USERS_FAILED,
	GET_PLEDGE_USERS_STARTED,
	GET_PLEDGE_USERS_SUCCESS,
} from "../constants/actions";

export const initialState = {
	pledgeDrives: { current_page: 1, data: [], total_pages: 1, total_results: 0 },
	loading: false,
	error: null,
	alert: null,
};

const pledgeReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_PLEDGE_USERS_STARTED:
		case GET_PLEDGE_SUMMARY_STARTED:
		case GET_PLEDGE_DETAIL_STARTED:
			return {
				...state,
				loading: true,
			};
		case GET_PLEDGE_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				pledgeDrives: action.payload,
			};
		case GET_PLEDGE_USERS_FAILED:
		case GET_PLEDGE_SUMMARY_FAILED:
		case GET_PLEDGE_DETAIL_FAILED:
			return {
				...state,
				loading: false,
				error: action.error,
				alert: {
					message: action.error,
					severity: ALERT.ERROR,
					duration: 5000,
				},
			};
		case GET_PLEDGE_SUMMARY_SUCCESS:
			const pledgeDrives = {
				...state.pledgeDrives,
				data: state.pledgeDrives.data.map((pledgeDrive) => {
					return {
						...pledgeDrive,
						pledges: action.payload.map((a) => {
							return {
								areaName: a.areaName,
								id: a.pledgeData.areaId,
								totalMembers: a.pledgeData.areaUserTotal,
								pledgedMembers: a.pledgeData.areaUserPledged,
								pledgeCount: a.pledgeData.areaPledgeTotal,
							};
						}),
					};
				}),
			};
			return {
				...state,
				pledgeDrives,
			};
		default:
			return state;
	}
};

export default pledgeReducer;
