import { API_URL, METHOD } from "../constants/network";
import { fetchAPI } from "../utils/network";
import { updateUser } from "./user";

export const loginService = (body) => {
	return fetchAPI({
		url: API_URL.LOGIN,
		method: METHOD.POST,
		body,
	});
};

export const validateToken = (token) => {
	return fetchAPI({
		url: API_URL.VALIDATE,
		method: METHOD.GET,
	});
};

export const loginByToken = (token) => {
	return fetchAPI({
		url: API_URL.APP_TOKEN_LOGIN,
		method: METHOD.POST,
		body: {
			token,
		},
		isOpenApi: true
	});
};

export const logout = () => {
	return fetchAPI({
		url: API_URL.LOGOUT,
	});
};

export const resetPassword = (userId) => {
	return updateUser(userId, {
		password: "password",
	});
};

export const changePassword = (body) => {
	return fetchAPI({
		url: API_URL.RESET_PASSWORD,
		method: METHOD.POST,
		body,
	});
};
