import { AXIS_RAZORPAY_KEY_ID, RAZORPAY_KEY_ID } from '../../constants/config'
import { COLOR } from '../../constants/theme'
import {
	ALERT,
	EVENT_BOOKING_STATUS,
	PAYMENT_METHOD_ONLINE,
} from '../../constants'
import { createOrder } from '../../services/viplava'
import { useEffect, useState } from 'react'
import store from '../../store'
import { showAlert } from '../../actions'

function usePayment({
	booking,
	userDetails,
	onPaymentSuccess,
	onPaymentFailure,
	eventUiConfig,
}) {
	const [paymentData, setPaymentData] = useState({
		orderId: '',
		paymentMode: '',
	})

	var options = {
		key: RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
		name: 'COASTOK',
		order_id: paymentData.orderId, // For one time payment
		prefill: {
			name: userDetails.name,
			email: userDetails.email,
			contact: userDetails.phone,
		},
		theme: {
			color: COLOR.PRIMARY,
		},
		// This handler function will handle the success payment
		handler: function (response) {
			onPaymentSuccess()
		},
	}
	var options2 = { ...options, key: AXIS_RAZORPAY_KEY_ID }

	// eslint-disable-next-line no-undef
	var rzp1 = new Razorpay(options)
	// eslint-disable-next-line no-undef
	var axisRzp = new Razorpay(options2)

	const initiatePayment = (paymentMode, residueAmount = false) => {
		if (
			Date.now() >
				new Date(eventUiConfig.deadline).getTime() +
					24 * 60 * 60 * 1000 +
					9 * 60 * 60 * 1000 &&
			!residueAmount
		) {
			// On deadline day at 12pm midnight
			store.dispatch(
				showAlert({
					message: 'Registration are closed for this event.',
					severity: ALERT.ERROR,
					duration: 5000,
				})
			)
			return
		}
		if (userDetails._id && booking.bookingId) {
			return createOrder({
				userId: userDetails._id,
				bookingId: booking.bookingId,
				pg: paymentMode,
			})
				.then((resp) => {
					if (resp.result.orderId) {
						setPaymentData({
							orderId: resp.result.orderId,
							paymentMode,
						})
					}
				})
				.catch((err) => {
					console.log('errrrrrrrrrrrrrrrrrrr', err)
				})
		}
	}

	useEffect(() => {
		if (paymentData.orderId && paymentData.paymentMode) {
			if (paymentData.paymentMode === PAYMENT_METHOD_ONLINE.AXIS_BANK) {
				axisRzp.open()
			} else if (
				paymentData.paymentMode === PAYMENT_METHOD_ONLINE.RAZOR_PAY
			) {
				rzp1.open()
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paymentData.orderId, paymentData.paymentMode])

	rzp1.on('payment.failed', function (response) {
		onPaymentFailure(response.error)
	})

	axisRzp.on('payment.failed', function (response) {
		onPaymentFailure(response.error)
	})
	return { initiatePayment }
}

export default usePayment
