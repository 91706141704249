import { API_URL, METHOD } from '../constants/network'
import { serialize } from '../utils'
import { fetchAPI } from '../utils/network'

export const getSadhna = (params) => {
	return fetchAPI({
		url: API_URL.GET_SADHNA + serialize(params),
		method: 'GET',
	})
}
