import React, { Component } from 'react'
import { Button, CircularProgress, TextField, Typography } from '@mui/material'
import './style.scss'
import {
	createScheduleAction,
	updateScheduleAction,
} from './../../actions/schedule'
import { connect } from 'react-redux'

class UpdateNotificationPopup extends Component {
	constructor(props) {
		super(props)
		let date = props.date.split('/')
		let finalDate = date[0] + '/' + date[1] + '/' + date[2].slice(-2)
		this.state = {
			notificationId: props.notificationId || '',
			heading: props.heading || '',
			body: props.body || '',
			date: finalDate,
			loading: false,
		}
	}

	onChange = (name, value) => {
		this.setState({
			[name]: value,
		})
	}

	submitNotification = () => {
		const { notificationId } = this.state
		const { closeHandler, createScheduleAction, updateScheduleAction } =
			this.props
		this.setState({ loading: true })
		if (notificationId) {
			//update notification
			updateScheduleAction(notificationId, {
				...this.state,
			})
				.then((res) => {
					this.setState({ loading: false })
					closeHandler()
				})
				.catch(() => {
					this.setState({ loading: false })
				})
		} else {
			//create notification
			createScheduleAction({
				...this.state,
				notificationId: undefined,
			})
				.then((res) => {
					this.setState({ loading: false })
					closeHandler()
				})
				.catch(() => {
					this.setState({ loading: false })
				})
		}
	}

	render() {
		const { notificationId, heading, body, date } = this.state
		return (
			<form noValidate autoComplete="off">
				<Typography variant="h5">
					{notificationId
						? 'Update Notification'
						: 'Create Notification'}
				</Typography>
				<TextField
					className="m-top-16"
					label="Heading"
					variant="outlined"
					defaultValue={heading}
					value={heading}
					name="heading"
					onChange={(e) =>
						this.onChange(e.target.name, e.target.value)
					}
					fullWidth
				/>
				<TextField
					className="m-top-16"
					label="Body"
					variant="outlined"
					defaultValue={body}
					value={body}
					name="body"
					onChange={(e) =>
						this.onChange(e.target.name, e.target.value)
					}
					fullWidth
					multiline
					rows={4}
				/>
				<TextField
					className="m-top-16"
					label="Date"
					variant="outlined"
					defaultValue={date}
					value={date}
					name="date"
					onChange={(e) =>
						this.onChange(e.target.name, e.target.value)
					}
					fullWidth
					disabled
				/>
				<Button
					className="m-top-16"
					variant="contained"
					color="primary"
					onClick={this.submitNotification}
					disabled={this.state.loading}
				>
					{notificationId
						? 'UPDATE NOTIFICATION'
						: 'CREATE NOTIFICATION'}
					{this.state.loading && (
						<CircularProgress size={15} className="ml-2" />
					)}
				</Button>
			</form>
		)
	}
}

const mapStateToProps = (state) => ({
	loading: state.scheduleReducer.loading,
	alert: state.scheduleReducer.alert,
})

const mapDispatchToProps = {
	createScheduleAction,
	updateScheduleAction,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UpdateNotificationPopup)
