import React, { Component } from 'react'
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom'
import DashboardContainer from '../../containers/Dashboard'
import Header from '../Header'
import { ROUTE } from '../../constants/route'
import LoginContainer from '../../containers/Login'
import AnswerQuestionContainer from '../../containers/AnswerQuestion'
import ManageAreaContainer from '../../containers/ManageArea'
import ViewPledging from '../../containers/Pledging/ViewPledging'
import UserContainer from '../../containers/UserContainer'
import PrivacyContainer from '../../containers/Privacy'
import PledgingContainer from '../../containers/Pledging'
import ManageAppContent from '../../containers/ManageAppContent'
import ViewAreaPledges from '../../containers/Pledging/ViewAreaPledges'
import NotificationContainer from '../../containers/Notification'
import ScheduleNotificationsContainer from '../../containers/ScheduleNotifications'
import Register from '../../containers/Register'
import ResetPassword from '../../containers/ResetPassword'
import UpdateFrontliners from '../../containers/UpdateFrontliners'
import ReportContainer from '../../containers/Reports'
import ViplavaRegister from '../../containers/EventRegister/shared-pages/ViplavaRegister'
import EventContainer from '../../containers/ManageEvent'
import RefundPolicy from '../../containers/RefundPolicy'
import Terms from '../../containers/Terms'
import Footer from '../Footer'
import About from '../../containers/About'
import Contact from '../../containers/ContactUs'
import ViplavaBridge from '../../containers/EventRegister/shared-pages/ViplavaBridge'
import EventLandingPage from '../../containers/EventRegister/shared-pages'
import EventListing from '../../containers/EventRegister/shared-pages/EventListing'
import Viplava2023 from '../../containers/EventRegister/particular-pages/Viplava2023'
import Donate from '../../containers/Donate'
import LibraryContainer from '../../containers/LibraryContainer'
import HaridwarCamp from '../../containers/EventRegister/particular-pages/HaridwarCamp2023'
import ManageQRScreen from '../../containers/ManageAttendance/ManageQR'
import AttendanceScreen from '../../containers/ManageAttendance/AttendanceScreen'
import SadhnaReportContainer from '../../containers/SadhnaReport'
import PrivateRouter from './PrivateRouter'
import JagannathPuriMayapurCamp from '../../containers/EventRegister/particular-pages/PuriMayapurCamp2022'

const Layout = () => {
	return (
		<>
			<Header />
			<Outlet />
			<Footer />
		</>
	)
}

class RouterComponent extends Component {
	render() {
		return (
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Layout />}>
						<Route
							path={ROUTE.HOME.path}
							element={<LoginContainer />}
							exact
						/>
						<Route
							path={ROUTE.PRIVACY.path}
							element={<PrivacyContainer />}
							exact
						/>
						<Route
							path={ROUTE.REGISTER.path}
							element={<Register />}
							exact
						/>
						<Route
							path={ROUTE.VIPLAVA.path}
							element={<Viplava2023 />}
							exact
						/>
						<Route
							path={ROUTE.DONATION_PAGE.path}
							element={<Donate />}
							exact
						/>
						<Route
							path={ROUTE.EVENT.path}
							element={<EventLandingPage />}
							exact
						/>
						<Route
							path={ROUTE.EVENT_BRIDGE.path}
							element={<ViplavaBridge />}
							exact
						/>
						<Route
							path={ROUTE.EVENT_REGISTER.path}
							element={<ViplavaRegister />}
							exact
						/>
						<Route
							path={ROUTE.EVENT_REGISTER_FOR_APP.path}
							element={<ViplavaRegister />}
							exact
						/>
						<Route
							path={ROUTE.TNC.path}
							element={<Terms />}
							exact
						/>
						<Route
							path={ROUTE.ABOUT.path}
							element={<About />}
							exact
						/>
						<Route
							path={ROUTE.CONTACT.path}
							element={<Contact />}
							exact
						/>
						<Route
							path={ROUTE.REFUND_POLICY.path}
							element={<RefundPolicy />}
							exact
						/>
						{/* <Route path={ROUTE.VIPLAVA_REGISTER.path} element={<ViplavaRegister/>} exact /> */}
						<Route
							path={ROUTE.VIPLAVA_BRIDGE.path}
							element={<ViplavaBridge />}
							exact
						/>

						{/* <Route
						path={ROUTE.BSS_CAMP.path}
						element={<()/> => (
							<HaridwarCamp eventId="bss-dps-haridwar-camp-2024" />
						)}
						exact
					/>
					<Route
						path={ROUTE.SPS_CAMP.path}
						element={<()/> => (
							<HaridwarCamp eventId="sps-vl2-haridwar-camp-2024" />
						)}
						exact
					/> */}

						<Route
							path={ROUTE.BSS_BRAJA.path}
							element={
								<HaridwarCamp eventId="bss-braja-camp-2024" />
							}
							exact
						/>

						{/* <Route
						path={ROUTE.FTP12024.path}
						element={<()/> => (
							<ViplavaBridge
								eventIdFromProps={
									EVENTS_UI_CONFIG.find(
										(event) => event.id === 'ftp12024'
									)?.uuid
								}
							/>
						)}
						exact
					/> */}
						<Route
							path={ROUTE.MAYAPYR2024.path}
							element={<JagannathPuriMayapurCamp />}
							exact
						/>
						<Route
							path={ROUTE.PURI2024.path}
							element={<JagannathPuriMayapurCamp />}
							exact
						/>
						<Route
							path={ROUTE.BOTHMNP.path}
							element={<JagannathPuriMayapurCamp />}
							exact
						/>

						<Route
							path={ROUTE.EVENT_LIST_PAGE.path}
							element={<EventListing />}
							exact
						/>

						<Route
							path={ROUTE.CAMP_BRIDGE.path}
							element={<EventListing />}
							exact
						/>

						{/* Private Routers starts here */}
						<Route
							path="/*"
							element={
								<PrivateRouter redirectTo="/login">
									<Routes>
										<Route
											path={
												ROUTE.ATTENDANCE_MANAGE_QR.path
											}
											route={ROUTE.ATTENDANCE_MANAGE_QR}
											element={<ManageQRScreen />}
											exact
										/>

										<Route
											path={ROUTE.ATTENDANCE_MANAGE.path}
											route={ROUTE.ATTENDANCE_MANAGE}
											element={<AttendanceScreen />}
											exact
										/>

										<Route
											path={ROUTE.DASHBOARD.path}
											route={ROUTE.DASHBOARD}
											element={<DashboardContainer />}
											exact
										/>
										<Route
											path={ROUTE.ANSWER_QUESTION.path}
											route={ROUTE.ANSWER_QUESTION}
											element={
												<AnswerQuestionContainer />
											}
											exact
										/>
										<Route
											path={ROUTE.MANAGE_AREA.path}
											route={ROUTE.MANAGE_AREA}
											element={<ManageAreaContainer />}
											exact
										/>
										<Route
											path={ROUTE.PLEDGING.path}
											route={ROUTE.PLEDGING}
											element={<PledgingContainer />}
											exact
										/>
										<Route
											path={ROUTE.UPDATE_FRONTLINERS.path}
											route={ROUTE.UPDATE_FRONTLINERS}
											element={<UpdateFrontliners />}
											exact
										/>
										<Route
											path={ROUTE.REPORTS.path}
											route={ROUTE.REPORTS}
											element={<ReportContainer />}
											exact
										/>
										<Route
											path={
												ROUTE.VIEW_PLEDGE_SUMMARY.path
											}
											route={ROUTE.VIEW_PLEDGE_SUMMARY}
											element={<ViewPledging />}
											exact
										/>
										<Route
											path={
												ROUTE.VIEW_PLEDGE_AREAWISE.path
											}
											route={ROUTE.VIEW_PLEDGE_AREAWISE}
											element={<ViewAreaPledges />}
										/>
										<Route
											path={ROUTE.NOTIFICATION.path}
											route={ROUTE.NOTIFICATION}
											element={<NotificationContainer />}
											exact
										/>
										<Route
											path={ROUTE.MANAGE_APP_CONTENT.path}
											route={ROUTE.MANAGE_APP_CONTENT}
											element={<ManageAppContent />}
											exact
										/>
										<Route
											path={ROUTE.USER.path}
											route={ROUTE.USER}
											element={<UserContainer />}
											exact
										/>
										<Route
											path={
												ROUTE.SCHEDULE_NOTIFICATION.path
											}
											route={ROUTE.SCHEDULE_NOTIFICATION}
											element={
												<ScheduleNotificationsContainer />
											}
											exact
										/>
										<Route
											path={ROUTE.RESET_PASSWORD.path}
											route={ROUTE.RESET_PASSWORD}
											element={<ResetPassword />}
											exact
										/>
										<Route
											path={ROUTE.EVENTS.path}
											route={ROUTE.EVENTS}
											element={<EventContainer />}
											exact
										/>
										<Route
											path={ROUTE.LIBRARY.path}
											route={ROUTE.EVENTS}
											element={<LibraryContainer />}
											exact
										/>
										<Route
											path={ROUTE.SADHNA.path}
											route={ROUTE.SADHNA}
											element={<SadhnaReportContainer />}
											exact
										/>
									</Routes>
								</PrivateRouter>
							}
						></Route>
					</Route>
				</Routes>
			</BrowserRouter>
		)
	}
}

export default RouterComponent
