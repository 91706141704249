import {
	SEND_NOTIFICATION_STARTED,
	SEND_NOTIFICATION_SUCCESS,
	SEND_NOTIFICATION_FAILED,
} from "../constants/actions";
import { ALERT } from "../constants";

let initialState = {
	loading: false,
	error: null,
	alert: null,
	limit: 10000,
};
const notificationReducer = (state = initialState, action) => {
	switch (action.type) {
		case SEND_NOTIFICATION_STARTED:
			return {
				...state,
				loading: true,
			};
		case SEND_NOTIFICATION_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case SEND_NOTIFICATION_FAILED:
			return {
				...state,
				loading: false,
				error: action.error,
				alert: {
					message: action.error,
					severity: ALERT.ERROR,
					duration: 5000,
				},
			};
		default:
			return state;
	}
};

export default notificationReducer;
