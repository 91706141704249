import {
	Button,
	CircularProgress,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Tab,
	Tabs,
	TextField,
	Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Autocomplete } from '@mui/lab'
import React, { Component } from 'react'
import { updateUserAction } from '../../actions/user'
import { connect } from 'react-redux'
import { getAreaDetails } from '../../services/area'
import { resetPassword as resetPasswordService } from '../../services/auth'
import { searchUser } from '../../services/user'
import TabPanel from '../../components/TabPanel'
import {
	CLASS_LEVEL,
	EXPERTISE_AREAS,
	GENDER,
	MARITAL_STATUS,
	PROFESSIONS,
} from '../../constants'
import './style.scss'
import {
	isGenSecretary,
	isAreaLeader,
	isAsstDirector,
	isDirector,
} from '../../utils'
import { format } from 'date-fns'

class UpdateUserPopup extends Component {
	constructor(props) {
		super(props)
		this.state = {
			userId: props?.userId || '',
			email: props?.user?.email || null,
			phone: props?.user?.phone || null,
			connectedArea: props?.user?.connectedAreaId,
			residenceArea: props?.user?.residenceArea,
			chantingRounds: props?.user?.chantingRounds,
			gender: props?.user?.gender,
			maritalStatus: props?.user?.maritalStatus,
			profession: props?.user?.profession,
			expertiseArea: props?.user?.expertiseArea
				? props?.user?.expertiseArea.split(', ')
				: [],
			name: props?.user?.name || '',
			initiatedName: props?.user?.initiatedName || '',
			facilitator: props?.user?.facilitator || '',
			dob: props?.user?.dob && new Date(props?.user?.dob),
			sadhnaGroup: props?.user?.sadhnaGroup,
			mentor: props?.user?.mentor || '',
			tabIndex: 0,
		}
	}

	resetPassword = async () => {
		const { userId } = this.state
		if (userId) {
			await resetPasswordService(userId)
			this.setState({
				resetStatus: true,
			})
		}
	}

	onInputChange = (event, value, type) => {
		const { mentor, facilitator, connectedArea } = this.state
		//response from api
		if ((event, value)) {
			switch (type) {
				case 'connectedArea':
					if (mentor?._id || mentor) {
						getAreaDetails({
							facilitator: facilitator?._id || facilitator,
							per_page: 100,
						}).then((res) => {
							this.setState({
								[`options-${type}`]: res.data.data,
							})
						})
					} else {
						getAreaDetails({ per_page: 100 }).then((res) => {
							this.setState({
								[`options-${type}`]: res.data.data,
							})
						})
					}
					break
				case 'facilitator':
					if (connectedArea?._id || connectedArea) {
						searchUser({
							q: value,
							userRole: 3,
							areaId: connectedArea?._id || connectedArea,
							per_page: 100,
						}).then((res) => {
							this.setState({
								[`options-${type}`]: res.data.data,
							})
						})
					} else {
						searchUser({
							q: value,
							userRole: 3,
							per_page: 100,
						}).then((res) => {
							this.setState({
								[`options-${type}`]: res.data.data,
							})
						})
					}
					break
				case 'mentor':
					searchUser({ q: value, userRole: 3, per_page: 100 }).then(
						(res) => {
							this.setState({
								[`options-${type}`]: res.data.data,
							})
						}
					)
					break
				default:
					getAreaDetails({ per_page: 100 }).then((res) => {
						this.setState({
							[`options-${type}`]: res.data.data,
						})
					})
			}
		}
	}

	onChange = (name, value) => {
		this.setState({
			[name]: value,
		})
	}

	submitUserData = () => {
		const {
			userId,
			email,
			phone,
			connectedArea,
			name,
			initiatedName,
			facilitator,
			residenceArea,
			expertiseArea,
			chantingRounds,
			maritalStatus,
			gender,
			profession,
			dob,
			mentor,
			sadhnaGroup,
		} = this.state
		const { closeHandler, updateUserAction } = this.props
		const { updateUserTableDataHandler } = this.props
		if (name) {
			if (userId) {
				//update user
				this.setState({
					loading: true,
				})
				updateUserAction(userId, {
					name,
					email,
					phone,
					connectedAreaId: connectedArea?._id,
					initiatedName,
					facilitator: facilitator?._id,
					residenceArea,
					expertiseArea: expertiseArea.join(', '),
					chantingRounds,
					maritalStatus,
					gender,
					profession,
					dob: dob && format(dob, 'yyyy-MM-dd'),
					sadhnaGroup,
					mentor: mentor?._id ? mentor._id : this.props.mentor,
				})
					.then((res) => {
						this.setState({
							loading: false,
						})
						updateUserTableDataHandler()
						closeHandler()
					})
					.catch(() => {
						this.setState({
							loading: false,
						})
					})
			} else {
				//create area
			}
		}
	}

	approveUser = () => {
		const { userId } = this.state
		const { closeHandler, updateUserAction } = this.props
		if (userId) {
			//approve user
			updateUserAction(userId, {
				userRole: 1,
			}).then((res) => {
				closeHandler()
			})
		}
	}

	a11yProps = (index) => {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		}
	}

	changeTab = (e, tabIndex) => {
		this.setState({
			tabIndex,
		})
	}

	render() {
		const {
			userId,
			email,
			phone,
			connectedArea,
			name,
			initiatedName,
			facilitator,
			resetStatus,
			residenceArea,
			expertiseArea,
			chantingRounds,
			maritalStatus,
			gender,
			profession,
			dob,
			sadhnaGroup,
			mentor,
		} = this.state
		return (
			<div className="update-user-popup">
				<Typography variant="h5" className="m-bottom-16">
					{userId ? 'Update User' : 'Create User'}
				</Typography>
				<Tabs
					value={this.state.tabIndex}
					onChange={this.changeTab}
					indicatorColor="primary"
					textColor="primary"
					centered
				>
					<Tab label="Basic Info" {...this.a11yProps(1)} />
					<Tab label="Additional Info" {...this.a11yProps(1)} />
				</Tabs>
				<TabPanel value={this.state.tabIndex} index={0}>
					<form
						noValidate
						autoComplete="off"
						className="edit-user-form"
					>
						<TextField
							label="Name"
							variant="outlined"
							defaultValue={name}
							TabPa
							value={name}
							name="name"
							onChange={(e) =>
								this.onChange(e.target.name, e.target.value)
							}
							fullWidth
						/>
						<TextField
							label="Initiated Name"
							variant="outlined"
							defaultValue={initiatedName}
							value={initiatedName}
							name="initiatedName"
							onChange={(e) =>
								this.onChange(e.target.name, e.target.value)
							}
							fullWidth
						/>
						<TextField
							label="Email"
							variant="outlined"
							defaultValue={email}
							value={email}
							name="email"
							onChange={(e) =>
								this.onChange(e.target.name, e.target.value)
							}
							fullWidth
						/>
						<TextField
							label="Phone"
							variant="outlined"
							defaultValue={phone}
							value={phone}
							name="phone"
							onChange={(e) =>
								this.onChange(e.target.name, e.target.value)
							}
							fullWidth
						/>
						<Autocomplete
							options={this.state[`options-facilitator`] || []}
							getOptionLabel={(option) =>
								option.initiatedName || option.name
									? `${option.initiatedName || option.name}` +
									  (option.phone ? ` (${option.phone})` : '')
									: option.initiatedName || option.name
							}
							defaultValue={facilitator}
							filterSelectedOptions
							onInputChange={(e, val) =>
								this.onInputChange(e, val, 'facilitator')
							}
							value={facilitator}
							onChange={(e, newValue) => {
								this.onChange('facilitator', newValue)
							}}
							disabled={!this.state.connectedArea}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									label="Facilitator"
									placeholder="Start typing Facilitator Name"
								/>
							)}
						/>
						<Autocomplete
							options={this.state[`options-connectedArea`] || []}
							getOptionLabel={(option) => option.name || ''}
							defaultValue={connectedArea}
							filterSelectedOptions
							onInputChange={(e, val) =>
								this.onInputChange(e, val, 'connectedArea')
							}
							value={connectedArea}
							onChange={(e, newValue) => {
								this.onChange('connectedArea', newValue)
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									label="Connected Area"
									placeholder="Start typing Area Name"
								/>
							)}
						/>

						<DatePicker
							id="date-picker-dialog"
							label="Date of Birth"
							format="dd/MM/yyyy"
							value={dob}
							inputVariant="outlined"
							onChange={(date) => {
								this.onChange('dob', date)
							}}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
						/>

						<FormControl variant="outlined" fullWidth>
							<InputLabel id="demo-simple-select-outlined-label">
								Class level
							</InputLabel>
							<Select
								value={sadhnaGroup}
								onChange={(e) =>
									this.onChange(e.target.name, e.target.value)
								}
								label="Class level"
								name="sadhnaGroup"
							>
								{Object.keys(CLASS_LEVEL).map((level) => (
									<MenuItem value={level}>
										{CLASS_LEVEL[level]}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</form>
				</TabPanel>
				<TabPanel value={this.state.tabIndex} index={1}>
					<form
						noValidate
						autoComplete="off"
						className="edit-user-form"
					>
						<TextField
							label="Residence Area"
							variant="outlined"
							defaultValue={residenceArea}
							value={residenceArea}
							name="residenceArea"
							onChange={(e) =>
								this.onChange(e.target.name, e.target.value)
							}
							fullWidth
						/>
						<TextField
							label="Chanting Rounds"
							variant="outlined"
							defaultValue={chantingRounds}
							value={chantingRounds}
							name="chantingRounds"
							onChange={(e) =>
								this.onChange(e.target.name, e.target.value)
							}
							fullWidth
						/>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="demo-simple-select-outlined-label">
								Gender
							</InputLabel>
							<Select
								value={gender}
								onChange={(e) =>
									this.onChange(e.target.name, e.target.value)
								}
								label="Gender"
								name="gender"
							>
								{Object.keys(GENDER).map((gen) => (
									<MenuItem value={gen}>
										{GENDER[gen]}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="demo-simple-select-outlined-label">
								Marital Status
							</InputLabel>
							<Select
								value={maritalStatus}
								onChange={(e) =>
									this.onChange(e.target.name, e.target.value)
								}
								label="Marital Status"
								name="maritalStatus"
							>
								{Object.keys(MARITAL_STATUS).map((marStat) => (
									<MenuItem value={marStat}>
										{MARITAL_STATUS[marStat]}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="demo-simple-select-outlined-label">
								Profession
							</InputLabel>
							<Select
								value={profession}
								onChange={(e) =>
									this.onChange(e.target.name, e.target.value)
								}
								label="Profession"
								name="profession"
							>
								{PROFESSIONS.map((prof) => (
									<MenuItem value={prof}>{prof}</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="demo-simple-select-outlined-label">
								Expertise Area
							</InputLabel>
							<Select
								value={expertiseArea}
								onChange={(e) =>
									this.onChange(e.target.name, e.target.value)
								}
								label="Expertise Area"
								name="expertiseArea"
								multiple
							>
								{EXPERTISE_AREAS.map((expArea) => (
									<MenuItem value={expArea}>
										{expArea}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<Autocomplete
							options={this.state[`options-mentor`] || []}
							getOptionLabel={(option) =>
								option.initiatedName || option.name
									? `${option.initiatedName || option.name}` +
									  (option.phone ? ` (${option.phone})` : '')
									: option.initiatedName || option.name
							}
							defaultValue={mentor}
							filterSelectedOptions
							onInputChange={(e, val) =>
								this.onInputChange(e, val, 'mentor')
							}
							value={mentor}
							onChange={(e, newValue) => {
								this.onChange('mentor', newValue)
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									label="Mentor"
									placeholder="Start typing mentor name"
								/>
							)}
						/>
					</form>
				</TabPanel>
				<div className="button-container">
					<Button
						variant="contained"
						color="primary"
						onClick={this.submitUserData}
						disabled={this.state.loading}
					>
						{this.state.loading ? (
							<CircularProgress
								size={22}
								className="mx-5"
								color="white"
							/>
						) : userId ? (
							'UPDATE USER'
						) : (
							'CREATE USER'
						)}
					</Button>
					&nbsp;&nbsp;&nbsp;&nbsp;
					{isGenSecretary() &&
						(isAreaLeader(this.props.user) ||
							isAsstDirector(this.props.user) ||
							isDirector(this.props.user) ||
							isGenSecretary(this.props.user)) && (
							<span className="mr-12">
								{resetStatus ? (
									<span
										style={{ backgroundColor: '#00ff0044' }}
									>
										✔ Password Reset Successfully
									</span>
								) : (
									<Button
										variant="contained"
										color="primary"
										onClick={this.resetPassword}
									>
										RESET PASSWORD
									</Button>
								)}
							</span>
						)}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	loading: state.userReducer.loading,
	alert: state.userReducer.alert,
})

const mapDispatchToProps = {
	updateUserAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUserPopup)
