export const commonActions = [
    { page: "mission", placeholder: "To open coastok mission and vision" },
    { page: "video", placeholder: "To open coastok videos and photos" },
    { page: "hierarchy", placeholder: "To open hierarchy" },
    { page: "duty", placeholder: "To open my duties" },
    { page: "connect_us", placeholder: "To open connect with us screen" },
    { page: "ask", placeholder: "To open ask question screen" },
    {
        page: "question",
        placeholder: "To open my asked question answer screen",
    },
    { page: "add_mentee", placeholder: "To open connect mentee screen" },
    { page: "user_guide", placeholder: "To open user-guide screen" },
    {
        page: "feedback",
        placeholder:
            "Opens pre-filled (subject and to) email to give app feedback",
    },
    {
        page: "connect",
        placeholder: "Opens app and moves to Connect tab (2nd tab)",
    },
    { page: "me", placeholder: "Opens app and moves to Me tab (3rd tab)" },
    { page: "dialog", placeholder: "Opens the content over a dialog box" },
];


export const actionPages = {
    areaLeader: commonActions,
    frontliner: commonActions,
    facilitator: commonActions,
    asstDirector: commonActions,
    coastok: commonActions,
    noncoastok: [
        {
            page: "coastok_sign_in",
            placeholder:
                "Try to coastok sign in, successful if user approved as coastok member",
        },
        { page: "udgaar", placeholder: "Opens Udgaar content screen" },
        { page: "viplava", placeholder: "Opens Viplava content screen" },
        {
            page: "temple_connect",
            placeholder: "Opens Temple Connect content screen",
        },
        {
            page: "value_education",
            placeholder: "Opens Velue Education content screen",
        },
        { page: "camp_yatras", placeholder: "Opens Camp Yatras content screen" },
        {page: "dialog", placeholder: "Opens the content over a dialog box"},
    ],
    all: [
        { page: "edit_profile", placeholder: "To open edit profile screen" },
        {
            page: "update",
            placeholder: "To take user to coastok app update page in playstore",
        },
        {page: "dialog", placeholder: "Opens the content over a dialog box"},
    ],
};