import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import Sidebar from '../Sidebar'
import { ROUTE } from '../../constants/route'
import { useDispatch, useSelector } from 'react-redux'
import './style.scss'
import { getEventDetailsAction } from '../../actions/event'

const PrivateRouter = ({ children: Component, redirectTo, ...props }) => {
	// path,
	// route,
	// userStatus,
	// user,
	// ...rest
	const dispatch = useDispatch()
	const events = useSelector((state) => state.eventReducer)?.events
	const user = useSelector((state) => state.commonContent.user)

	useEffect(() => {
		if (!events?.length) {
			dispatch(getEventDetailsAction())
		}
	}, [events])

	console.log(
		user
		// user &&
		// 	user.roles.some((r) => route.roles && route.roles?.indexOf(r) >= 0)
	)
	if (localStorage.getItem('Token')) {
		return (
			<div className="private-route">
				<Sidebar events={events} {...props} />
				<div className="component-wrapper">{Component}</div>
			</div>
		)
	} else {
		return <Navigate {...props} to={ROUTE.HOME} />
	}
}

export default PrivateRouter
