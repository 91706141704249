/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { getQueryObj } from '../../../../utils'
import { loginByToken } from '../../../../services/auth'
import { useDispatch, useSelector } from 'react-redux'
import './style.scss'
import { getEventDetailsAction } from '../../../../actions/event'
import './css/98-layout.css'
import './css/classic-themes.min.css'
import './css/frontend.css'
import './css/print.css'
import './css/rs6.css'
import './css/style.css'
import './css/style.min.css'
import './css/wpcdt-public.css'
import './css/zn_dynamic.css'
import './css/znb_frontend.css'
import useEventIdFromParams from '../../../../hooks/useEventIdFromParams'
import { EVENTS_UI_CONFIG } from '../../../../data/event'
import useCounter from '../../../../hooks/useCounter'
import useBookNow from '../../useBookNow'
import { format } from 'date-fns'

const activities = [
	{
		// hidden
		image: 'https://1.bp.blogspot.com/-FJF9sioQu4Q/WQrK2uNuRQI/AAAAAAAAR1g/yku33aur8dQfiXnq0pdAP2uAa5W85_5kwCK4B/s1600/Ayodhya%2BTour%2BBSS%2BCamp%2BApril%2B2017%2B%252876%2529.JPG',
		name: 'Nand Ghat',
		place: '',
	},
	{
		image: 'https://res.cloudinary.com/dm1o3cvik/image/upload/v1725893063/braja/1000_F_370716921_8sdvA1TRJ9D1eSn8M3nlOjlenjIigbJe_zllezb.jpg',
		name: 'Ram Ghat',
		place: '',
	},
	{
		image: 'https://i.ytimg.com/vi/gjquXWtgckA/maxresdefault.jpg?sqp=-oaymwEmCIAKENAF8quKqQMa8AEB-AH-CYAC0AWKAgwIABABGHIgTCg9MA8=&rs=AOn4CLC7XOyAZaqPkwjnVTyObesTOXvlWg',
		name: 'Delicious Prasadam',
		place: '',
	},
	{
		image: 'https://2.bp.blogspot.com/-voDvH9KF9-k/T-Bf-JfairI/AAAAAAAACZQ/tWvSpAN6t04/s1600/harinam+2.jpg',
		name: 'Seshasayee',
		place: '',
	},
	{
		image: 'https://res.cloudinary.com/dm1o3cvik/image/upload/v1725893063/braja/45619131_2071558892888088_283334306707800064_n_lvvtrc.jpg',
		name: 'Vihar Van',
		place: '',
	},
	{
		image: 'https://res.cloudinary.com/dm1o3cvik/image/upload/v1725948631/braja/krishna-balarama_eucfmh.jpg',
		name: 'Vatsavan',
		place: '',
	},
	{
		// Ignore
		image: '',
		name: 'Daksh Prajapati temple',
		place: '',
	},
	{
		image: 'https://res.cloudinary.com/dm1o3cvik/image/upload/v1725893062/braja/IMG_20220417_135831_237_icfzaa.jpg',
		name: 'Cheer Ghat',
		place: '',
	},
	{
		image: 'https://res.cloudinary.com/dm1o3cvik/image/upload/v1725948532/braja/12_v7tqfp.jpg',
		name: 'Seshasayee',
		place: '',
	},
	{
		// Ignoe
		image: '',
		name: 'Ganga Snan 3',
		place: 'Triveni Ghat',
	},
]

const BG_IMAGE =
	'https://res.cloudinary.com/dm1o3cvik/image/upload/v1725893062/braja/67114057-8f45-4d68-b079-8a1136c3be9d_aduxmn.jpg'

const HaridwarCamp = ({ history, location, eventId: eventIdFromProps }) => {
	const eventIdFromParam = useEventIdFromParams()
	const eventId = eventIdFromParam || eventIdFromProps

	// const data = useSelector((state) => state)
	const eventFromUI = EVENTS_UI_CONFIG.find((config) => config.id === eventId)
	// const eventApiConfig = data.eventReducer.events.find(
	// 	(event) => event.eventId === eventFromUI.uuid
	// )
	// const isAnnualYatra = false

	const counter = useCounter(eventFromUI.deadline)

	const dispatch = useDispatch()

	const { handleRegisterBtnClick } = useBookNow()

	// const isSpsCamp = () => eventFromUI.id.includes('sps')

	const isBssCamp = () => eventFromUI.id.includes('bss')

	// const CAMP_GALARY = []

	// const getPlaceName = () => 'Haridwar'

	useEffect(() => {
		const data = getQueryObj(location?.search)
		if (data.token) {
			loginByToken(data.token).then((res) => {
				const userProfile = res.data[0]
				userProfile.loggedIn = true
				localStorage.setItem('UserProfile', JSON.stringify(userProfile))
			})
		}
	}, [])

	const events = useSelector((state) => state.eventReducer)?.events

	useEffect(() => {
		if (!events?.length) {
			dispatch(getEventDetailsAction())
		}
	}, [])

	return (
		<div>
			<div className="login_register_stuff" />
			{/* end login register stuff */} <div id="fb-root" />
			<div id="page_wrapper">
				<div
					className="zn_pb_wrapper clearfix zn_sortable_content"
					data-droplevel={0}
				>
					{' '}
					<section
						className="zn_section eluidf73c7c54   zn_parallax  section-sidemargins    zn_section--relative section--no "
						id="eluidf73c7c54"
					>
						<div className="zn-bgSource zn-bgSource-imageParallax js-znParallax">
							<div
								className="zn-bgSource-image"
								style={{
									backgroundImage: `url(${BG_IMAGE})`,
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'center center',
									backgroundSize: 'cover',
								}}
							/>
							<div
								className="zn-bgSource-overlay"
								style={{ backgroundColor: 'rgba(5,0,62,0.7)' }}
							/>
						</div>
						<div className="zn_section_size container zn-section-height--custom_height zn-section-content_algn--top ">
							<div className="row gutter-0">
								<div
									className="eluid3151d108            col-md-12 col-sm-12   znColumnElement"
									id="eluid3151d108"
								>
									<div className="znColumnElement-innerWrapper-eluid3151d108 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div
											className="znColumnElement-innerContent"
											style={{
												display: 'flex',
												flexDirection: 'column',
											}}
										>
											{' '}
											<p
												className="eluid08c6f97a  dn-heading mb-3"
												id="eluid08c6f97a"
												itemProp="headline"
											>
												{eventFromUI.heading
													?.split(' ')
													.slice(0, 1)
													.join(' ')
													.toUpperCase()}
											</p>
											<p
												className="eluid08c6f97a  dn-heading mb-3"
												id="eluid08c6f97a"
												itemProp="headline"
											>
												{eventFromUI.heading
													?.split(' ')
													.slice(1)
													.join(' ')
													?.toUpperCase()}
											</p>
											<h1
												className="eluid142ad9e4  dn-heading my-3"
												id="eluid142ad9e4"
												itemProp="headline"
											>
												{eventFromUI.title?.toUpperCase()}
												{/* &amp; 
												JAGANNATH PURI */}
											</h1>
											<h1
												className="eluid51c10575  dn-heading mt-3"
												id="eluid51c10575"
												itemProp="headline"
											>
												{eventFromUI?.dates}
											</h1>
											<h4
												className="eluid08c6f97a my-5"
												style={{
													textAlign: 'center',
													fontSize: '14px',
													display: 'inline',
													color: 'gold',
													letterSpacing: '1px',
												}}
												id="eluid51c10575"
												itemProp="headline"
											>
												Registration will close on
											</h4>
											<h4
												className="eluid08c6f97a my-5"
												id="eluid51c10575"
												itemProp="headline"
											>
												{format(
													new Date(
														eventFromUI.deadline
													),
													'MMMM do yyyy'
												)}
											</h4>
											<div
												id="eluid483ea049"
												className="zn_buttons_element eluid483ea049 text-center mt-4"
												style={{
													marginTop: '35px',
												}}
											>
												<a
													// href="#Mayapur"
													id="eluid483ea0490"
													className="eluid483ea0490 btn-element btn-element-0 btn  btn-fullcolor btn-custom-color   zn_dummy_value btn-icon--before btn--square"
													target="_self"
													itemProp="url"
													onClick={(...args) =>
														handleRegisterBtnClick(
															eventFromUI,
															...args
														)
													}
												>
													<span>BOOK NOW</span>
												</a>
											</div>{' '}
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						className="zn_section eluide5ee4e5d     section-sidemargins    section--no "
						id="eluide5ee4e5d"
					>
						<div className="zn_section_size container zn-section-height--auto zn-section-content_algn--top ">
							<div className="row gutter-0">
								<div
									className="eluid93c3ea30            col-md-12 col-sm-12   znColumnElement"
									id="eluid93c3ea30"
								>
									<div className="znColumnElement-innerWrapper-eluid93c3ea30 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<h3
												className="eluid6c367734  dn-heading"
												id="eluid6c367734"
												itemProp="headline"
											>
												DAYS REMAINING:
											</h3>
											<div
												id="eluid8e6e3cba"
												className="ud_counter kl-counter kl-font-alt eluid8e6e3cba text-center "
											>
												{' '}
												<ul className="th-counter kl-counterlist counter-value">
													<li className="kl-counter-li">
														<span className="kl-counter-value ">
															{counter?.days}
														</span>
														<span className="kl-counter-unit ">
															DAYS
														</span>
													</li>
													<li className="kl-counter-li">
														<span className="kl-counter-value ">
															{counter?.hours}
														</span>
														<span className="kl-counter-unit ">
															HOURS
														</span>
													</li>
													<li className="kl-counter-li">
														<span className="kl-counter-value ">
															{counter?.min}
														</span>
														<span className="kl-counter-unit ">
															MIN
														</span>
													</li>
													<li className="kl-counter-li">
														<span className="kl-counter-value ">
															{counter?.sec}
														</span>
														<span className="kl-counter-unit ">
															SEC
														</span>
													</li>{' '}
												</ul>
											</div>{' '}
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<div className="znColumnElement-innerWrapper-eluid45521b11 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
						<div className="znColumnElement-innerContent">
							{' '}
							<div className="media-container eluidd816dbd6  media-container--type-btn kl-overlay-none  ">
								{eventFromUI.trailerLink && (
									<>
										<div className="zn-bgSource ">
											<div
												className="zn-bgSource-image"
												style={{
													backgroundImage:
														'url(https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/Mayapur-Jagannath-Puri-Yatra.jpg)',
													backgroundRepeat:
														'no-repeat',
													backgroundPosition:
														'center center',
													backgroundSize: 'cover',
													backgroundAttachment:
														'scroll',
												}}
											/>
											<div
												className="zn-bgSource-overlay"
												style={{
													backgroundColor:
														'rgba(5,0,62,0.7)',
												}}
											/>
										</div>{' '}
										<a
											className="media-container__link media-container__link--btn media-container__link--style-circle "
											href={eventFromUI.trailerLink}
											data-lightbox="iframe"
										>
											<div className="circleanim-svg">
												<svg
													height={108}
													width={108}
													xmlns="http://www.w3.org/2000/svg"
												>
													<circle
														strokeOpacity="0.1"
														fill="#FFFFFF"
														strokeWidth={5}
														cx={54}
														cy={54}
														r={48}
														className="circleanim-svg__circle-back"
													/>
													<circle
														strokeWidth={5}
														fill="#FFFFFF"
														cx={54}
														cy={54}
														r={48}
														className="circleanim-svg__circle-front"
														transform="rotate(50 54 54) "
													/>
													<path
														d="M62.1556183,56.1947505 L52,62.859375 C50.6192881,63.7654672 49.5,63.1544098 49.5,61.491212 L49.5,46.508788 C49.5,44.8470803 50.6250889,44.2383396 52,45.140625 L62.1556183,51.8052495 C64.0026693,53.0173767 63.9947588,54.9878145 62.1556183,56.1947505 Z"
														fill="#FFFFFF"
													/>
												</svg>
											</div>{' '}
										</a>
									</>
								)}
								{eventFromUI.posterImage && (
									<div className="text-center flex justify-center w-100">
										<img
											src={eventFromUI.posterImage}
											classe="self-center"
										/>
									</div>
								)}
							</div>
						</div>
					</div>
					<section
						className="zn_section eluide9b9c256     section-sidemargins    section--no mt-10"
						id="eventsviplava"
					>
						<div className="zn_section_size full_width zn-section-height--auto zn-section-content_algn--top ">
							<div className="row gutter-0">
								<div
									className="eluidb10bd2a5            col-md-3 col-sm-3   znColumnElement"
									id="eluidb10bd2a5"
								>
									<div className="znColumnElement-innerWrapper-eluidb10bd2a5 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-center znColumnElement-innerWrapper--halign-left ">
										<div
											className="znColumnElement-innerContent"
											style={{ maxHeight: '1000px' }}
										>
											{' '}
											<div
												style={{
													minHeight: '500px',
												}}
											>
												<h3
													className="eluidae1898ac  dn-heading"
													id="eluidae1898ac"
													itemProp="headline"
												>
													ACTIVITIES
												</h3>
												<h3
													className="eluide74a2c16  dn-heading"
													id="eluide74a2c16"
													itemProp="headline"
												>
													ACTIVITIES
												</h3>
												<h3
													className="eluid072fa61e  dn-heading"
													id="eluid072fa61e"
													itemProp="headline"
												>
													{eventFromUI.title}
												</h3>
												<h3
													className="eluidc1846163  dn-heading"
													id="eluidc1846163"
													itemProp="headline"
												>
													<a
														href="#"
														className="dn-headingLink"
														target="_self"
														itemProp="url"
													>
														<strong>
															Special:
														</strong>
														<br /> Ram Ghat, <br />
														Vihar Van, <br />
														Cheer Ghat, Nand Ghat,{' '}
														<br />
														Seshasayee temple
														<br />
														Vatsavan, <br />
														Prasadam etc. <br />
														<br />
													</a>
												</h3>{' '}
											</div>
											{activities?.[0] && (
												<div className="media-container eluid1a07545b  media-container--type-pb kl-overlay-none  ">
													<div className="zn-bgSource ">
														<div
															className="zn-bgSource-image"
															style={{
																backgroundImage: `url(${activities?.[0]?.image})`,
																backgroundRepeat:
																	'no-repeat',
																backgroundPosition:
																	'center',
																backgroundSize:
																	'cover',
																backgroundAttachment:
																	'scroll',
															}}
														/>
														<div
															className="zn-bgSource-overlay"
															style={{
																backgroundColor:
																	'rgba(168,39,170,0.5)',
															}}
														/>
													</div>
													<div className="media-container-pb media-container-pb--alg-bottom">
														<div className="row zn_col_container-media_container ">
															<div
																className="eluidbb62d562            col-md-12 col-sm-12   znColumnElement"
																id="eluidbb62d562"
															>
																<div className="znColumnElement-innerWrapper-eluidbb62d562 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
																	<div className="znColumnElement-innerContent">
																		{' '}
																		<h3
																			className="eluid5aefb679  dn-heading"
																			id="eluid5aefb679"
																			itemProp="headline"
																		>
																			{
																				activities?.[0]
																					?.name
																			}
																		</h3>
																		<h3
																			className="eluid4745c165  dn-heading"
																			id="eluid4745c165"
																			itemProp="headline"
																		>
																			{
																				activities?.[0]
																					?.place
																			}
																		</h3>{' '}
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
								{activities
									?.slice(1)
									?.reduce(
										(accum, val) => {
											if (accum.acc.length < 2) {
												accum.acc.push(val)
											} else {
												accum.final.push([...accum.acc])
												accum.acc = []
											}
											return accum
										},
										{ acc: [], final: [] }
									)
									?.final?.map((activityPair) => (
										<div
											className="eluid0298d50d            col-md-3 col-sm-3   znColumnElement"
											id="eluid0298d50d"
										>
											<div className="znColumnElement-innerWrapper-eluid0298d50d znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
												<div className="znColumnElement-innerContent">
													{' '}
													<div className="media-container eluid395cb513  media-container--type-pb kl-overlay-none  ">
														<div className="zn-bgSource ">
															<div
																className="zn-bgSource-image"
																style={{
																	backgroundImage: `url(${activityPair[0].image})`,
																	backgroundRepeat:
																		'no-repeat',
																	backgroundPosition:
																		'center',
																	backgroundSize:
																		'cover',
																	backgroundAttachment:
																		'scroll',
																}}
															/>
															<div
																className="zn-bgSource-overlay"
																style={{
																	backgroundColor:
																		'rgba(53,53,53,0.65)',
																}}
															/>
														</div>
														<div className="media-container-pb media-container-pb--alg-bottom">
															<div className="row zn_col_container-media_container ">
																<div
																	className="eluid7df5957e            col-md-12 col-sm-12   znColumnElement"
																	id="eluid7df5957e"
																>
																	<div className="znColumnElement-innerWrapper-eluid7df5957e znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
																		<div className="znColumnElement-innerContent">
																			{' '}
																			<h3
																				className="eluid0f822b88  dn-heading"
																				id="eluid0f822b88"
																				itemProp="headline"
																			>
																				{
																					activityPair[0]
																						.name
																				}
																			</h3>
																			<h3
																				className="eluidd36876a5  dn-heading"
																				id="eluidd36876a5"
																				itemProp="headline"
																			>
																				{/* Puri
																		&amp; */}
																				{
																					activityPair[0]
																						.place
																				}
																			</h3>{' '}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="media-container eluiddab10e3d  media-container--type-pb kl-overlay-none  ">
														<div className="zn-bgSource ">
															<div
																className="zn-bgSource-image"
																style={{
																	backgroundImage: `url(${activityPair[1].image})`,
																	backgroundRepeat:
																		'no-repeat',
																	backgroundPosition:
																		'center center',
																	backgroundSize:
																		'cover',
																	backgroundAttachment:
																		'scroll',
																}}
															/>
															<div
																className="zn-bgSource-overlay"
																style={{
																	backgroundColor:
																		'rgba(54,81,237,0.45)',
																}}
															/>
														</div>
														<div className="media-container-pb media-container-pb--alg-bottom">
															<div className="row zn_col_container-media_container ">
																<div
																	className="eluid26947b3d            col-md-12 col-sm-12   znColumnElement"
																	id="eluid26947b3d"
																>
																	<div className="znColumnElement-innerWrapper-eluid26947b3d znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
																		<div className="znColumnElement-innerContent">
																			{' '}
																			<h3
																				className="eluid1f006f84  dn-heading"
																				id="eluid1f006f84"
																				itemProp="headline"
																			>
																				{
																					activityPair[1]
																						.name
																				}
																			</h3>
																			<h3
																				className="eluid6b496da4  dn-heading"
																				id="eluid6b496da4"
																				itemProp="headline"
																			>
																				{
																					activityPair[1]
																						.place
																				}
																			</h3>{' '}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					</section>
					<section
						className="zn_section eluidd0d1dfd3     section-sidemargins    section--no "
						id="details"
					>
						<div className="zn_section_size container zn-section-height--auto zn-section-content_algn--top ">
							<div className="row ">
								<div
									className="eluidb1c8ee5f            col-md-4 col-sm-4   znColumnElement"
									id="eluidb1c8ee5f"
								>
									<div className="znColumnElement-innerWrapper-eluidb1c8ee5f znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<div className="image-boxes imgbox-simple eluida94919f9 ">
												<a
													href="#Mayapur"
													className="image-boxes-link imgboxes-wrapper u-mb-0  "
													target="_self"
													itemProp="url"
												>
													<div className="image-boxes-img-wrapper img-align-center">
														<img
															className="image-boxes-img img-responsive "
															src="https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/IYF-Delhi-COASTOK-Mayapur-Yatra-2023.jpg"
															alt=""
															title="IYF Delhi - COASTOK Mayapur Yatra 2023"
														/>
													</div>
												</a>
											</div>{' '}
										</div>
									</div>
								</div>
								<div
									className="eluidbf264391            col-md-4 col-sm-4   znColumnElement"
									id="eluidbf264391"
								>
									<div className="znColumnElement-innerWrapper-eluidbf264391 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<div className="image-boxes imgbox-simple eluid6c35cf93 ">
												<a
													href="#Mayapur"
													className="image-boxes-link imgboxes-wrapper u-mb-0  "
													target="_self"
													itemProp="url"
												>
													<div className="image-boxes-img-wrapper img-align-center">
														<img
															className="image-boxes-img img-responsive "
															src="https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/IYF-Delhi-COASTOK-Jgannath-Puri-Mayapur-Yatra-2023.jpg"
															alt=""
															title="IYF Delhi - COASTOK Jgannath Puri Mayapur Yatra 2023"
														/>
													</div>
												</a>
											</div>{' '}
										</div>
									</div>
								</div>
								<div
									className="eluidc96d085b            col-md-4 col-sm-4   znColumnElement"
									id="eluidc96d085b"
								>
									<div className="znColumnElement-innerWrapper-eluidc96d085b znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<div className="image-boxes imgbox-simple eluid74525342 ">
												<a
													href="#Puri"
													className="image-boxes-link imgboxes-wrapper u-mb-0  "
													target="_self"
													itemProp="url"
												>
													<div className="image-boxes-img-wrapper img-align-center">
														<img
															className="image-boxes-img img-responsive "
															src="https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/IYF-Delhi-COASTOK-Jgannath-Puri-Yatra-2023.jpg"
															alt=""
															title="IYF Delhi - COASTOK Jgannath Puri Yatra 2023"
														/>
													</div>
												</a>
											</div>{' '}
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						className="zn_section eluidbbe439a1   zn_parallax  section-sidemargins    zn_section--relative section--no "
						id="Mayapur"
					>
						<div className="zn-bgSource zn-bgSource-imageParallax js-znParallax">
							<div
								className="zn-bgSource-image"
								style={{
									backgroundImage:
										'url(https://iyfdelhi.com/viplava2022/wp-content/uploads/2022/12/Mayapur.jpg)',
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'center center',
									backgroundSize: 'cover',
								}}
							/>
							<div
								className="zn-bgSource-overlay"
								style={{
									backgroundColor: 'rgba(5,0,62,0.7)',
								}}
							/>
						</div>
						<div className="zn_section_size container zn-section-height--auto zn-section-content_algn--top ">
							<div className="row gutter-0">
								<div
									className="eluid8147b945            col-md-12 col-sm-12   znColumnElement"
									id="eluid8147b945"
								>
									<div className="znColumnElement-innerWrapper-eluid8147b945 znColumnElement-innerWrapper znColumnElement-innerWrapper--valign-top znColumnElement-innerWrapper--halign-left ">
										<div className="znColumnElement-innerContent">
											{' '}
											<h3
												className="eluid3f9d1ca5  dn-heading"
												id="eluid3f9d1ca5"
												itemProp="headline"
											>
												{eventFromUI?.dates}
											</h3>
											<h3
												className="eluideff4d654  dn-heading"
												id="eluideff4d654"
												itemProp="headline"
											>
												{eventFromUI?.title}
											</h3>
											{true && (
												<h3
													className="eluidccba8740  dn-heading"
													id="eluidccba8740"
													itemProp="headline"
												>
													<strong>
														Dormitory (Without
														Travel)
													</strong>{' '}
													₹2000/-
													<br />
													<strong>
														Room (4 devotees in a
														room) (Without Travel)
													</strong>{' '}
													₹2500/-
													{/* <br />
													<strong>
														Room with Transportation
													</strong>{' '}
													₹2500/-
													<br />
													<strong>
														Room without
														Transportation
													</strong>{' '}
													₹1900/-
													<br /> */}
												</h3>
											)}
											<div
												id="eluidbda8a035"
												className="zn_buttons_element eluidbda8a035 text-center mt-6"
											>
												<a
													href="#"
													id="eluidbda8a0350"
													className="eluidbda8a0350 btn-element btn-element-0 btn  btn-fullcolor btn-custom-color   zn_dummy_value btn-icon--before btn--square"
													target="_self"
													itemProp="url"
													onClick={(...args) =>
														handleRegisterBtnClick(
															eventFromUI,
															...args
														)
													}
												>
													<span>BOOK NOW</span>
												</a>
											</div>{' '}
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<div className="clearfix" />
				</div>{' '}
				<footer
					id="footer"
					className="site-footer"
					role="contentinfo"
					itemScope="itemscope"
					itemType="https://schema.org/WPFooter"
				>
					<div className="container">
						<div className="row">
							<div className="col-sm-12">
								<div className="bottom site-footer-bottom clearfix">
									<div className="zn_footer_nav-wrapper">
										<ul
											id="menu-event-conference-menu-1"
											className="footer_nav"
										>
											<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-24">
												<a href="#aboutviplava">
													ABOUT EVENT
												</a>
											</li>
											<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-25">
												<a href="#eventsviplava">
													EVENTS
												</a>
											</li>
											<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-27">
												<a href="#details">DETAILS</a>
											</li>
											<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-95">
												<a href="https://coastok.com/event/jagannath-puri-mayapur-camp">
													REGISTER NOW
												</a>
											</li>
										</ul>
									</div>
									<div className="copyright footer-copyright">
										<p className="footer-copyright-text">
											© 2022. All rights reserved.{' '}
											<a href="https://iyfdelhi.com">
												IYF Delhi
											</a>
											.
										</p>{' '}
									</div>
									{/* end copyright */}
								</div>
								{/* end bottom */}
							</div>
						</div>
						{/* end row */}
					</div>
				</footer>
			</div>
			{/* end page_wrapper */}
		</div>
	)
}

export default HaridwarCamp
