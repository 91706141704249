import {
	SEND_NOTIFICATION_STARTED,
	SEND_NOTIFICATION_SUCCESS,
	SEND_NOTIFICATION_FAILED,
} from "../constants/actions";
import { sendNotification } from "../services/notification";
import { extractErrorStr } from "../utils/network";
import { actionCreator } from "../utils/redux";

export const sendBulkNotificationAction = (notificationData) => (dispatch) => {
	dispatch(actionCreator(SEND_NOTIFICATION_STARTED));
	return sendNotification(notificationData)
		.then((json) => {
			dispatch(actionCreator(SEND_NOTIFICATION_SUCCESS, json.data[0]));
			return json;
		})
		.catch((error) => {
			dispatch(actionCreator(SEND_NOTIFICATION_FAILED, extractErrorStr(error)));
			return error;
		});
};
