import {
	GET_PLEDGE_DETAIL_FAILED,
	GET_PLEDGE_DETAIL_STARTED,
	GET_PLEDGE_DETAIL_SUCCESS,
	GET_PLEDGE_SUMMARY_FAILED,
	GET_PLEDGE_SUMMARY_STARTED,
	GET_PLEDGE_SUMMARY_SUCCESS,
	GET_PLEDGE_USERS_FAILED,
	GET_PLEDGE_USERS_STARTED,
	GET_PLEDGE_USERS_SUCCESS,
} from "../constants/actions";
import {
	getPledgeDetails,
	getPledgeSummary,
	getPledgeUsers,
} from "../services/pledging";
import { extractErrorStr } from "../utils/network";
import { actionCreator } from "../utils/redux";

export const getPledgeAction = () => (dispatch) => {
	dispatch(actionCreator(GET_PLEDGE_DETAIL_STARTED));
	return getPledgeDetails()
		.then((json) => {
			dispatch(actionCreator(GET_PLEDGE_DETAIL_SUCCESS, json.data));
			return json;
		})
		.catch((error) => {
			dispatch(actionCreator(GET_PLEDGE_DETAIL_FAILED, extractErrorStr(error)));
			return error;
		});
};

export const getPledgeSummaryAction = (params) => (dispatch) => {
	dispatch(actionCreator(GET_PLEDGE_SUMMARY_STARTED));
	return getPledgeSummary(params)
		.then((json) => {
			dispatch(
				actionCreator(GET_PLEDGE_SUMMARY_SUCCESS, json.data, {
					pledgeId: params.pledgeId,
				})
			);
			return json;
		})
		.catch((error) => {
			dispatch(
				actionCreator(GET_PLEDGE_SUMMARY_FAILED, extractErrorStr(error))
			);
			return error;
		});
};

export const getPledgeUsersAction = (params) => (dispatch) => {
	dispatch(actionCreator(GET_PLEDGE_USERS_STARTED));
	return getPledgeUsers(params)
		.then((json) => {
			dispatch(
				actionCreator(GET_PLEDGE_USERS_SUCCESS, json.data, {
					pledgeId: params.pledgeId,
					areaId: params.areaId,
				})
			);
			return json;
		})
		.catch((error) => {
			dispatch(actionCreator(GET_PLEDGE_USERS_FAILED, extractErrorStr(error)));
			return error;
		});
};
