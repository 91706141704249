import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import { calculateTotal, getAmounts } from '../utils'
import '../style.scss'
import { EVENTS_UI_CONFIG } from '../../../data/event'
import DonationModal from './DonationModal'
import AccommodationAvailabilityWrapper from './AccomodationAvailabilityWrapper'
import { getReferrer } from '../../../utils'
import { BSS_CAMP_ATTENDED, EXPECTED_PBT } from '../../../constants'

const PREFIX = 'AdditionalInfoScreen'

const classes = {
	root: `${PREFIX}-root`,
	modal: `${PREFIX}-modal`,
	paper: `${PREFIX}-paper`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
	[`& .${classes.root}`]: {
		flexGrow: 1,
		minWidth: 300,
		display: 'flex',
		flexDirection: 'column',
		rowGap: '14px',
		transform: 'translateZ(0)',
		'@media all and (-ms-high-contrast: none)': {
			display: 'none',
		},
	},

	[`& .${classes.modal}`]: {
		display: 'flex',
		// padding: theme.spacing(8),
		alignItems: 'center',
		justifyContent: 'center',
	},

	[`& .${classes.paper}`]: {
		width: 400,
		// backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		borderRadius: '8px',
		// boxShadow: theme.shadows[5],
		// padding: theme.spacing(8, 24, 16),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
}))

const ROOM_INFO = {
	1: 'Dormitory ( Hall - 10 To 100 occupancy , Common Bathrooms)',
	2: 'Room (Non AC Room, 3 To 4 occupancy, Attached Bathroom)',
	3: `Premium Room( AC Room , Double Occupancy , attached
	bathroom)`,
}

const RoomInfo = ({ accomodationCategory, roomInfo } = {}) => {
	if (!accomodationCategory) return null
	return (
		<div>
			<Typography variant={'h5'}>Accomodation</Typography>
			{Object.values(accomodationCategory).map((accomodation, i) => (
				<p key={i}>
					<b>
						{roomInfo[accomodation?.id]} ₹{accomodation?.price}
					</b>
				</p>
			))}
		</div>
	)
}

const isAccomodationDisabled = (
	key,
	accomodationCategory,
	accomodationAvailability
) => {
	return accomodationAvailability?.[accomodationCategory[key].id]?.left === 0
}

const getAccomodationName = (
	key,
	accomodationCategory,
	accomodationAvailability
) => {
	let accommodationString = ''
	if (accomodationAvailability) {
		accommodationString = `(Available : ${
			accomodationAvailability[accomodationCategory[key].id]?.left ?? 0
		}) `
		// / ${
		// 	accomodationAvailability[accomodationCategory[key].id]?.total ?? 0
		// })
	}
	return `${accomodationCategory[key].name} ${accommodationString}`
}

class AdditionalInfo extends React.Component {
	constructor(props) {
		super(props)
		const eventId = this.props.eventId
		const eventUiconfig = EVENTS_UI_CONFIG.find(
			(event) => event.uuid === eventId
		)

		this.state = {
			...props.booking,
			transportation: !isNaN(props.booking?.transportationAmount)
				? props.booking?.transportationAmount > 0
				: false,
			accomodation: props.booking?.accomodationCategory ?? 1, // Default dormitory
			donation: 0,
			outfit: props.booking?.outfitCount ?? 0,
			donationModal: false,
			event: props.event,
			tokenAmount:
				eventUiconfig?.confirmationCharge ??
				props.totalAmount ??
				props.booking?.tokenAmount,

			userDetails: props.userDetails,
			referrer: getReferrer(),

			cf1_value: props.booking?.cf1_value || '',
			cf2_value: props.booking?.cf2_value || '',
		}
	}
	a11yProps = (index) => {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		}
	}

	onChange = (name, value) => {
		const { updateAmount } = this.props
		const amounts = getAmounts({ ...this.state, [name]: value })
		updateAmount(calculateTotal(amounts))
		this.setState({
			[name]: value,
		})
	}

	componentDidMount() {
		const { updateAmount } = this.props
		const amounts = getAmounts({ ...this.state })
		updateAmount(calculateTotal(amounts))
	}

	componentDidUpdate(prevProps, nextProps) {
		// if (prevProps.totalAmount !== this.props.totalAmount) {
		// 	this.onChange('tokenAmount', this.props.totalAmount)
		// }
	}

	onProceedToPay = (eventUiconfig) => () => {
		const {
			transportation,
			accomodation,
			outfit,
			tokenAmount,
			donation,
			referrer,
			cf1_value,
			cf2_value,
			agree,
		} = this.state
		if (
			((eventUiconfig.basicInputs.find(
				(el) => el.id === 'cf1_value' || el.id === 'cf2_value'
			) &&
				(!cf1_value || !cf2_value)) ||
				!agree) &&
			eventUiconfig.extraValidation
		) {
			this.setState({
				isSubmitted: true,
			})
			return
		}
		if (eventUiconfig.allowDonation) {
			this.setState({
				donationModal: true,
			})
		} else {
			if (tokenAmount >= eventUiconfig?.confirmationCharge) {
				this.props.onSubmit({
					...getAmounts(this.state),
					accomodationCategory: accomodation,
					outfitCount: outfit,
					transportationAmount: transportation ? 1 : 0,
					tokenAmount,
					donation,
					referrer,
					cf1_value,
					cf2_value,
				})
			}
		}
	}

	render() {
		const {
			transportation,
			accomodation,
			outfit,
			donationModal,
			tokenAmount,
			referrer,
			cf1_value,
			cf2_value,
			agree,
		} = this.state
		const { event } = this.props
		const eventId = this.props.eventId
		const eventUiconfig = EVENTS_UI_CONFIG.find(
			(event) => event.uuid === eventId
		)
		const accomodationOptions =
			event?.accomodationCategory &&
			Object.keys(event.accomodationCategory)
		const referrerFromParam = getReferrer()
		return (
			<Root>
				<div className={classes.root}>
					<DonationModal
						visible={donationModal}
						onClose={() =>
							this.setState({
								donationModal: false,
							})
						}
						onSubmit={(donation) =>
							this.props.onSubmit({
								...getAmounts(this.state),
								accomodationCategory: accomodation,
								outfitCount: outfit,
								donation,
							})
						}
					/>

					{eventUiconfig.additionalInputs.find(
						(input) => input.id === 'accomodation'
					) && (
						<AccommodationAvailabilityWrapper
							eventId={eventId}
							userDetails={this.state.userDetails}
						>
							{(accomodationAvailability) => (
								<>
									<RoomInfo
										accomodationCategory={
											event?.accomodationCategory
										}
										roomInfo={
											eventUiconfig?.roomInfo ?? ROOM_INFO
										}
									/>
									<FormControl variant="outlined" fullWidth>
										<InputLabel id="demo-simple-select-outlined-label">
											Accomodation
										</InputLabel>
										<Select
											value={accomodation}
											onChange={(e) =>
												this.onChange(
													e.target.name,
													e.target.value
												)
											}
											label="Accomodation"
											name="accomodation"
										>
											{accomodationOptions?.map(
												(key, i) => (
													<MenuItem
														value={
															event
																?.accomodationCategory[
																key
															].id
														}
														key={i}
														disabled={
															eventUiconfig?.showAvailability
																? isAccomodationDisabled(
																		key,
																		event?.accomodationCategory,
																		accomodationAvailability
																  )
																: false
														}
													>
														{getAccomodationName(
															key,
															event?.accomodationCategory,
															eventUiconfig?.showAvailability
																? accomodationAvailability
																: null
														)}
													</MenuItem>
												)
											)}
										</Select>
									</FormControl>
								</>
							)}
						</AccommodationAvailabilityWrapper>
					)}
					{eventUiconfig.additionalInputs.find(
						(input) => input.id === 'transportation'
					) && (
						<>
							{!eventUiconfig?.hideTransportationLabel && (
								<Typography variant={'h5'}>
									Transportation
								</Typography>
							)}
							<p>
								<b>
									{eventUiconfig.transportationMessage ||
										event?.transportationDescription}
								</b>
							</p>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="demo-simple-select-outlined-label">
									{!eventUiconfig?.hideTransportationLabel
										? 'Transportation'
										: 'NCR ?'}
								</InputLabel>
								<Select
									value={transportation}
									onChange={(e) =>
										this.onChange(
											e.target.name,
											e.target.value
										)
									}
									label="Transportation"
									name="transportation"
								>
									<MenuItem value={true}>Yes</MenuItem>
									<MenuItem value={false}>No</MenuItem>
								</Select>
							</FormControl>
						</>
					)}
					{!!event &&
						!!event.outfitAmount &&
						eventUiconfig.showOutfit && (
							<Typography variant={'h5'}>Outfit</Typography>
						)}
					{!!event &&
						!!event.outfitCutAmount &&
						eventUiconfig.showOutfit && (
							<p>
								<b>
									Dhoti Kurta worth{' '}
									<strike>₹{event.outfitCutAmount}</strike> is
									now{' '}
									{event.outfitAmount === 0
										? 'FREE'
										: `at ₹${event.outfitAmount}`}{' '}
									for you!{' '}
								</b>
							</p>
						)}
					{!!event &&
						!!event.outfitAmount &&
						eventUiconfig.showOutfit && (
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="demo-simple-select-outlined-label">
									No. of Dhoti Kurta Set needed
								</InputLabel>
								<Select
									value={outfit}
									onChange={(e) =>
										this.onChange(
											e.target.name,
											e.target.value
										)
									}
									label="No. of Dhoti Kurta Set needed"
									name="outfit"
								>
									<MenuItem value={0}>0</MenuItem>
									<MenuItem value={1}>1</MenuItem>
									<MenuItem value={2}>2</MenuItem>
									<MenuItem value={3}>3</MenuItem>
								</Select>
							</FormControl>
						)}
					{eventUiconfig.priceTallyImg && (
						<img src={eventUiconfig.priceTallyImg} />
					)}
					{eventUiconfig.allowPartialPayment && (
						<>
							<Typography variant={'h5'}>
								Booking Confirmation Amount
							</Typography>
							<p>
								<b>
									You can pay atleast Rs.
									{eventUiconfig.confirmationCharge ||
										500}{' '}
									for registration.
									<br />
									{eventUiconfig.nonRefundableMessage}
								</b>
							</p>
							<TextField
								label="Booking Confirmation Amount"
								variant="outlined"
								defaultValue={
									eventUiconfig?.confirmationCharge ??
									this.props.totalAmount ??
									tokenAmount
								}
								value={tokenAmount}
								name="tokenAmount"
								disabled={this.disabled}
								onChange={(e) =>
									this.onChange(
										e.target.name,
										+e.target.value
									)
								}
								error={
									!tokenAmount ||
									tokenAmount <
										(eventUiconfig.confirmationCharge ||
											500)
								}
								helperText={
									!!(
										!tokenAmount ||
										tokenAmount <
											eventUiconfig.confirmationCharge ||
										500
									) && (
										<span>
											Please pay atleast Rs.
											{eventUiconfig.confirmationCharge ||
												500}{' '}
											for seat confirmation
										</span>
									)
								}
								fullWidth
							/>
							{!eventUiconfig.hideReferral && (
								<>
									<Typography variant={'h6'}>
										Please enter referee mobile number
									</Typography>

									<Typography variant={'h8'}>
										* Refer <b>15 devotee</b> and get free 1
										pair <b>Dhoti Kurta</b>
										<br /> * Refer <b>30 Devotee</b> and get{' '}
										<b>Srila Prbhupad Lilamrita</b> as gift
									</Typography>

									<TextField
										label="Referral Number"
										variant="outlined"
										defaultValue={referrer}
										value={referrer}
										type="number"
										name="referrer"
										disabled={!!referrerFromParam}
										onChange={(e) =>
											this.onChange(
												e.target.name,
												e.target.value
											)
										}
										fullWidth
									/>
								</>
							)}
						</>
					)}
					{/* <TextField
            label="Donation"
            variant="outlined"
            defaultValue={donation}
            TabPa
            value={donation}
            name="donation"
            onChange={(e) => this.onChange(e.target.name, e.target.value)}
            fullWidth
            type="number"
          /> */}
					{eventUiconfig.basicInputs.find(
						(input) => input.id === 'NUMBER_OF_BSS_CAMP'
					) && (
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="demo-simple-select-outlined-label">
								Number of BSS camps attended ?
							</InputLabel>
							<Select
								value={cf1_value}
								onChange={(e) =>
									this.onChange(e.target.name, e.target.value)
								}
								label="Number of BSS camps attended ?"
								name="cf1_value"
								error={
									this.state.isSubmitted &&
									!this.state.cf1_value
								}
							>
								{Object.keys(BSS_CAMP_ATTENDED).map((key) => (
									<MenuItem value={key}>
										{BSS_CAMP_ATTENDED[key]}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					{eventUiconfig.basicInputs.find(
						(input) => input.id === 'PBT_YEAR'
					) && (
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="demo-simple-select-outlined-label">
								Expected year of joining the PBT batch ?
							</InputLabel>
							<Select
								value={cf2_value}
								onChange={(e) =>
									this.onChange(e.target.name, e.target.value)
								}
								label="Expected year of joining the PBT batch ?"
								name="cf2_value"
								error={
									this.state.isSubmitted &&
									!this.state.cf2_value
								}
							>
								{Object.keys(EXPECTED_PBT).map((key) => (
									<MenuItem value={key}>
										{EXPECTED_PBT[key]}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					{eventUiconfig.basicInputs.find(
						(input) => input.id === 'TNC'
					) && (
						<div>
							<Typography
								style={{
									fontSize: 18,
									fontWeight: '800',
									marginBottom: 5,
								}}
							>
								|! Criteria for {eventUiconfig.title} devotees
								!|
							</Typography>
							<p>
								1. {eventUiconfig.criteriaPoint}
								<br />
								2. Keep clean shave. <br />
								3. Wearing a plain white Dhoti Kurta all over
								the camp. <br />
								4. Time punctual. <br />
								5. Follow four regulative principles. <br />
								6. Chanting minimum{' '}
								{eventUiconfig.title?.includes('BSS')
									? 16
									: 4}{' '}
								rounds daily.
								<br />
							</p>
							<FormControlLabel
								label="I agree with the above rules ?"
								control={
									<Checkbox
										value={agree}
										onChange={(e) =>
											this.onChange(
												e.target.name,
												e.target.checked
											)
										}
										name="agree"
										color="red"
									/>
								}
							/>
							{this.state.isSubmitted && !this.state.agree && (
								<FormHelperText error={true}>
									This field is mendatory!
								</FormHelperText>
							)}
						</div>
					)}
					<Typography>
						Total Amount: ₹ {this.props.totalAmount}
					</Typography>
					<Button
						variant="contained"
						color="primary"
						onClick={this.onProceedToPay(eventUiconfig)}
					>
						Proceed to pay
					</Button>
				</div>
			</Root>
		)
	}
}

export default AdditionalInfo
