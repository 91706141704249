import React, { Component } from 'react'
import {
	Backdrop,
	CircularProgress,
	Snackbar,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
	TextField,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { connect } from 'react-redux'
import Router from './components/Router'
import './App.css'
import './styles/global.scss'
import { Alert } from '@mui/lab'
import { hideAlert, showAlert, closeDialog } from './actions'
import { validateTokenAction } from './actions/auth'

const PREFIX = 'App'

const classes = {
	backdrop: `${PREFIX}-backdrop`,
}

const Root = styled('div')({
	[`& .${classes.backdrop}`]: {
		zIndex: 1,
		color: '#fff',
	},
})

class App extends Component {
	constructor(props) {
		super(props)
		this.dialogFormRef = React.createRef(null)
		this.state = {}
	}

	componentDidMount() {
		const { validateTokenAction, user, loading } = this.props
		if (
			validateTokenAction &&
			!(user && user.name) &&
			localStorage.getItem('Token') &&
			!loading
		) {
			validateTokenAction(localStorage.getItem('Token'))
		}
		return null
	}

	onCloseAlert = () => {
		const {
			alert: { onClose },
			hideAlert,
		} = this.props
		if (typeof onClose === 'function') {
			onClose()
		}
		hideAlert()
	}

	render() {
		const { loading, alert, dialog, closeDialog } = this.props
		return (
			<Root className="app-container">
				{loading && (
					<Backdrop className={classes.backdrop} open={loading}>
						<CircularProgress color="inherit" />
					</Backdrop>
				)}
				<Dialog
					open={dialog.open}
					onClose={closeDialog}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						{dialog.title || 'Message'}
					</DialogTitle>
					<DialogContent>
						{dialog.description || (
							<DialogContentText id="alert-dialog-description">
								{dialog.description}
							</DialogContentText>
						)}
						<div>
							<form ref={this.dialogFormRef}>
								{dialog.form &&
									dialog.form.map((input) => (
										<TextField
											name={input.name}
											label={input.label}
											defaultValue={input.defaultValue}
											placeholder={input.placeholder}
										/>
									))}
							</form>
						</div>
					</DialogContent>
					{dialog && dialog.actions && (
						<DialogActions>
							{dialog.actions.map((action) => (
								<Button
									onClick={() => {
										let dialogValue
										if (dialog.form) {
											dialogValue = {}
											dialog.form.forEach((input) => {
												dialogValue[input.name] =
													this.dialogFormRef.current.elements[
														input.name
													].value
											})
										}
										if (typeof action.action === 'function')
											action.action(dialogValue)
										else {
											closeDialog()
										}
									}}
									color={action.color || 'primary'}
									autoFocus
								>
									{action.text || 'OK'}
								</Button>
							))}
						</DialogActions>
					)}
				</Dialog>
				{alert && (
					<Snackbar
						open={!!alert.message}
						autoHideDuration={alert.duration}
						onClose={this.onCloseAlert}
					>
						<Alert
							onClose={this.onCloseAlert}
							severity={alert.severity}
						>
							{alert.message}
						</Alert>
					</Snackbar>
				)}
				<Router />
			</Root>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.commonContent.user,
	loading: state.commonContent.loading,
	alert: state.commonContent.alert ?? state.areaReducer.alert,
	dialog: state.commonContent.dialog,
})

const mapDispatchToProps = {
	hideAlert,
	showAlert,
	validateTokenAction,
	closeDialog,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
