import axios from 'axios'
import { getToken } from './index'
import { DEFAULT_ERROR_MESSAGE, METHOD } from '../constants/network'
import store from '../store'
import { showAlert } from '../actions'
import { ALERT } from '../constants'

export const isProd = () => {
	return (
		!window.origin.includes('dev.coastok') &&
		!window.origin.includes('localhost')
	)
}

export const API_BASE_URL = isProd()
	? process.env.REACT_APP_API_BASE_URL_PROD
	: process.env.REACT_APP_API_BASE_URL_DEV
const axiosInstance = axios.create({
	baseURL: API_BASE_URL,
})

export const NOTIFICATION_KEY = window.origin.includes(
	process.env.REACT_APP_HOME_PAGE
)
	? process.env.REACT_APP_NOTIFICATION_KEY_PROD
	: process.env.REACT_APP_NOTIFICATION_KEY_DEV

// Axios Interceptors

axiosInstance.interceptors.response.use(
	(response) => {
		return response.data
	},
	(error) => {
		if (error?.response?.status === 401) {
			localStorage.removeItem('Token')
			localStorage.removeItem('UserProfile')
			window.location.href = '/'
		} else if (error?.response?.status === 400) {
			store.dispatch(
				showAlert({
					message:
						error.response?.data?.message ||
						(error.response?.data &&
							error.response?.data &&
							error.response?.data?.data &&
							error.response?.data?.data[0]) ||
						DEFAULT_ERROR_MESSAGE,
					severity: ALERT.ERROR,
					duration: 5000,
				})
			)
		}
		return Promise.reject(error)
	}
)

// axiosInstance.interceptors.request.use((config) => {
//   const token = getToken();
//   console.log(`token: ${token}`);
//   config.headers.Authorization = token;
//   return config;
// });

export const fetchAPI = (request) => {
	const opts = {
		url: request.url,
		method: request.method || METHOD.GET,
	}
	if (request.body) {
		opts.data = request.body
	}
	if ((!request.isOpenApi && getToken()) || request.headers) {
		opts.headers = {
			'Access-Control-Allow-Origin': '*',
			access_token: !request.isOpenApi ? getToken() : undefined,
			...request?.headers,
		}
	}
	return axiosInstance(opts)
}

export const extractErrorStr = (error) => {
	const message =
		error?.response?.data?.error ||
		error?.response?.data?.data?.[0] ||
		(error && error.message) ||
		DEFAULT_ERROR_MESSAGE
	console.log(message)
	return message
}
