import { API_URL, METHOD } from "../constants/network";
import { serialize } from "../utils";
import { fetchAPI } from "../utils/network";

export const initailizePledge = () => {
	return fetchAPI({
		url: API_URL.PLEDGE_INIT,
	});
};

export const getPledgeDetails = (params) => {
	return fetchAPI({
		url: `${API_URL.PLEDGE_DETAIL}${serialize(params)}`,
	});
};

export const createPledgeDetails = (pledgeDetails) => {
	return fetchAPI({
		url: API_URL.PLEDGE_DETAIL,
		method: METHOD.POST,
		body: pledgeDetails,
	});
};
export const updatePledgeDetails = (pledgeDetails) => {
	return fetchAPI({
		url: API_URL.PLEDGE_DETAIL,
		method: METHOD.PUT,
		body: pledgeDetails,
	});
};

export const getPledgeSummary = (params) => {
	return fetchAPI({
		url: `${API_URL.PLEDGE_SUMMARY}${serialize(params)}`,
		method: METHOD.GET,
	});
};

export const getPledgeUsers = (params) => {
	return fetchAPI({
		url: `${API_URL.PLEDGING}${serialize(params)}`,
		method: METHOD.GET,
	});
};
